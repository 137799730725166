import React, { Component } from 'react'


export default class subscription_plan extends Component {
    render() {
        return (
            <>
                {this.props?.current_subscription?.id === this.props.plan_id && <p className="current_plan">Current Plan</p>}
                <div className="manage-subscription-fold-1">
                    <h3 className="checkout_head mt-5">{this.props.plan_name}</h3>
                    <p className="text-uppercase ">{this.props.plan_name}<b></b> / month</p>
                </div>
                <div className="manage-subscription-fold-2">
                    <p className="price_check login_text text-start align-items-center text-grey mb-2"><span
                        className="my-account-plan-price me-2">${this.props.plan_price}</span><span>per<br />month</span></p>
                    <p className='small-p'>Make progress on your goals. With this plan, you get 4
                        coaching sessions per month with a certified coach, access to a library of curated learning content,
                        and professional planning resources.</p>
                    {/* {this.props.plan_feature } */}
                    {/* <p>{this.props.plan_feature}</p> */}
                    <div>
                        <p className='small-p recurring-image-before'>Monthly recurring subscription (cancel any time)</p>
                    </div>
                </div>
                <div className="manage-subscription-fold-3">
                    <div>
                        <span> <strong> Flavorable Lite</strong> <span style={{ float: 'right' }}>$0</span> <br />Billed Monthly</span>
                    </div>
                </div>
                {this?.props?.page !== "price" && this.props.plan_is_subscribed &&
                <div className="toggle-form">
                    <label className="toggle" htmlFor="toggle-element">
                        <input type="checkbox" role="switch" className="toggle__element" id="toggle-element" checked={this.props?.plan_is_recurring} onChange={()=>this.props.handleChnageSubscriptionToggle(this.props?.current_subscription?.id,this.props?.plan_is_recurring)} />
                        <div className="toggle__decor" aria-hidden="true">
                        <span className='toggle-text-on'>ON</span>
                        <span className='toggle-text-off' >OFF</span>
                        <div className="toggle__thumb"></div>
                        </div>
                    </label>
                    <p>Auto-renew</p>
                </div>
                }
            </>
        )
    }
}

import React, { useEffect, useState } from "react";
import "../../assets/css/tcreate-recipe.css";
import { ReactSession } from "react-client-session";
import { Link, Navigate } from "react-router-dom";
import { getCuisine, getGeography, getTest, recipe_type } from "../../apis";
import BakeTime from "../components/BakeTime";
import Cuisine from "../components/Cuisine";
import Taste from "../components/Taste";
import Occasion from "../components/Occasion";
import { useSelector, useDispatch } from "react-redux";
import { createRecipeActions } from "../../store/createRecipe";
import { recipeDataActions } from "../../store/recipeData";

const CreateRecipeStep4 = (props) => {
  
  const [is_validated, setIsValidate] = useState(false);

  const [list_cuisines, setCuisine] = useState({});

  const [list_bake_time, setBakeTime] = useState({});

  const [list_taste, setTaste] = useState({});

  const [list_occassion, setOccasion] = useState({});


  const [selected_cuisines, setSelectedCuisine] = useState([]);

  const [selected_bake_times, setSelectedBakeTime] = useState([]);

  const [selected_testes, setSelectedTest] = useState([]);

  const [selected_occasions, setSelectedOccasion] = useState([]);

  const [errors, setErrors] = useState({})

  const [form, setForm] = useState({
    dish_type: "",
    chefs_note: "",
  });

  const dispatch = useDispatch();

  const create_recipe = useSelector(
    (state) => state.createrecipe.create_recipe
  );
  const recipe_data = useSelector((state) => state.recipedata);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (recipe_data.stored_taste.results) {
      setSelectedBakeTime(JSON.parse(JSON.stringify(create_recipe?.recipe_selected_bakes)))
      setSelectedCuisine(JSON.parse(JSON.stringify(create_recipe?.recipe_selected_cuisines)))
      setSelectedOccasion(JSON.parse(JSON.stringify(create_recipe?.recipe_selected_occasions)))
      setSelectedTest(JSON.parse(JSON.stringify(create_recipe?.recipe_selected_tastes)))
      setCuisine(JSON.parse(JSON.stringify(recipe_data.stored_cuisine)));
      setBakeTime(JSON.parse(JSON.stringify(recipe_data.stored_bake)));
      setTaste(JSON.parse(JSON.stringify(recipe_data.stored_taste)));
      setOccasion(JSON.parse(JSON.stringify(recipe_data.stored_occasion)));
    } else {
      const authtoken = ReactSession.get("authtoken");

      let config = {
        headers: {
          Authorization: `Token ${authtoken}`,
        },
      };
      // to get the available cuisines
      getCuisine(config)
        .then((resp) => {
          for (let data of resp.data.results) {
            data.is_selected = false;
          }

          setCuisine(resp.data);
        })

        .catch((err) => {
          console.log("this is the error", err.response);
        });

      // bake time
      recipe_type(config)
        .then((resp) => {
          for (let data of resp.data.results) {
            data.is_selected = false;
          }

          setBakeTime(resp.data);
        })
        .catch((err) => {
          console.log("this is the error", err.response);
        });

      //
      getTest(config)
        .then((resp) => {
          for (let data of resp.data.results) {
            data.is_selected = false;
          }
          setTaste(resp.data);
        })

        .catch((err) => {
          console.log("this is the error", err.response);
        });
    
         //occasion
        getGeography()
        .then((resp) => {
          for (let data of resp.data.results) {
            data.is_selected = false;
          }
          setOccasion(resp.data);
        })

        .catch((err) => {
          console.log("this is the error", err.response);
        });
      }
  }, []);

  // to handle the event when some cuisine is selected
  const handleClickCuisine = (id) => {
    const cuisine = { ...list_cuisines };
    let selected_cuisine = [...selected_cuisines];

    for (let result of cuisine.results) {
      if (result.id == id) {
        result.is_selected = !result.is_selected;
        if (result.is_selected) {
          selected_cuisine.push(id);
        }
        if (!result.is_selected) {
          let index = selected_cuisine.indexOf(id);
          // only splice array when item is found
          if (index > -1) {
            // 2nd parameter means remove one item only
            selected_cuisine.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }
    }
    setCuisine(cuisine);
    setSelectedCuisine(selected_cuisine);
    setErrors({
      ...errors,
      cuisines:''
    })
  };

  const handleClickBakeTime = (id) => {
    const selected_bake_time = [...selected_bake_times];
    const bake_time = { ...list_bake_time };

    for (let result of bake_time.results) {
      if (result.id == id) {
        result.is_selected = !result.is_selected;
        if (result.is_selected) {
          selected_bake_time.push(id);
        }
        if (!result.is_selected) {
          let index = selected_bake_time.indexOf(id);
          // only splice array when item is found
          if (index > -1) {
            // 2nd parameter means remove one item only
            selected_bake_time.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }
    }
    setBakeTime(bake_time);
    setSelectedBakeTime(selected_bake_time);
    setErrors({
      ...errors,
      bakes:''
    })

    console.log("this is the selcted bake time", selected_bake_time);
  };

  const handleClickTaste = (id) => {
    const selected_teste = [...selected_testes];
    const taste = { ...list_taste };

    for (let result of taste.results) {
      if (result.id == id) {
        result.is_selected = !result.is_selected;
        if (result.is_selected) {
          selected_teste.push(id);
        }
        if (!result.is_selected) {
          let index = selected_teste.indexOf(id);
          // only splice array when item is found
          if (index > -1) {
            // 2nd parameter means remove one item only
            selected_teste.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }
    }
    setTaste(taste);
    setSelectedTest(selected_teste);
    setErrors({
      ...errors,
      tastes:''
    })

    console.log("this is the selcted bake time", selected_teste);
  };

  const handleClickOccasion=(id)=>{
    const selected_occasion = [...selected_occasions];
    const occasion = { ...list_occassion };

    for (let result of occasion.results) {
      if (result.id == id) {
        result.is_selected = !result.is_selected;
        if (result.is_selected) {
          selected_occasion.push(id);
        }
        if (!result.is_selected) {
          let index = selected_occasion.indexOf(id);
          // only splice array when item is found
          if (index > -1) {
            // 2nd parameter means remove one item only
            selected_occasion.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }
    }
    setOccasion(occasion);
    setSelectedOccasion(selected_occasion);
    setErrors({
      ...errors,
      occasions:''
    })

  }


  const handleChangefield = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      chefs_note:''
    })
    dispatch(
      createRecipeActions.createRecipe({
        ...create_recipe,
        [e.target.name]: e.target.value,
      })
    );
  };

  

  const handleClickRouting = () => {

    const cuisines = list_cuisines;
    const bakes = list_bake_time;
    const tastes = list_taste;
    const occasions = list_occassion
    dispatch(
      recipeDataActions.recipeData({
        ...recipe_data,
        stored_cuisine: cuisines,
        stored_bake: bakes,
        stored_taste: tastes,
        stored_occasion: occasions
      })
    );

    dispatch(
      createRecipeActions.createRecipe({
        ...create_recipe,
        recipe_selected_cuisines:selected_cuisines,
        recipe_selected_bakes:selected_bake_times,
        recipe_selected_tastes:selected_testes,
        recipe_selected_occasions:selected_occasions
      }))
  };

  const isValidated = () => {
    let is_validted = true;
    let error = {};
    if (!selected_cuisines.length) {
      is_validted = false;
      error.cuisines = "Please select atleast one cuisine from above list ";
    }
    if (!selected_bake_times.length) {
      is_validted = false;
      error.bakes = "Please select atleast one dish type from above list";
    }
    if (!selected_testes.length) {
      is_validted = false;
      error.tastes = "Please select atleast one taste from above list";
    }

    if (!selected_occasions.length) {
      is_validted = false;
      error.occasions = "Please select atleast one occasion from above list";
    }
    if (!form.chefs_note) {
      is_validted = false;
      error.chefs_note = "Please enter the chefs note";
    }
    setErrors(error);
    return is_validted;
  };
  const handleClickRoutingNext=()=>{
    if (isValidated()) {
      handleClickRouting()
      setIsValidate(true)
      
    }
  }
  const user_info = ReactSession.get("user_data");

  return (
    <>
     {is_validated && (<Navigate to="/create-recipe-step-5" replace={true} />)}
      <section className="create-recipe-page-1">
        <div className="container">
          <div className="row d-flex justify-content-center mt-2">
            <div className="col-11 col-xl-12 col-md-12 col-lg-6">
              <h5 className="create-recipe-title">
                {create_recipe?.recipe_name}
              </h5>
              <h1 className="create-recipe-heading">Create Recipe</h1>
              <p className="col-xl-6 col-11 p-0 create-recipe-paragraph-section-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est,
                adipiscing cursus auctor eget sed phasellus senectus.{" "}
              </p>
            </div>
            <div className="col-xl-12 col-11">
              <ul className="progressbar pl-0">
                <li className="active">Step 1</li>
                <li>Step 2</li>
                <li>Step 3</li>
                <li>Step 4</li>
              </ul>
            </div>
            <div className="col-xl-12 col-11">
              <div className="progress mt-2 p-0" style={{ height: "5px" }}>
                <div
                  className="progress-bar steps-bg-color"
                  role="progressbar"
                  aria-label="Example 1px high"
                  style={{ width: "100%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-4 col-md-12 col-11 mt-3">
            <span className="recipe-name">occasion</span>
            <span className="red-star"> *</span>
            <div className="step-2-input-fields">
            <span className='errorValidation'>{errors?.occasions}</span>
              <div className="row">
                <div className="col-xl-12 form-group mt-2">
                  <div className="input-effect-create-recips">
                    <Occasion
                    occasion={list_occassion?.results}
                    handleClickOccasion={handleClickOccasion}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="mt-2 mb-2">
                <span className="recipe-name"> Cuisine</span>
                <span className="red-star"> *</span>
              </div>
            <span className='errorValidation'>{errors?.cuisines}</span>
              <div>
                <Cuisine
                  cuisines={list_cuisines?.results}
                  handleClickCuisine={handleClickCuisine}
                />
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-md-12 col-11 mt-3">
            <div>
              <span className="recipe-name">Dish Type</span>
              <span className="red-star"> *</span>
            </div>
            <span className='errorValidation'>{errors?.bakes}</span>
            <div className="mt-2">
              <BakeTime
                bake_times={list_bake_time?.results}
                handleClickBakeTime={handleClickBakeTime}
              />
            </div>
            <hr></hr>
            <div className="mt-3">
              <div>
                <span className="recipe-name">Taste</span>
                <span className="red-star"> *</span>
              </div>
            <span className='errorValidation'>{errors?.tastes}</span>
              <div className="mt-3">
                <Taste
                  tastes={list_taste?.results}
                  handleClickTaste={handleClickTaste}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-12 col-10 mt-3">
            <span className="recipe-name">Chef’s note</span>
            <span className="red-star"> *</span>
            <div className="row">
              <div className="col-xl-12 form-group mt-2">
                <div className="input-effect-create-recips">
                  <input
                    className="effect-1"
                    type="text"
                    name="chefs_note"
                    autoComplete="off"
                    placeholder="E.g. Dessert"
                    value={create_recipe.chefs_note}
                    onChange={handleChangefield}
                  />
            <span className='errorValidation'>{errors?.chefs_note}</span>
                  {/* <label>E.g. Dessert</label> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mb-5 hr-color" />
        <div className="row  mb-5">
          <div className="col-xl-12 col-12 d-flex justify-content-between">
            <Link to="/create-recipe-step-3">
              <button
                type="button"
                className="btn btn-success btn-rounded previous-btn "
                onClick={handleClickRouting}
              >
                Previous
              </button>
            </Link>
              <button
                type="button"
                className="btn btn-success btn-rounded previous-btn "
                onClick={handleClickRoutingNext}
              >
                Preview Before Submitting
              </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateRecipeStep4;

import React, { useState } from 'react'
import Modal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

const RecipeEditDirection = props => {


    return (
        <>
            <a onClick={() => props.handleclickEditDirection(props?.id, props?.direction)} >
                <FontAwesomeIcon
                    className="penToSquareIcon"
                    icon={faPenToSquare}
                    size="2x"
                    transform="shrink-6"
                    color="#85B492"
                />
            </a>

            <Modal
                isOpen={props.showEditDirectionModal}
                onRequestClose={props.handleCloseEditDirectionModal}
                className="forgotPasswordModal "
                overlayClassName="forgotPasswordOverlay"
                closeTimeoutMS={500}
                ariaHideApp={false}
            >
                <>


                    <div className="col-12 form-group mt-5">
                        <div className="input-effect">
                            <textarea
                                className="effect"
                                type="text"
                                name="direction_input"
                                onChange={props.handleChangeDirection}
                                value={props.direction_input}
                                autoComplete="off" />
                        </div>
                    </div>

                    <div className="col-12 col-xl-12 col-md-12 text-center">
                        <a
                            style={{ color: "#85b492" }}
                            onClick={() => props.handleClickEditSaveDirection(props.edit_Direction_id)}
                        >
                            save
                        </a>
                    </div>

                </>
            </Modal>
        </>
    )
}

export default RecipeEditDirection
import './App.css';
import axios from 'axios';

import Header from './pages/header';
import Footer from './pages/footer';

import { setAuthorizationToken } from './apis';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import { useSelector } from 'react-redux';

import Landing from './pages/landing';
import Login from './pages/login';
import Register from './pages/register';
import Contact from './pages/contact';
import Blogs from './pages/blogs/blogs';
import Privacy from './pages/privacy';
import Blog from './pages/blogs/blog';
import Termsandconditions from './pages/termsandconditions';
import Thankyou from './pages/thankyou';
import NewBlog from './pages/blogs/create_blog';
import CreateRecipeStep1 from './pages/recipe/createRecipeStep1';
import CreateRecipeStep2 from './pages/recipe/createRecipeStep2';
import CreateRecipeStep3 from './pages/recipe/createRecipeStep3';
import CreateRecipeStep4 from './pages/recipe/createRecipeStep4';
import CreateRecipeStep5 from './pages/recipe/createRecipeStep5';

import EditRecipeStep1 from './pages/edit-recipe/editRecipeStep1';
import EditRecipeStep2 from './pages/edit-recipe/editRecipeStep2';
import EditRecipeStep3 from './pages/edit-recipe/editRecipeStep3';
import EditRecipeStep4 from './pages/edit-recipe/editRecipeStep4';
import EditRecipeStep5 from './pages/edit-recipe/editRecipeStep5';

import MyWorkspace from './pages/blogs/My_Workspace';
import My_account from './pages/user/My_account';
import Price from './pages/price';
import Modal from './pages/modal';
import Recipes from './pages/ListRecipes/Recipes'
import Recipe from './pages/ListRecipes/Recipe'
import CheckoutContainer from './pages/checkout_container';


function App() {


  ReactSession.setStoreType('sessionStorage');

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === `${name}=`) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  axios.interceptors.request.use(request => {
    const token = ReactSession.get('authtoken');
    const csrftoken = getCookie('csrftoken');
    setAuthorizationToken(token, csrftoken,request);
    return request

  })


  const token = ReactSession.get('authtoken');

  return (
    <Router>

      <Header />

      <Routes>

        <Route path="/" element={<Landing />} />

        <Route path="/login" element={<Login />} />

        <Route path="/register" element={<Register />} />

        <Route path="/contact" element={<Contact />} />

        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:tag" element={<Blogs />} />

        <Route path="/blog/:blogId/:title" element={<Blog />} />

        <Route path="/privacy" element={<Privacy />} />

        <Route path="/tnc" element={<Termsandconditions />} />

        <Route path="/thankyou" element={<Thankyou />} />
        {token &&
          <>
            <Route path="/createblog" element={<NewBlog />} />
            <Route path="/createblog/:blogId/:title" element={<NewBlog />} />
          </>
        }
        <Route path="/create-recipe-1" element={<CreateRecipeStep1 />} />
        <Route path="/create-recipe-2" element={<CreateRecipeStep2 />} />
        <Route path="/create-recipe-3" element={<CreateRecipeStep3 />} />
        <Route path="/create-recipe-4" element={<CreateRecipeStep4 />} />
        <Route path="/create-recipe-5" element={<CreateRecipeStep5 />} />
        <Route path="/edit-1/:slug" element={<EditRecipeStep1 />} />
        <Route path="/edit-2/:slug" element={<EditRecipeStep2 />} />
        <Route path="/edit-3/:slug" element={<EditRecipeStep3 />} />
        <Route path="/edit-4/:slug" element={<EditRecipeStep4 />} />
        <Route path="/edit-5/:slug" element={<EditRecipeStep5 />} />
        <Route path="/my-workspace" element={<MyWorkspace />} />
        <Route path="/my-account" element={<My_account />} />
        <Route path="/price" element={<Price />} />
        <Route path="/checkout/:plan_id" element={<CheckoutContainer />} />


        <Route path="/recipes" element={<Recipes/>} />
        <Route path="/recipes/:tag" element={<Recipes />} />

        <Route path="/Recipe/:slug" element={<Recipe />} />

      </Routes>

        

      <Footer />

    </Router>
  );
}

export default App;


// add this to urls to redirect /Create-recipe-step-1

import React from 'react';
import { ReactSession } from 'react-client-session'

import LazyLoad from 'react-lazyload';
import { notification } from 'antd';

import { NavLink, Navigate } from 'react-router-dom';
import $ from 'jquery';

//importing css
import '../index.css';
import '../assets/js/selectdropdown';

import { getUserProfile } from '../apis'
import logo from '../assets/images/flavorable-logo.png';
import { connect } from 'react-redux';
import { authActions } from '../store/auth';
import { refreshBlogsActions } from '../store/refreshblogs';
import default_person from "../assets/images/default_person.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHeart,
    faUser,
    faArrowRightToBracket,
    faCircleUser
} from "@fortawesome/free-solid-svg-icons";

class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            isLogedout: false,
            isloggedin: false,
        };
        this.handleLogout = this.handleLogout.bind(this);
        this.refreshBlogs = this.refreshBlogs.bind(this);
    }


    componentDidMount() {
        const auth_token = ReactSession.get('authtoken');
        if (!auth_token) {

            if (window?.location?.pathname.includes("login")) {
                const authtoken = window?.location?.pathname.split('/')[2]
                if (authtoken) {

                    let config = {
                        headers: {
                            'Authorization': `Token ${authtoken}`
                        }
                    }

                    getUserProfile(config)
                        .then(resp => {
                            const user_data ={
                                'first_name' : resp.data.first_name,
                                'last_name': resp.data.last_name,
                                'username'     : resp.data.username,
                                'profile_image': resp.data.profile_image   
                            }
                            ReactSession.set("user_data", user_data);
                            ReactSession.set("authtoken", authtoken);
                            notification.success({
                                message: 'You have successfully logged-in.',
                                placement: 'bottomRight'

                            })
                            this.props.login()
                            this.setState(
                                {
                                    isloggedin: true
                                }
                            )

                        })

                        .catch(err => {

                        })
                }
            }

            else if (!window?.location?.pathname.includes("blog")) {
                const authtoken = window?.location?.pathname.split('-')[1]
                if (authtoken) {
                    let config = {
                        headers: {
                            'Authorization': `Token ${authtoken}`
                        }
                    }

                    getUserProfile(config)
                        .then(resp => {
                            const user_data ={
                                'first_name' : resp.data.first_name,
                                'last_name': resp.data.last_name,
                                'username'     : resp.data.username,
                                'profile_image': resp.data.profile_image   
                            }
                            ReactSession.set("user_data", user_data);
                            ReactSession.set("authtoken", authtoken);
                            notification.success({
                                message: 'logged-in succesfully.',
                                description: 'Your magiclink did the trick.',
                                placement: 'bottomRight'
                            })
                            this.props.login()

                            this.setState(
                                {
                                    isloggedin: true
                                }
                            )
                        })

                        .catch(err => {

                        })
                }
            }
        }
   
        $(window).scroll(function () {
            if ($(this).scrollTop() > 1) {
                $('header').addClass("sticky");
            }
            else {
                $('header').removeClass("sticky");
            }

        });

        //Menu JS
        const hamburger = document.querySelector(".hamburger");
        const navLinks = document.querySelector(".nav-links");
        const links = document.querySelectorAll(".nav-links li");

        hamburger.addEventListener('click', () => {
            //Animate Links
            navLinks.classList.toggle("open");
            links.forEach(link => {
                link.classList.toggle("fade");
            });

            //Hamburger Animation
            hamburger.classList.toggle("toggle");
        });


    }

    componentDidUpdate(pP, pS, cC) {

        if (this.state.isLogedout === true || this.state.isloggedin === true) {
            this.setState({
                isLogedout: false,
                isloggedin: false,

            })
        }

    }

    toggleHamBurgerClasses() {
        if (window.screen.width < 767) {
            const hamburger = document.querySelector(".hamburger");
            const navLinks = document.querySelector(".nav-links");
            const links = document.querySelectorAll(".nav-links li");

            navLinks.classList.toggle("open");
            links.forEach(link => {
                link.classList.toggle("fade");
            });

            //Hamburger Animation
            hamburger.classList.toggle("toggle");
        }


    }

    handleLogout() {

        if (window.screen.width < 767) {
            const hamburger = document.querySelector(".hamburger");
            const navLinks = document.querySelector(".nav-links");
            const links = document.querySelectorAll(".nav-links li");
            navLinks.classList.toggle("open");
            links.forEach(link => {
                link.classList.toggle("fade");
            });


            hamburger.classList.toggle("toggle");
        }

        ReactSession.remove("user_data");
        ReactSession.remove("authtoken");
        this.props.logout()
        this.setState(
            {
                isLogedout: true
            }
        )

    }

    refreshBlogs() {

        this.props.refresh()

        if (window.screen.width < 767) {
            const hamburger = document.querySelector(".hamburger");
            const navLinks = document.querySelector(".nav-links");
            const links = document.querySelectorAll(".nav-links li");

            navLinks.classList.toggle("open");
            links.forEach(link => {
                link.classList.toggle("fade");
            });

            //Hamburger Animation
            hamburger.classList.toggle("toggle");
        }

    }

    render() {
        let token = ReactSession.get("authtoken");
        const userInfo = ReactSession?.get('user_data');
        return (
            <div>
                {this.state.isLogedout || this.state.isloggedin && (<Navigate to="/" replace={true} />)}
                <header>
                    <div className="container-fluid">
                        <nav>
                            <NavLink className="logo active" aria-current="page" to="/">
                                <LazyLoad >
                                    <img src={logo} alt="" />
                                </LazyLoad>
                            </NavLink>
                            <div className={`hamburger `}>
                                <div className="line1"></div>
                                <div className="line2"></div>
                                <div className="line3"></div>
                            </div>
                            <ul className="nav-links">
                                <li>
                                    <NavLink aria-current="page" to="/blogs" onClick={this.refreshBlogs} >Blog </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact" onClick={this.toggleHamBurgerClasses}>Contact</NavLink>
                                </li>
                                {!token ?
                                    (<>
                                        <li>
                                            <NavLink to="/login" onClick={this.toggleHamBurgerClasses} > Login </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/register" >
                                                <button type="button" className="btn f-register" onClick={this.toggleHamBurgerClasses}>Register</button>
                                            </NavLink>
                                        </li>
                                    </>
                                    )
                                    :
                                    (<>
                                        <li>
                                            
                                            <div className="dropdown">
                                                    <div className="author-img ">
                                                        {userInfo?.profile_image ?
                                                         (<img src={userInfo?.profile_image} alt="" className="img-fluid rounded-circle" style={{ height: "30px",width: '30px'}} />) 

                                                         :
                                                         ( <FontAwesomeIcon
                                                            icon={faCircleUser}
                                                            size={"2x"}
                                                            color="#85b492"
                                                        />) }
                                                    </div>
                                                <div className="dropdown-content">
                                                    <div className="author-img mb-4 mt-4 d-flex">
                                                    {userInfo?.profile_image ?

                                                         (<img src={userInfo?.profile_image} alt="" className="img-fluid rounded-circle" style={{ height: "30px", width:'30px' }} />) 
                                                         :
                                                         ( <FontAwesomeIcon
                                                            icon={faCircleUser}
                                                            size={"2x"}
                                                            color="#85b492"
                                                        />) }
                                                        <span className='ml-3 user-name d-flex align-items-center'>{userInfo?.first_name} {userInfo?.last_name}</span>
                                                    </div>
                                                    <NavLink to='/my-workspace' className={'d-flex  header-dropdown'} >
                                                        <FontAwesomeIcon
                                                            icon={faHeart}
                                                            size={"xl"}
                                                            color="#85b492"
                                                        />
                                                        <span className='pl-3 mt-1 mb-1'>My Workspace</span>
                                                        </NavLink>
                                                    <NavLink to='/my-account' className={'d-flex  header-dropdown'}>
                                                        <FontAwesomeIcon
                                                            icon={faUser}
                                                            size={"xl"}
                                                            color="#85b492"

                                                        />
                                                        <span className='pl-3 mt-1 mb-1'>My Account</span>
                                                        </NavLink>
                                                    <NavLink to="/" onClick={this.handleLogout} className={'d-flex header-dropdown'} >
                                                        <FontAwesomeIcon
                                                            icon={faArrowRightToBracket}
                                                            size={"xl"}
                                                            color="#85b492"
                                                        /> {" "} <span className='pl-3 mt-1 mb-1'>Logout</span> </NavLink>
                                                </div>
                                            </div>
                                        </li>
                                    </>
                                    )
                                }
                            </ul>
                        </nav>
                    </div>
                </header>

            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        refreshblogs: state.refreshblog.refreshblogs
    };
}

const mapDispatchToProps = dispatch => {
    return {
        login: () => dispatch(authActions.login()),
        logout: () => dispatch(authActions.logout()),
        refresh: () => dispatch(refreshBlogsActions.blogsRefresh()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
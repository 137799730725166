import React, { useState, useEffect } from "react";
import "../../assets/css/tcreate-recipe.css";
import { ReactSession } from "react-client-session";
import { Link, Navigate } from "react-router-dom";
import InputCircularTime from "../components/input-circular-time";
import { useSelector, useDispatch } from "react-redux";
import { createRecipeActions } from "../../store/createRecipe";

const CreateRecipeStep2 = (props) => {
  const [portion_type, setPortionType] = useState("");
  const [bake_time, setBakeTime] = useState({ min: 0, hours: 0 });
  const [prep_time, setPrepTime] = useState({ min: 0, hours: 0 });
  const [resting_time, setRestingTime] = useState({ min: 0, hours: 0 });
  const [errors, setErrors] = useState({});
  const [is_validated, setIsValidate] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setBakeTime(JSON.parse(JSON.stringify(create_recipe.recipe_bake_time)))
    setRestingTime(JSON.parse(JSON.stringify(create_recipe.recipe_rest_time)))
    setPrepTime(JSON.parse(JSON.stringify(create_recipe.recipe_prep_time)))
    setPortionType(create_recipe.recipe_portion_type)
  }, []);
  // handling the input
  const handleChangePortion = (e) => {
    
    setErrors(
     { ...errors,
     portion_type : ''}
      )
      setPortionType(e.target.value);
  };

  const dispatch = useDispatch();

  const create_recipe = useSelector(
    (state) => state.createrecipe.create_recipe
  );

  const handleClickRoutingNext=()=>{
    if (isValidated()) {
      handleClickRouting()
      setIsValidate(true)
      
    }
  }

  const handleClickRouting = () => {

    dispatch(
      createRecipeActions.createRecipe({
        ...create_recipe,
        recipe_prep_time: { ...prep_time },
        recipe_bake_time: { ...bake_time },
        recipe_rest_time: { ...resting_time },
        recipe_portion_type: portion_type,
      })
    );
  };

  const isValidated = () => {
    let is_validted = true;
    let error = {};
    if (!portion_type) {
      is_validted = false;
      error.portion_type = "please add the portion size";
    }
    if (bake_time.min === 0 && bake_time.hours === 0 ) {
      is_validted = false;
      error.bake_time = "Please add the baking time for your recipe";
    }
    if (prep_time.min === 0 && prep_time.hours === 0) {
      is_validted = false;
      error.prep_time = "Please add the preparation time for your recipe";
    }
    if (resting_time.min === 0 && resting_time.hours===0) {
      is_validted = false;
      error.resting_time = "Please add the resting time for your recipe";
    }

    console.log(error);
    setErrors(error);
    return is_validted;
  };

  const handleChangeHours = (value, identifier) => {
    switch (identifier) {
      case 'bake':
        setBakeTime({
          ...bake_time,
          hours: value,
        });
        setErrors(
          { ...errors,
          bake_time : ''}
           )
        break;
      case 'prep':
        setPrepTime({
          ...prep_time,
          hours: value,
        });
        setErrors(
          { ...errors,
          prep_time : ''}
           )
        break;
      case 'rest':
        setRestingTime({
          ...resting_time,
          hours: value,
        });
        setErrors(
          { ...errors,
          resting_time : ''}
           )
        break;
    }
  };

  const handleChangeMinutes = (value, identifier) => {
    switch (identifier) {
      case 'bake':
        setBakeTime({
          ...bake_time,
          min: value,
        });
        setErrors(
          { ...errors,
          bake_time : ''}
           )
        break;
      case 'prep':
        setPrepTime({
          ...prep_time,
          min: value,
        });
        setErrors(
          { ...errors,
          prep_time : ''}
           )
        break;
      case 'rest':
        setRestingTime({
          ...resting_time,
          min: value,
        });
        setErrors(
          { ...errors,
          resting_time : ''}
          )
        break;
    }

  };

  // user data to display in the ui
  const user_info = ReactSession.get("user_data");
  console.log('this are the errrors', errors);
  return (
    <>
     {is_validated && (<Navigate to="/create-recipe-step-3" replace={true} />)}
      <section className="create-recipe-page-1">
        <div className="container">
          <div className="row d-flex justify-content-center mt-2">
            <div className="col-11 col-xl-12 col-md-12 col-lg-6">
              <h5 className="create-recipe-title">
                {create_recipe?.recipe_name}
              </h5>
              <h1 className="create-recipe-heading">Create Recipe</h1>
              <p className="col-xl-6 col-11 p-0 create-recipe-paragraph-section-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est,
                adipiscing cursus auctor eget sed phasellus senectus.{" "}
              </p>
            </div>
            <div className="col-xl-12 col-11">
              <ul className="progressbar pl-0">
                <li className="active">Step 1</li>
                <li>Step 2</li>
                <li>Step 3</li>
                <li>Step 4</li>
              </ul>
            </div>
            <div className="col-xl-12 col-11">
              <div className="progress mt-2 p-0" style={{ height: "5px" }}>
                <div
                  className="progress-bar steps-bg-color"
                  role="progressbar"
                  aria-label="Example 1px high"
                  style={{ width: "50%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-6 col-md-12 col-11 mt-3 mb-3">
            <span className="recipe-name">Portion Type</span>
            <span className="red-star"> *</span>
            <div>
              <span className="step-2-input-field">
                Choose between servings or pieces
              </span>
            </div>
            <div className="step-2-input-fields">
              <div className="row">
                <div className="col-xl-8 form-group mt-2">
                  <div className="input-effect-create-recips">
                    <input
                      className="effect-1"
                      type="number"
                      name="portion_type"
                      autoComplete="off"
                      placeholder="E.g. 2 servings / pieces"
                      value={portion_type}
                      onChange={handleChangePortion}
                    />
                  </div>
                </div>
              </div>
            </div>
            <span className='errorValidation'>{errors?.portion_type}</span>
          </div>
          <div className="col-xl-6 col-md-12 col-11 mt-3 mb-3">
            <span className="recipe-name">Bake time</span>
            <span className="red-star"> *</span>
            <div>
              <span className="step-2-input-field">
                How long does the dish need to bake for?
              </span>
            </div>
            <div className="step-2-input-fields">
              <div className="row">
                <div className="col-xl-8 circular-slider mt-5">
                  <InputCircularTime
                    setBakeTime={setBakeTime}
                    bake_time={bake_time}
                    identifier={"bake"}
                    handleChangeMinutes={handleChangeMinutes}
                    handleChangeHours={handleChangeHours}
                  />
                </div>
              </div>
            </div>
          <span className='errorValidation'>{errors?.bake_time}</span>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-xl-6 col-md-12 col-11 mt-3 mb-3">
            <span className="recipe-name">Prep time </span>
            <span className="red-star"> *</span>
            <div>
              <span className="step-2-input-field">
                How much time do you actively spend making the dish?
              </span>
            </div>
            <div className="step-2-input-fields">
              <div className="row">
                <div className="col-xl-8 circular-slider mt-5">
                  <InputCircularTime
                    setPrepTime={setPrepTime}
                    prep_time={prep_time}
                    identifier={"prep"}
                    handleChangeMinutes={handleChangeMinutes}
                    handleChangeHours={handleChangeHours}
                  />
                </div>
              </div>
            </div>
          <span className='errorValidation'>{errors?.prep_time}</span>
          </div>
          <div className="col-xl-6 col-md-12 col-11 mt-3 mb-3">
            <span className="recipe-name">Resting time</span>
            <span className="red-star"> *</span>
            <div>
              <span className="step-2-input-field">
                Does the dish need to rest at any point? e.g. rising time,
                marination
              </span>
            </div>
            <div className="step-2-input-fields">
              <div className="row">
                <div className="col-xl-8 circular-slider mt-5">
                  <InputCircularTime
                    resting_time={resting_time}
                    setRestingTime={setRestingTime}
                    identifier={"rest"}
                    handleChangeMinutes={handleChangeMinutes}
                    handleChangeHours={handleChangeHours}
                  />
                </div>
              </div>
            </div>
          <span className='errorValidation'>{errors?.resting_time}</span>
          </div>
        </div>
        <hr className="mb-5 hr-color" />

        <div className="row  mb-5">
          <div className="col-xl-12 col-12 d-flex justify-content-between">
            <Link to="/create-recipe-step-1">
              <button
                type="button"
                className="btn btn-success btn-rounded previous-btn "
                onClick={handleClickRouting}
              >
                Previous
              </button>
            </Link>
              <button
                type="button"
                className="btn btn-success btn-rounded previous-btn "
                onClick={handleClickRoutingNext}
              >
                Next
              </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateRecipeStep2;

import React, { Component } from 'react'

import default_person from "../../assets/images/default_person.png";

import Moment from 'react-moment';



export class NotLoggedInComments extends Component {
    render() {
        return (
            <>
                {this.props?.comments?.map((comment) => {
                    return (
                        <React.Fragment key={comment.id}>
                            <div className="author-box" >
                                <div className="profile-box">
                                    <span className="author-img">
                                        {comment.user?.profile_img ? (<img src={comment?.user?.profile_img} alt="" className="img-fluid" />) : (<img src={default_person} alt="" className="img-fluid" />)}
                                    </span>
                                    <div className="author-content">
                                        <h4 className='p-0'>
                                            <strong>{comment?.user?.username}</strong>
                                            <span className="date">
                                                {<Moment format='MMM D, YYYY'>{comment?.time_stamp}</Moment>} at {<Moment format='h:mm a'>{comment?.time_stamp}</Moment>}
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                                <p>{comment?.comment} </p>
                            </div>
                            {comment?.replies?.length > 0 && comment?.replies.map((reply, index) => {
                                return (
                                    <div className="sub-comment" key={`${reply.time_stamp}${reply.user.email}${index}`} >
                                        <div className="author-box">
                                            <div className="profile-box">
                                                <span className="author-img">
                                                    {reply.user?.profile_img ? (<img src={reply?.user?.profile_img} alt="" className="img-fluid" />) : (<img src={default_person} alt="" className="img-fluid" />)}
                                                </span>
                                                <div className="author-content">
                                                    <h4 className='p-0' >
                                                        <strong>{reply?.user?.username}</strong>
                                                        <span className="date">
                                                {<Moment format='MMM D, YYYY'>{reply?.time_stamp}</Moment>} at {<Moment format='h:mm a'>{reply?.time_stamp}</Moment>}

                                                        </span>
                                                    </h4>
                                                </div>
                                            </div>
                                            <p>{reply?.reply}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </React.Fragment>
                    )

                })}
            </>
        )
    }
}

export default NotLoggedInComments;
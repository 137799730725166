import React from 'react'

const Cuisine = props => {
    return (

        <>
            {props.cuisines?.map((cuisine => {
                return (
                    <button
                        key={cuisine?.id}
                        type="button"
                        className="cuisine-btn"
                        style={cuisine.is_selected ? { background: '#85b492', color: "#fff" } : { background: '#FFF' }}
                        onClick={() => props.handleClickCuisine(cuisine?.id)}
                    >
                        {cuisine?.name}
                    </button>

                )
            }))}
        </>
    )
}

export default Cuisine
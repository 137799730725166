import React from 'react';
import { notification } from 'antd';
import { Navigate } from "react-router-dom";
import { magicLogin, submitLoginForm, googleSingUp, facebookSignUp, appleAuth, resetPassword } from '../apis'
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import AppleLogin from "react-apple-login";
import { ReactSession } from 'react-client-session';
import $ from 'jquery';
import IncorrectPasswordModal from './components/IncorrectPasswordModal';
import ForgotPasswordModal from './components/ForgotPasswordModal';
import { connect } from 'react-redux';
import { authActions } from '../store/auth';



class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: { email: "", password: "" },               //To store form values
            errors: { email: "", password: "" },                //To store form errors 
            invalidPasswordCount: 1,
            isInvalidPassword: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleClickLogin = this.handleClickLogin.bind(this);
        this.handleClickMagicLink = this.handleClickMagicLink.bind(this);
        this.handleClickResetPassword = this.handleClickResetPassword.bind(this);

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        //Input label effects JS
        $("input").val("");

        $(".input-effect input").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });
        $(".input-effect textarea").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });
        $(".input-effect select").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });
    }

    //to handle fields values 
    handleChange(e) {
        let fields = this.state.fields;
        let errors = this.state.errors;
        errors[e.target.name] = "";
        fields[e.target.name] = e.target.value;

        this.setState({
            errors: errors,
            fields
        })
    }

    //implements forgot password link
    handleClickMagicLink(e) {
        e.preventDefault();
        let email = this.state.fields.email.trim();
        if (this.validEmail()) {
            magicLogin(email)
                .then(resp => {
                    notification.success({
                        message: "Please check your inbox",
                        description: resp.data,
                        placement: "bottomRight"
                    })
                    this.afterFPMSuccess();
                    this.afterIPMSuccess();
                })
                .catch(err => {
                    if (err?.response?.data) {
                        notification.error({
                            description: err?.response?.data,
                            placement: 'bottomRight'
                        })
                    }
                })
        }
    }

    handleClickResetPassword(e) {
        e.preventDefault();
        let email = this.state.fields.email.trim();
        const userData = {
            "email": email
        }
        if (this.validEmail()) {
            resetPassword(userData)
                .then(resp => {
                    notification.success({
                        message: "Please check your inbox",
                        description: resp.data.detail,
                        placement: "bottomRight"
                    })
                    this.afterFPMSuccess();
                    ; this.afterIPMSuccess()
                })

                .catch(err => {
                    if (err?.response?.data) {
                        notification.error({
                            description: err?.response?.data,
                            placement: 'bottomRight'
                        })
                    }

                })
        }
    }



    //implements login button click
    handleClickLogin(event) {
        event.preventDefault();
        const userData = {
            'email': this.state.fields["email"]?.trim(),
            'password': this.state.fields["password"]
        }

        if (this.validateForm()) {
            submitLoginForm(userData)
                .then(resp => {
                    notification.success({
                        message: 'logged-in succesfully.',
                        description: resp.data.detail,
                        placement: 'bottomRight'
                    })
                    this.afterLoginSuccess(resp.data.results)
                })

                .catch(err => {
                    if (err.response.data?.detail) {
                        notification.error({
                            message: 'Login failed',
                            description: err.response.data.detail,
                            placement: 'bottomRight'
                        })

                        if (err.response.data.detail === "Invalid password entered.") {
                            this.setState(
                                {
                                    invalidPasswordCount: ++this.state.invalidPasswordCount,
                                }
                            )
                            if (this.state.invalidPasswordCount >= 4)
                                this.setState(
                                    {
                                        isInvalidPassword: true
                                    }
                                )
                        }
                    }
                })

        }
    }

    handleFacebookLogin = (response) => {
        const userData = {
            auth_token: response.accessToken,
        }

        facebookSignUp(userData)
            .then(resp => {
                notification.success({
                    message: 'You have successfully logged-in with facebook.',
                    description: resp,
                    placement: 'bottomRight'
                })
                this.afterLoginSuccess(resp?.data)
            })

            .catch(err => {
                if (err.response) {
                    notification.error({
                        message: 'Facebook login Failed',
                        description: err?.response?.data?.auth_token,
                        placement: 'bottomRight'
                    })
                }
            })
    }




    handleGoogleLogin = (googleData) => {

        let googleAccessToken = googleData.tokenId;
        const userData = {
            auth_token: googleAccessToken,
        }

        googleSingUp(userData)
            .then(resp => {
                notification.success({
                    message: 'You have successfully logged-in.',
                    placement: 'bottomRight'
                })
                this.afterLoginSuccess(resp.data)
            })

            .catch(err => {
                if (err.response) {
                    notification.error({
                        message: 'Google login Failed',
                        description: err?.response?.data?.detail,
                        placement: 'bottomRight'
                    })
                }
            })
    }




    afterLoginSuccess(resp) {
        const user_data ={
            'first_name' : resp.user.first_name,
            'last_name': resp.user.last_name,
            'username'     : resp.user.username,
            'profile_image': resp.user.profile_image   
        }

        ReactSession.set("user_data", user_data);
        ReactSession.set("authtoken", resp.key);

        this.props.login()
    }

    validEmail() {
        let errors = {};
        let isValid = true;

        let email = this.state.fields.email?.trim()

        // checking whather email value given or not
        if (!email) {
            isValid = false;
            errors["email"] = "*Please enter your email.";
        }

        // checking whather given email is valid or not
        else {
            //regex for email validation
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                isValid = false;
                errors["email"] = "*Please enter a valid email.";
            }
        }
        this.setState({
            errors: errors,
        })
        return isValid;

    }

    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let isValid = true;

        let email = this.state.fields.email?.trim()

        // checking whather email value given or not
        if (!email) {
            isValid = false;
            errors["email"] = "*Please enter your email.";
        }

        // checking whather given email is valid or not
        else {
            //regex for email validation
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                isValid = false;
                errors["email"] = "*Please enter a valid email.";
            }
        }
        // checking whather password value given or not
        if (!fields["password"]) {
            isValid = false;
            errors["password"] = "*Please enter your password.";
        }
        // checking whather password is satisfiying our conditions
        else {
            if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
                isValid = false;
                errors["password"] = "*please enter a valid password";
            }
        }

        this.setState({
            errors: errors,
        })
        return isValid;
    }

    resetstateIPM() {
        this.setState(
            {
                invalidPasswordCount: 1,
                isInvalidPassword: false
            }
        )
    }

    afterIPMSuccess() {
        this.ipmodal.handleCloseModal();
    }

    afterFPMSuccess() {
        this.fpmodal.handleCloseModal();
    }

    render() {
        let token = ReactSession.get('authtoken');
        return (

            // <!--Banner container start-->
            <div className="banner-content login-area" >
                {token && (<Navigate to="/" replace={true} />)}
                {this.state.isInvalidPassword &&
                    <IncorrectPasswordModal
                        ref={instance => { this.ipmodal = instance; }}
                        resetstateIPM={() => this.resetstateIPM()}
                        handleClickMagicLink={this.handleClickMagicLink}
                        handleClickResetPassword={this.handleClickResetPassword}
                        handleChange={(e) => this.handleChange(e)}
                        email={this.state?.fields?.email}
                        errorEmail={this.state.errors.email}
                        showModal={this.state.isInvalidPassword}
                    />
                }
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-12 col-md-12">
                            <h1 className="title-register">Login</h1>
                            <div className="contact-form">
                                <div className="row">
                                    <div className="col-12  form-group">
                                        <div className="input-effect">
                                            <input className="effect" type="text" name="email" value={this.state.fields.email} onChange={this.handleChange} autoComplete="off" />
                                            <label>Email</label>
                                            <span className='errorValidation'>{this.state.errors.email}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="input-effect">
                                            <input className="effect show-pw" id="pass_log_id" type="password" name="password" value={this.state.password} onChange={this.handleChange} autoComplete="off" />
                                            <span toggle="#password-fields" className="fa fa-fw fa-eye field_icon show-password"></span>
                                            <label>Password</label>
                                            <span className='errorValidation'>{this.state.errors.password}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-12 col-md-12 text-center">
                                        <a onClick={this.handleClickLogin} className="btn">Login</a>
                                        <ForgotPasswordModal
                                            ref={instance => { this.fpmodal = instance; }}
                                            handleClickMagicLink={this.handleClickMagicLink}
                                            handleClickResetPassword={this.handleClickResetPassword}
                                            handleChange={(e) => this.handleChange(e)}
                                            email={this.state?.fields?.email}
                                            errorEmail={this.state.errors.email}
                                        />
                                    </div>
                                    <div className="col-12 col-xl-12 col-md-12">
                                        <div className="login-other">
                                            <h3><strong >Or login with</strong></h3>
                                            <ul>
                                                {/* keep these li elements commented as these components inside li also create li elements which causes the error. */}
                                                {/* <li> */}
                                                <FacebookLogin
                                                    appId={process.env.React_App_facebook_App_Id}
                                                    fields="name,email,picture"
                                                    callback={this.handleFacebookLogin}
                                                    render={renderProps => (
                                                        <li onClick={renderProps.onClick}><a ></a></li>
                                                    )}
                                                /> &nbsp; &nbsp;
                                                {/* </li> */}
                                                {/* <li> */}
                                                <GoogleLogin
                                                    clientId={process.env.React_App_google_Client_Id}
                                                    // isSignedIn={true}
                                                    onSuccess={this.handleGoogleLogin}
                                                    render={renderProps => (
                                                        <li onClick={renderProps.onClick}><a className="google-icon"></a></li>
                                                    )}
                                                />&nbsp; &nbsp;

                                                {/* </li> */}
                                                {/* <li> */}
                                                <AppleLogin
                                                    clientId={process.env.React_App_Apple_Client_Id}
                                                    responseMode="form_post"
                                                    responseType='code id_token'
                                                    scope='name email'
                                                    redirectURI="https://dev.flavorable.io/api/user/v1/apple/signup_success/"
                                                    render={renderProps => (
                                                        <li onClick={renderProps.onClick}><a className="apple-icon"></a></li>
                                                    )}
                                                />
                                                {/* </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            // {/* <!--Banner container end--> */}
        )
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
}

const mapDispatchToProps = dispatch => {
    return {
        login: () => dispatch(authActions.login())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
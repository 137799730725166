import React, { useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../components/Loading";
import {
  faMagnifyingGlass,
  faCartPlus,
  faScaleBalanced,
} from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/tcreate-recipe.css";

const ListIngredients = (props) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <a className="btn btn-add-ingredient" onClick={handleOpenModal}>
        Add Ingreadients
      </a>
      <span className='errorValidation'>{props.ingredient_error}</span>


      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        className="forgotPasswordModal ingredient-modal add-ingredient-model"
        overlayClassName="forgotPasswordOverlay"
        closeTimeoutMS={500}
        ariaHideApp={false}
      >
        <>
          <span style={{ position: "relative" }}>
            <input
              className="ingredient-search-field"
              id="ingredient-search"
              type="text"
              placeholder="Enter ingredient name"
              onChange={props.handleChangeIngredient}
              value={props.enteredSearch}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  props.searchIngrediets();
                }
              }}
            />
            <div
              onClick={props.searchIngrediets}
              className="search-recipe-icon"
            >
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                size="xl"
                transform="shrink-6"
                color="#85B492"
                title={"Search"}
              />
              {props.is_loading && (
                <div className="spinner-border text-info" role="status">
                  <span className="visually-hidden"></span>
                </div>
              )}
            </div>
          </span>
          {!props.is_loading ? (
            <>
              {props.count ? (
                <ol>
                  {props.ingredients?.map((ingredient) => {
                    return (
                      <InfiniteScroll
                        dataLength={props?.count}
                        next={props?.fetchMoreIngredients}
                        hasMore={props?.next}
                        key={ingredient?.id}
                      >
                        <li
                          style={
                            ingredient?.is_selected
                              ? { background: "azure" }
                              : {}
                          }
                        >
                          <a style={{ width: "100%" }}>
                            {!ingredient?.is_selected && (
                              <div
                                onClick={() =>
                                  props.handleClickIngredients(ingredient.id)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faSquarePlus}
                                  size="2x"
                                />
                              </div>
                            )}
                            {ingredient?.is_selected && (
                              <div
                                onClick={() =>
                                  props.handleClickIngredients(ingredient.id)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faSquareCheck}
                                  size="2x"
                                  color="#85B492"
                                />
                              </div>
                            )}
                            <p className="ingredient-name">
                              {ingredient?.name}
                            </p>
                            {ingredient?.image ? (
                              <img
                                className="modal-ing-img"
                                src={ingredient?.image}
                                alt="ingredients"
                              />
                            ) : (
                              <>
                                <FontAwesomeIcon
                                  icon={faCartPlus}
                                  size="2x"
                                  color="#85B492"
                                />
                                {ingredient?.is_selected}
                              </>
                            )}
                          </a>
                        </li>
                      </InfiniteScroll>
                    );
                  })}
                </ol>
              ) : (
                <h1 className="mt-5" style={{ textAlign: "center" }}>
                  {" "}
                  No match found
                </h1>
              )}
            </>
          ) : (
            <h1 style={{ textAlign: "center" }}>Searching...</h1>
          )}
        </>
      </Modal>
    </>
  );
};

export default ListIngredients;

import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
    refreshblogs: 1,
};

const refreshblogsSlice = createSlice({
    name: 'refreshblogs',
    initialState: initialAuthState,
    reducers: {
        blogsRefresh(state) {
            state.refreshblogs = state.refreshblogs + 1;
        }
    },
});

export const refreshBlogsActions = refreshblogsSlice.actions;

export default refreshblogsSlice.reducer;
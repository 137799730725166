import { React, Component } from 'react'
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import Moment from 'react-moment';
import TagBlogs from './TagBlogs';



export class Article extends Component {
    render() {
        let title = this.props?.title?.replaceAll(" ", "-");
        return (
            <>

                <Link to={`/blog/${this.props?.id}/${title}`} className="blog-box">
                    <LazyLoad>
                        <span className="blog-thumbnail" >
                            {this.props?.images?.filter(tru => tru?.is_hero === true)?.map(img => (
                                < img src={img?.image} alt="" key={img?.image} className="img-fluid" />
                            ))}
                        </span>
                        <h2 className="title" >
                            <span>{<Moment format='MMM D, YYYY'>{this.props?.date}</Moment>}</span>
                            {this.props?.title} </h2>
                        <p className='blog-paragraph-post'  >{this.props?.abstract}</p>
                    </LazyLoad>
                    <div className="discover">
                        <TagBlogs
                            tags={this.props?.tags}
                        />
                    </div>
                </Link>

            </>
        )
    }
}

export default Article;
import React from "react";
import { notification } from "antd";
import { submitRegisterForm } from "../apis";
import { Link, Navigate } from "react-router-dom";
import $ from "jquery";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AppleLogin from "react-apple-login";
import { googleSingUp, facebookSignUp, appleAuth } from "../apis";
import { ReactSession } from "react-client-session";
import { connect } from "react-redux";
import { authActions } from "../store/auth";
import PhoneInput from "react-phone-input-2";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
      },
      errors: {},
      isRegistered: false,
      isChecked: false,
      country_code: "",
      phone: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCheckBoxOnChange = this.handleCheckBoxOnChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    //Input label effects JS
    $("input").val("");

    $(".input-effect input").focusout(function () {
      if ($(this).val() !== "") {
        $(this).addClass("has-content");
      } else {
        $(this).removeClass("has-content");
      }
    });
    $(".input-effect textarea").focusout(function () {
      if ($(this).val() !== "") {
        $(this).addClass("has-content");
      } else {
        $(this).removeClass("has-content");
      }
    });
    $(".input-effect select").focusout(function () {
      if ($(this).val() !== "") {
        $(this).addClass("has-content");
      } else {
        $(this).removeClass("has-content");
      }
    });
  }

  handleChange(e) {
    let fields = this.state.fields;
    let errors = this.state.errors;
    fields[e.target.name] = e.target.value;
    errors[e.target.name] = "";

    this.setState({
      errors: errors,
      fields,
    });
  }

  handleClick(event) {
    event.preventDefault();
    let firstName = "";
    let lastName = "";
    let name = "";

    name = this.state.fields["fullName"]?.trim().split(" ");

    const length = name?.length;
    if (name) {
      firstName = name[0];
      lastName = name[length - 1];
    }


    const userData = {
      first_name: firstName,
      last_name: lastName,
      email: this.state.fields["email"],
      password: this.state.fields["password"],
      country_code: this.state.country_code,
      phone: this.state.fields.phone,
    };

    if (this.validateForm()) {
      submitRegisterForm(userData)
        .then((resp) => {
          notification.success({
            message: "You have registered sucessfully!",
            description: resp.data.detail,
            placement: "bottomRight",
          });
          let isRegistered = true;
          this.setState({
            isRegistered,
          });
        })

        .catch((err) => {
          if (err.response.data.detail) {
            notification.error({
              message: "Registration failed.",
              description: err.response.data.detail,
              placement: "bottomRight",
            });
          }
        });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let isValid = true;
    let isChecked = this.state.isChecked;
    let fullName = fields["fullName"]?.trim();
    let email = fields.email?.trim();

    if (!fullName) {
      isValid = false;
      errors["fullName"] = "*Please enter your full name.";
    } else {
      //checking last name is entered or not
      if (fullName.split(" ").length < 2) {
        isValid = false;
        errors["fullName"] = "*Please enter your last name.";
      }
      // checking only alphabet chars are passed
      else if (!fields["fullName"].match(/^[a-zA-Z ]*$/)) {
        isValid = false;
        errors["fullName"] = "*Please enter alphabets only.";
      }
    }

    // checking whather email value given or not
    if (!email) {
      isValid = false;
      errors["email"] = "*Please enter your email.";
    }

    // checking whather given email is valid or not
    else {
      //regex for email validation
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        errors["email"] = "*Please enter valid email.";
      }
    }
    // checking whather password value given or not
    if (!fields["password"]) {
      isValid = false;
      errors["password"] = "*Please enter your password.";
    }
    // checking whather password is satisfiying our conditions
    else {
      if (
        !fields["password"].match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*()-_]).*$/
        )
      ) {
        isValid = false;
        errors["password"] =
          "*Please enter secure and strong password. <br> Password must satistfy following criteria: <br> Length should be more than 8.<br>Must contail atleast one number. <br> Must contain alteast one upercase alphabet.<br> Must contain any of this specian char(@, #, $, %, &).";
      }
    }

    // checking whather confirm password value given or not
    if (!fields["confirmPassword"]) {
      isValid = false;
      errors["confirmPassword"] = "*Please enter confirm password.";
    }

    // checking whather confirm password & password are matching
    else {
      if (fields["confirmPassword"] !== fields["password"]) {
        isValid = false;
        errors["confirmPassword"] =
          "*Confirm Password must match the password .";
      }
    }
    if (isChecked !== true) {
      isValid = false;
      errors["erroeCheckBox"] =
        '*Please agree to the "Terms of Service" and "Privacy Policy".';
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }
  handleCheckBoxOnChange = () => {
    let errors = this.state.errors;
    errors.erroeCheckBox = "";
    this.setState({
      errors: errors,
      isChecked: !this.state.isChecked,
    });
  };

  handleFacebookLogin = (response) => {
    const userData = {
      auth_token: response.accessToken,
    };

    facebookSignUp(userData)
      .then((resp) => {
        notification.success({
          message: "You have successfully logged-in with facebook.",
          description: resp,
          placement: "bottomRight",
        });
        this.afterLoginSuccess(resp.data);
      })
      .catch((err) => {
        if (err.response) {
          notification.error({
            message: "Facebook login Failed",
            description: err?.response?.data?.auth_token,
            placement: "bottomRight",
          });
        }
      });
  };

  handleGoogleLogin = (googleData) => {
    let googleAccessToken = googleData.tokenId;
    const userData = {
      auth_token: googleAccessToken,
    };

    googleSingUp(userData)
      .then((resp) => {
        notification.success({
          message: "You have successfully logged-in.",
          placement: "bottomRight",
        });
        this.afterLoginSuccess(resp.data);
      })

      .catch((err) => {
        if (err.response) {
          notification.error({
            message: "Google login Failed",
            description: err?.response?.data?.detail,
            placement: "bottomRight",
          });
        }
      });
  };

  afterLoginSuccess(resp) {
    ReactSession.set("user_data", resp);
    ReactSession.set("authtoken", resp.key);
    this.props.login();
  }
  handleChnageCountryCode(country_code) {
    this.setState({ country_code: country_code });
  }

  render() {
    let token = ReactSession.get("authtoken");
    let isRegistered = this.state.isRegistered;
    return (
      // <!--Banner container start-->
      <div className="banner-content login-area">
        {isRegistered && <Navigate to="/login" replace={true} />}
        {token && <Navigate to="/" replace={true} />}
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-12 col-md-12">
              <h1 className="title-register">Register</h1>
              <div className="contact-form">
                <div className="row">
                  <div className="col-12 form-group">
                    <div className="input-effect">
                      <input
                        className="effect"
                        type="text"
                        name="fullName"
                        value={this.state.fields.fullName}
                        onChange={this.handleChange}
                        autoComplete="off"
                      />
                      <label>Full Name</label>
                      <span className="errorValidation">
                        {this.state.errors.fullName}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 form-group">
                    <div className="input-effect">
                      <input
                        className="effect"
                        type="text"
                        name="email"
                        value={this.state.fields.email}
                        onChange={this.handleChange}
                        autoComplete="off"
                      />
                      <label>Email</label>
                      <span className="errorValidation">
                        {this.state.errors.email}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 form-group">
                    <div className="input-effect">
                      <input
                        className="effect show-pw"
                        id="pass_log_id"
                        type="password"
                        name="password"
                        value={this.state.fields.password}
                        onChange={this.handleChange}
                        autoComplete="off"
                      />
                      <span
                        toggle="#password-field"
                        className="fa fa-fw fa-eye field_icon show-password"
                      ></span>
                      <label>Password</label>
                      <span
                        className="errorValidation"
                        dangerouslySetInnerHTML={{
                          __html: this.state.errors.password,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 form-group">
                    <div className="input-effect">
                      <input
                        className="effect show-pw"
                        id="pass_log_id_confirm"
                        type="password"
                        name="confirmPassword"
                        value={this.state.fields.confirmPassword}
                        onChange={this.handleChange}
                        autoComplete="off"
                      />
                      <span
                        toggle="#password-field"
                        className="fa fa-fw fa-eye field_icon show-password1"
                      ></span>
                      <label>Confirm Password</label>
                      <span className="errorValidation">
                        {this.state.errors.confirmPassword}
                      </span>
                    </div>
                  </div>
                <div className="col-12 form-group">
                  <div className="d-flex">
                    <PhoneInput
                      country={"au"}
                      enableSearch={true}
                      value={this.state?.country_code}
                      onChange={(country_code) =>
                        this.handleChnageCountryCode(country_code)
                      }
                      inputStyle={{ width: "100px", height: "38px" }}
                      containerStyle={{ width: "50%" }}
                      autoFormat={false}
                    />
                      <div className="input-effect ">
                        <input
                          className="effect"
                          type="number"
                          name="phone"
                          value={this.state?.fields?.phone}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label>Phone</label>
                        <span className="errorValidation">
                          {this.state.errors.phone}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-12 col-md-12 text-center">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheckBox1"
                        onChange={this.handleCheckBoxOnChange}
                        defaultChecked={this.state.isChecked}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckBox1"
                      >
                        I agree to the <Link to="/tnc">Terms of Service</Link>{" "}
                        and <Link to="/privacy">Privacy Policy</Link>
                      </label>

                      <div className="errorValidation">
                        {this.state.errors.erroeCheckBox}
                      </div>
                    </div>
                    <div className=" col-xl-6 col-md-6 col-11 mb-3"></div>
                    <a onClick={this.handleClick} className="btn mt-4">
                      Register
                    </a>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <span>
                      Already have an account?{" "}
                      <Link to="/login">Log In Here</Link>
                    </span>
                  </div>
                  <div className="col-12 col-xl-12 col-md-12">
                    <div className="login-other">
                      <h3>
                        <strong>Or login with</strong>
                      </h3>
                      <ul>
                        {/* <li> */}
                        <FacebookLogin
                          appId={process.env.React_App_facebook_App_Id}
                          fields="name,email,picture"
                          callback={this.handleFacebookLogin}
                          render={(renderProps) => (
                            <li onClick={renderProps.onClick}>
                              <a></a>
                            </li>
                          )}
                        />{" "}
                        &nbsp; &nbsp;
                        {/* </li>
                                                <li> */}
                        <GoogleLogin
                          clientId={process.env.React_App_google_Client_Id}
                          // isSignedIn={true}
                          onSuccess={this.handleGoogleLogin}
                          render={(renderProps) => (
                            <li onClick={renderProps.onClick}>
                              <a className="google-icon"></a>
                            </li>
                          )}
                        />
                        &nbsp; &nbsp;
                        {/* </li>
                                                <li> */}
                        <AppleLogin
                          clientId={process.env.React_App_Apple_Client_Id}
                          responseMode="form_post"
                          responseType="code id_token"
                          scope="name email"
                          redirectURI="https://dev.flavorable.io/api/user/v1/apple/signup_success/"
                          render={(renderProps) => (
                            <li onClick={renderProps.onClick}>
                              <a className="apple-icon"></a>
                            </li>
                          )}
                        />
                        {/* </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      // {/* <!--Banner container end-->  */}
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: () => dispatch(authActions.login()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);

import React, { useEffect, useState, useReducer } from "react";
import "../../assets/css/tcreate-recipe.css";
import { ReactSession } from "react-client-session";
import { Link, Navigate, useParams } from "react-router-dom";
import { getCuisine, getGeography, getTest, recipe_type } from "../../apis";
import BakeTime from "../components/BakeTime";
import Cuisine from "../components/Cuisine";
import Taste from "../components/Taste";
import Occasion from "../components/Occasion";
import { useSelector, useDispatch } from "react-redux";
import { createRecipeActions } from "../../store/createRecipe";
import { retrieveRecipe } from "../../apis";
import { recipeDataActions } from "../../store/recipeData";
import { type } from "@testing-library/user-event/dist/type";


let initial_cuisines_state = {list_cuisines:{},selected_cuisines:[] }
let initial_bake_state = {list_bakes:{},selected_bakes:[] }
let initial_tastes_state = {list_tastes:{},selected_testes:[] }
let initial_occasions_state = {list_occasions:{},selected_occasions:[] }


const occasionsReducer = (current_occasion_state,action)=>{
switch (action.type) {
  case'set_occasions':
    return{'selected_occasions':action?.selected_occasion,'list_occasions' :action?.occasion_list}
    
    case'set_selected_occasion':
    let selected = []
    action.selected_occasion?.map((occasion)=>{
      if(action.fetched_occasion?.results?.length){
        for(let data of action.fetched_occasion?.results){
          if(data.name ===  occasion ){
            data.is_selected = true
            selected.push(data.id)
          }
        }
      }
    })
    return{'list_occasions':current_occasion_state?.list_occasions,"selected_occasions":selected}

    case'toggle_select_occasion':
    return action.new_state
}
}
const cuisinesReducer = (current_cuisine_state,action)=>{
switch (action.type) {
  case'set_cuisnes':
    return{'selected_cuisines':action?.selected_cuisine,'list_cuisines' :action?.cuisine_list}
    
    case'set_selected_cuisine':
    let selected = []
    action.selected_cuisine?.map((cuisine)=>{
      if(action.fetched_cuisine?.results?.length){
        for(let data of action.fetched_cuisine?.results){
          if(data.name ===  cuisine ){
            data.is_selected = true
            selected.push(data.id)
          }
        }
      }
    })
    return{'list_cuisines':current_cuisine_state?.list_cuisines,"selected_cuisines":selected}

    case'toggle_select_cuisine':
    return action.new_state
}
}

const bakeReducer = (current_bake_state,action)=>{
switch (action.type) {
  case'set_bakes':
    return{'selected_bakes':action?.selected_bake,'list_bakes':action?.bake_list}
    
    
    case'set_selected_bake':
    let selected = [];
    action.selected_bake?.map((bake)=>{
      if(action.fetched_bake?.results.length){
        for(let data of action.fetched_bake?.results){
          if(data.name ===  bake ){
            data.is_selected = true
            selected.push(data.id)
          }
        }
      }
      
    })
    return{'list_bakes':current_bake_state.list_bakes,"selected_bakes":selected}

    case'toggle_select_bake':
    return action.new_state
}
}

// this is the reducer for the the tastes.
const tastesReducer = (current_taste_state,action)=>{
switch (action.type) {
  case'set_tastes':
    return{'selected_tastes':action?.selected_taste,'list_tastes':action?.taste_list}

    
    case'set_selected_taste':
    let selected = [];
    action.selected_taste?.map((taste)=>{
      if(action.fetched_taste?.results?.length){
        for(let data of action.fetched_taste?.results){
          if(data.name ===  taste ){
            data.is_selected = true
            selected.push(data.id)
          }
        }
      }
      
    })
    return{'list_tastes':current_taste_state?.list_tastes,"selected_tastes":selected}

    case'toggle_select_taste':
    return action.new_state
}
}

const EditRecipeStep4 = (props) => {

  const [cuisine,dispatchCuisine ] = useReducer(cuisinesReducer,initial_cuisines_state)
  const [bake,dispatchBake ] = useReducer(bakeReducer,initial_bake_state)
  const [taste,dispatchTaste ] = useReducer(tastesReducer,initial_tastes_state)
  const [occasion,dispatchOccasion ] = useReducer(occasionsReducer,initial_occasions_state)
  const [is_validated, setIsValidate] = useState(false);


  const params = useParams();



  const [errors, setErrors] = useState({})

  const [form, setForm] = useState({
    dish_type: "",
    chefs_note: "",
  });

  const dispatch = useDispatch();

  const create_recipe = useSelector(
    (state) => state.createrecipe.create_recipe
  );
  const recipe_data = useSelector((state) => state.recipedata);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (recipe_data.stored_taste.results) {
      dispatchCuisine({type:'set_cuisnes',cuisine_list:JSON.parse(JSON.stringify(recipe_data?.stored_cuisine)),selected_cuisine:JSON.parse(JSON.stringify(create_recipe?.recipe_selected_cuisines))})
      dispatchBake({type:'set_bakes',bake_list:JSON.parse(JSON.stringify(recipe_data?.stored_bake)),selected_bake:JSON.parse(JSON.stringify(create_recipe?.recipe_selected_bakes))})
      dispatchTaste({type:'set_tastes',taste_list:JSON.parse(JSON.stringify(recipe_data?.stored_taste)), selected_taste:JSON.parse(JSON.stringify(create_recipe?.recipe_selected_tastes))})
      dispatchOccasion({type:'set_occasions',occasion_list:JSON.parse(JSON.stringify(recipe_data?.stored_occasion)), selected_occasion:JSON.parse(JSON.stringify(create_recipe?.recipe_selected_occasions))})
      setForm({chefs_note : create_recipe?.chefs_note})
    } else {
      let fetched_cuisines = null;
      let fetched_occasions = null;
      let fetched_bakes = null;
      let fetched_tastes = null;

      // to get the available cuisines
      getCuisine()
        .then((resp) => {
          for (let data of resp.data.results) {
            data.is_selected = false;
          }
          fetched_cuisines=resp.data
          dispatchCuisine({type:'set_cuisnes',cuisine_list:resp.data})
        })

        .catch((err) => {
          console.log("this is the error", err.response);
        });

        //get occasion
        getGeography()
        .then((resp) => {
          for (let data of resp.data.results) {
            data.is_selected = false;
          }
          fetched_occasions= resp.data
          dispatchOccasion({type:'set_occasions',occasion_list:resp.data})
        })

      // bake time
      recipe_type()
        .then((resp) => {
          for (let data of resp.data.results) {
            data.is_selected = false;
          }
          fetched_bakes = resp.data
          dispatchBake({type:'set_bakes',bake_list:resp.data})
        })
        .catch((err) => {
          console.log("this is the error", err.response);
        });

      //
      getTest()
        .then((resp) => {
          for (let data of resp.data.results) {
            data.is_selected = false;
          }
          fetched_tastes=resp.data
        dispatchTaste({type:'set_tastes',taste_list:resp.data}) 
        })

        .catch((err) => {
          console.log("this is the error", err.response);
        });

        retrieveRecipe(params.slug)
        .then((resp)=>{
          dispatchCuisine({type:'set_selected_cuisine','selected_cuisine':resp.data.results.cuisine,'fetched_cuisine':fetched_cuisines})
          dispatchBake({type:'set_selected_bake','selected_bake':resp.data.results.recipe_type,'fetched_bake':fetched_bakes})
          dispatchTaste({type:'set_selected_taste','selected_taste':resp.data.results.taste,'fetched_taste':fetched_tastes})
          dispatchOccasion({type:'set_selected_occasion','selected_occasion':resp.data.results.recipe_geography,'fetched_occasion':fetched_occasions})
        })
        .catch((err)=>{
          console.log("this is the error", err.response);
        })
    }
  }, []);



  // to handle the event when some cuisine is selected
  const handleClickCuisine = (id) => {
    const cuisines = { ...cuisine };


    for (let result of cuisine.list_cuisines.results) {
      if (result.id == id) {
        result.is_selected = !result.is_selected;
        if (result.is_selected) {
          cuisines.selected_cuisines.push(id);
        }
        if (!result.is_selected) {
          let index = cuisines.selected_cuisines.indexOf(id);
          // only splice array when item is found
          if (index > -1) {
            // 2nd parameter means remove one item only
            cuisines.selected_cuisines.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }
    }
    dispatchCuisine({type:'toggle_select_cuisine',new_state:cuisines})  
    setErrors({
      ...errors,
      cuisines:''
    })
  };
  const handleClickOccasion = (id) => {
    const occasions = { ...occasion };
    for (let result of occasions.list_occasions.results) {
      if (result.id == id) {
        result.is_selected = !result.is_selected;
        if (result.is_selected) {
          occasions.selected_occasions.push(id);
        }
        if (!result.is_selected) {
          let index = occasions.selected_occasions.indexOf(id);
          // only splice array when item is found
          if (index > -1) {
            // 2nd parameter means remove one item only
            occasions.selected_occasions.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }
    }
    dispatchOccasion({type:'toggle_select_occasion',new_state:occasions})  
    setErrors({
      ...errors,
      occasions:''
    })
  };

  const handleClickBakeTime = (id) => {
    const bakes = {...bake}
    for (let result of bakes?.list_bakes?.results) {
      if (result.id == id) {
        result.is_selected = !result.is_selected;
        if (result.is_selected) {
          bakes.selected_bakes.push(id);
        }
        if (!result.is_selected) {
          let index = bakes.selected_bakes.indexOf(id);
          // only splice array when item is found
          if (index > -1) {
            // 2nd parameter means remove one item only
            bakes.selected_bakes.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }
    }
    dispatchBake({type:'toggle_select_bake',new_state:bakes})  
    setErrors({
      ...errors,
      bakes:''
    })
  };

  const handleClickTaste = (id) => {
    const tastes = {...taste};

    for (let result of tastes.list_tastes.results) {
      if (result.id == id) {
        result.is_selected = !result.is_selected;
        if (result.is_selected) {

          tastes?.selected_tastes?.push(id);
        }
        if (!result.is_selected) {
          let index = tastes?.selected_tastes?.indexOf(id);
          // only splice array when item is found
          if (index > -1) {
            // 2nd parameter means remove one item only
            tastes?.selected_tastes?.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }
    }

    dispatchTaste({type:'toggle_select_taste',new_state:tastes}) 
    setErrors({
      ...errors,
      tastes:''
    })

  };

  const handleChangefield = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      chefs_note:''
    })

    dispatch(
      createRecipeActions.createRecipe({
        ...create_recipe,
        [e.target.name]: e.target.value,
      })
    );

  };

  const handleClickRouting = () => {
    dispatch(
      createRecipeActions.createRecipe({
        ...create_recipe,
        recipe_selected_cuisines:cuisine.selected_cuisines,
        recipe_selected_bakes:bake.selected_bakes,
        recipe_selected_tastes:taste.selected_tastes,
        recipe_selected_occasions:occasion.selected_occasions
      })
    );

    dispatch(
      recipeDataActions.recipeData({
        ...recipe_data,
        stored_cuisine: cuisine.list_cuisines,
        stored_bake: bake.list_bakes,
        stored_taste: taste.list_tastes,
        stored_occasion: occasion.list_occasions,
      })
    );
  };

  const isValidated = () => {
    let is_validted = true;
    let error = {};
    if (!cuisine?.selected_cuisines.length) {
      is_validted = false;
      error.cuisines = "Please select atleast one cuisine from above list ";
    }
    if (!bake?.selected_bakes.length) {
      is_validted = false;
      error.bakes = "Please select atleast one dish type from above list";
    }
    if (!taste?.selected_tastes.length) {
      is_validted = false;
      error.tastes = "Please select atleast one taste from above list";
    }
    if (!occasion?.selected_occasions.length) {
      is_validted = false;
      error.occasions = "Please select atleast one occasion from above list";
    }
    if (!form.chefs_note) {
      is_validted = false;
      error.chefs_note = "Please enter the chefs note";
    }
    setErrors(error);
    return is_validted;
  };
  const handleClickRoutingNext=()=>{
    if (isValidated()) {
      handleClickRouting()
      setIsValidate(true)
      
    }
  }

  return (
    <>
     {is_validated && (<Navigate to={`/edit-recipe-step-5/${params.slug}`} replace={true} />)}
      <section className="create-recipe-page-1">
        <div className="container">
          <div className="row d-flex justify-content-center mt-2">
            <div className="col-11 col-xl-12 col-md-12 col-lg-6">
              <h5 className="create-recipe-title">
                {create_recipe?.recipe_name}
              </h5>
              <h1 className="create-recipe-heading">Create Recipe</h1>
              <p className="col-xl-6 col-11 p-0 create-recipe-paragraph-section-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est,
                adipiscing cursus auctor eget sed phasellus senectus.{" "}
              </p>
            </div>
            <div className="col-xl-12 col-11">
              <ul className="progressbar pl-0">
                <li className="active">Step 1</li>
                <li>Step 2</li>
                <li>Step 3</li>
                <li>Step 4</li>
              </ul>
            </div>
            <div className="col-xl-12 col-11">
              <div className="progress mt-2 p-0" style={{ height: "5px" }}>
                <div
                  className="progress-bar steps-bg-color"
                  role="progressbar"
                  aria-label="Example 1px high"
                  style={{ width: "100%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-4 col-md-12 col-11 mt-3">
            <span className="recipe-name">Dish Type</span>
            <span className="red-star"> *</span>
            <div className="step-2-input-fields">
            <span className='errorValidation'>{errors?.occasions}</span>
              <div className="row">
                <div className="col-xl-12 form-group mt-2">
                  <div className="input-effect-create-recips">
                     <Occasion
                  occasion={occasion?.list_occasions?.results}
                  handleClickOccasion={handleClickOccasion}
                />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="mt-2 mb-2">
                <span className="recipe-name"> Cuisine</span>
                <span className="red-star"> *</span>
              </div>
            <span className='errorValidation'>{errors?.cuisines}</span>
              <div>
                <Cuisine
                  cuisines={cuisine?.list_cuisines?.results}
                  handleClickCuisine={handleClickCuisine}
                />
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-md-12 col-11 mt-3">
            <div>
              <span className="recipe-name">Bake time</span>
              <span className="red-star"> *</span>
            </div>
            <span className='errorValidation'>{errors?.bakes}</span>
            <div className="mt-2">
              <BakeTime
                bake_times={bake.list_bakes?.results}
                handleClickBakeTime={handleClickBakeTime}
              />
            </div>
            <hr></hr>
            <div className="mt-3">
              <div>
                <span className="recipe-name">Resting time</span>
                <span className="red-star"> *</span>
              </div>
            <span className='errorValidation'>{errors?.tastes}</span>
              <div className="mt-3">
                <Taste
                  tastes={taste.list_tastes?.results}
                  handleClickTaste={handleClickTaste}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-12 col-10 mt-3">
            <span className="recipe-name">Chef’s note</span>
            <span className="red-star"> *</span>
            <div className="row">
              <div className="col-xl-12 form-group mt-2">
                <div className="input-effect-create-recips">
                  <input
                    className="effect-1"
                    type="text"
                    name="chefs_note"
                    autoComplete="off"
                    placeholder="E.g. Dessert"
                    value={create_recipe.chefs_note}
                    onChange={handleChangefield}
                  />
            <span className='errorValidation'>{errors?.chefs_note}</span>

                  {/* <label>E.g. Dessert</label> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mb-5 hr-color" />
        <div className="row  mb-5">
          <div className="col-xl-12 col-12 d-flex justify-content-between">
            <Link to={`/edit-recipe-step-3/${params.slug}`}>
              <button
                type="button"
                className="btn btn-success btn-rounded previous-btn "
                onClick={handleClickRouting}
              >
                Previous
              </button>
            </Link>
              <button
                type="button"
                className="btn btn-success btn-rounded previous-btn "
                onClick={handleClickRoutingNext}
              >
                Preview Before Submitting
              </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditRecipeStep4;

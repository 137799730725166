import React, { Component } from "react";
import { updateUser, listSubscriptonsPlans, subscriptionPreview, modifySubscription, retrieveUser } from "../apis";
import { ReactSession } from "react-client-session";
import Subscription_plan from "./components/subscription_plan";
import Subscription_switch_modal from "./components/plan_switch_modal";
import { notification } from "antd";

export default class Price extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_info:{},
      subscriptions_plans: null,
      monthly_sub_plans: [],
      yearly_sub_plans: [],
      isMonthly: true,
      isYearly: false,
      show_modal: false,
      currunt_plan_id: "",
      preview_invoice: null,
      current_subscription_plan: null
    };
    this.handleClickMonthly = this.handleClickMonthly.bind(this);
    this.handleClickYearly = this.handleClickYearly.bind(this);
    this.handleClosePlanSwitchModal =
      this.handleClosePlanSwitchModal.bind(this);
    this.handleOpenPlanSwitchModal = this.handleOpenPlanSwitchModal.bind(this);
    this.handleClickSwitch = this.handleClickSwitch.bind(this);
    this.handleClickSwitchPreview= this.handleClickSwitchPreview.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    // getting the subscription plans
    listSubscriptonsPlans()
      .then((resp) => {
        let monthly_sub_plans = [];
        let yearly_sub_plans = [];
        let current_sub
        for (let plan of resp?.data?.results) {
          // adding yearly plans in montly_sub_plans array
          if (plan.plan_type === "Monthly") {
            monthly_sub_plans.push(plan);
          }
          // adding yearly plans in yearly_sub_plans array
          else if (plan.plan_type === "Yearly") {
            yearly_sub_plans.push(plan);
          }
          if(plan.is_subscribed){
            current_sub=plan
        }}

        this.setState({
          subscriptions_plans: resp.data,
          monthly_sub_plans: monthly_sub_plans,
          yearly_sub_plans: yearly_sub_plans,
          current_subscription_plan:current_sub
        });
      })

      .catch((err) => {
      });

      retrieveUser()
      .then((resp)=>{
        this.setState({
          user_info: resp.data,
        })
      })
      .catch((err) => {
        console.log("some error occured while geting user data", err.response);
      });
  }

  handleClickMonthly(e) {
    e.preventDefault();

    if (this.state.isMonthly === true) {
      return;
    }


    this.setState({
      isMonthly: true,
      isYearly: false,
    });
  }

  handleClickYearly(e) {
    e.preventDefault();

    if (this.state.isYearly === true) {
      return;
    }



    this.setState({
      isMonthly: false,
      isYearly: true,
    });
  }

  handleClickSwitch(new_plan_id) {
    const new_plan = this.state.subscriptions_plans?.results?.filter(
      (currunt_plan) => currunt_plan?.id === new_plan_id
    );


      const subscripton_data={
        "plan_id": new_plan_id,
      }
  
      modifySubscription(subscripton_data)
        .then((resp) => {
          notification.success({
            message: resp?.data?.detail,
            placement: "bottomRight"
        })

          listSubscriptonsPlans()
          .then((resp) => {
            let monthly_sub_plans = [];
            let yearly_sub_plans = [];
            let current_sub;
            for (let plan of resp?.data?.results) {
              // adding yearly plans in montly_sub_plans array
              if (plan.plan_type === "Monthly") {
                monthly_sub_plans.push(plan);
              }
              // adding yearly plans in yearly_sub_plans array
              else if (plan.plan_type === "Yearly") {
                yearly_sub_plans.push(plan);
              }
              if(plan.is_subscribed){
                current_sub=plan
            }}
    
            this.setState({
              subscriptions_plans: resp.data,
              monthly_sub_plans: monthly_sub_plans,
              yearly_sub_plans: yearly_sub_plans,
              current_subscription_plan:current_sub
            });
          })
        })
  
        .catch((err) => {
          notification.error({
            description: err.response.data.detail,
            placement: 'bottomRight'
        })
        });

    this.handleClosePlanSwitchModal();

    // }


    


  }

  handleClickSwitchPreview(plan_id){
    subscriptionPreview ({'plan_id':plan_id})
    .then((resp)=>{
      this.setState({
        preview_invoice:  resp.data
      })
      this.handleOpenPlanSwitchModal(plan_id)
    })

    .catch((err)=>{
    })


  }

  handleOpenPlanSwitchModal(plan_id) {
    this.setState({ show_modal: true, currunt_plan_id: plan_id });
  }

  handleClosePlanSwitchModal() {
    this.setState({ show_modal: false, currunt_plan_id: "" });
  }

  handleChnageSubscriptionToggle=(current_plan_id, is_recurring)=>{
    const  new_plan={
      "plan_id" : current_plan_id,
      "type"    : is_recurring ? "cancel" : "reactivate"
    }
    modifySubscription(new_plan)
    .then((resp)=>{
      notification.success({
        message: resp?.data?.detail,
        placement: "bottomRight"
    })
      listSubscriptonsPlans()
      .then((resp) => {
        let monthly_sub_plans = [];
        let yearly_sub_plans = [];
        let current_sub;
        for (let plan of resp?.data?.results) {
          // adding yearly plans in montly_sub_plans array
          if (plan.plan_type === "Monthly") {
            monthly_sub_plans.push(plan);
          }
          // adding yearly plans in yearly_sub_plans array
          else if (plan.plan_type === "Yearly") {
            yearly_sub_plans.push(plan);
          }
          if(plan.is_subscribed){
            current_sub=plan
        }}

        this.setState({
          subscriptions_plans: resp.data,
          monthly_sub_plans: monthly_sub_plans,
          yearly_sub_plans: yearly_sub_plans,
          current_subscription_plan:current_sub
        });
      })

      .catch((err) => {
        console.log("list subscription api failed", err.response);
      });

    })
    .catch((err)=>{
      console.log(err.response);
    })

    this.handleClosePlanSwitchModal();

  }

  render() {
    return (
      <>
        <section className="banner-section contact-section">
          <div className="banner-content">
            <div className="container">
              <div className="col-9 col-xl-12 col-md-6 col-sm-9 align center ">
                <h1 className="title-register">
                  {" "}
                  <span>Price</span> Subscription Plans{" "}
                </h1>
                <div className="col-12 col-xl-6 col-md-12 col-lg-12 p-0">
                  <div style={{ fontSize: "11pt" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Est, adipiscing cursus auctor eget sed phasellus senectus.{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <ul
                className="nav nav-pills mb-3 align-items-center justify-content-center mt-3 mb-5"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item me-5" role="presentation">
                  <button
                    className={`nav-link price_tab  ${
                      this.state.isMonthly && "active"
                    } `}
                    id="monthly-price-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#monthly-price-view"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                    onClick={this.handleClickMonthly}
                  >
                    Monthly
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link price_tab  ${
                      this.state.isYearly && "active"
                    } `}
                    id="yearly-price-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#yearly-price-view"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                    onClick={this.handleClickYearly}
                  >
                    Yearly
                  </button>
                </li>
              </ul>
            </div>
            {this.state.isMonthly && (
              <div className="container">
                <div className="row">
                  {this.state.monthly_sub_plans?.map((sub_plan) => {
                    return (
                      <div className="col-md-4" key={sub_plan.id}>
                        <Subscription_plan
                          plan_id={sub_plan.id}
                          plan_name={sub_plan?.name}
                          plan_type={sub_plan?.plan_type}
                          plan_price={sub_plan?.price}
                          plan_stripe_price_id={sub_plan?.stripe_price_id}
                          plan_feature={sub_plan?.feature}
                          current_subscription={
                            this.state?.current_subscription_plan
                          }
                          page="price"
                          plan_is_subscribed={sub_plan.is_subscribed}
                          plan_is_recurring={sub_plan.is_recurring}
                          handleChnageSubscriptionToggle={this.handleChnageSubscriptionToggle}
                        />
                        <div className="manage-subscription-fold-3">
                          <Subscription_switch_modal
                            key={sub_plan.id}
                            handleClickSwitch={this.handleClickSwitch}
                            plan_id={sub_plan?.id}
                            show_modal={this.state.show_modal}
                            handleClosePlanSwitchModal={
                              this.handleClosePlanSwitchModal
                            }
                            handleOpenPlanSwitchModal={
                              this.handleOpenPlanSwitchModal
                            }
                            currunt_plan_id={this.state?.currunt_plan_id}
                            current_subscription={
                              this.state?.current_subscription_plan
                            }
                            currunt_sub_id={this.state?.user_info?.subscription?.id}
                            handleClickSwitchPreview={this.handleClickSwitchPreview}
                            preview_invoice={this.state?.preview_invoice}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {this.state.isYearly && (
              <div className="container">
                <div className="row">
                  {this.state.yearly_sub_plans?.map((sub_plan) => {
                    return (
                      <div className="col-md-4" key={sub_plan.id}>
                        <Subscription_plan
                          plan_id={sub_plan.id}
                          plan_name={sub_plan?.name}
                          plan_type={sub_plan?.plan_type}
                          plan_price={sub_plan?.price}
                          plan_stripe_price_id={sub_plan?.stripe_price_id}
                          plan_feature={sub_plan?.feature}
                          current_subscription={
                            this.state?.user_info?.subscription
                          }
                          page="price"
                          plan_is_subscribed={sub_plan.is_subscribed}
                          plan_is_recurring={sub_plan.is_recurring}
                          handleChnageSubscriptionToggle={this.handleChnageSubscriptionToggle}
                        />
                        <div className="manage-subscription-fold-3">
                          <Subscription_switch_modal
                            handleClickSwitch={this.handleClickSwitch}
                            plan_id={sub_plan?.id}
                            show_modal={this.state.show_modal}
                            handleClosePlanSwitchModal={
                              this.handleClosePlanSwitchModal
                            }
                            handleOpenPlanSwitchModal={
                              this.handleOpenPlanSwitchModal
                            }
                            currunt_plan_id={this.state?.currunt_plan_id}
                            current_subscription={
                              this.state?.user_info?.subscription
                            }
                            currunt_sub_id={this.state?.user_info?.subscription?.id}
                            handleClickSwitchPreview={this.handleClickSwitchPreview}
                            preview_invoice={this.state?.preview_invoice}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
}

import React from 'react'

const BakeTime = props => {
    return (
        <>
            {props.bake_times?.map((bake_time => {
                return (
                    <button
                        key={bake_time?.id}
                        type="button"
                        className="cuisine-btn"
                        onClick={() => props.handleClickBakeTime(bake_time.id)}
                        style={bake_time.is_selected ? { background: '#85b492', color: '#fff' } : { background: '#FFF' }}

                    >
                        {bake_time?.name}
                    </button>

                )
            }))}
        </>
    )
}

export default BakeTime
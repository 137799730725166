import React from 'react'
import { Link } from 'react-router-dom'


const MyWorkspaceTags=(props)=> {
  return (
    <>
            <button
            type="button"
            className="cuisine-btn"
            style={props.is_selected ? { background: '#85b492', color: "#fff" } : { background: '#FFF' }}
            onClick={() => props.handleClickPopularTags(props?.index)}
        >
            {props.tag_name}
        </button>
</>
  )
}

export default MyWorkspaceTags
import React, { Component } from 'react'
import { Link } from 'react-router-dom';


export default class Tags extends Component {
    render() {
        return (
            <>
                {this.props?.tags?.map((tag, index) => {
                    return (

                        <Link to={`/blogs/${tag}`} className="btn" key={`${tag}-${index}`}  >
                            {tag}
                        </Link>
                    )
                })}

            </>
        )
    }
}

import React, { useEffect, useState } from "react";
import "../../assets/css/tcreate-recipe.css";
import { ReactSession } from "react-client-session";
import { Link } from "react-router-dom";
// importing the images
import img1 from "../../assets/images/Rectangle 589.png";
import img2 from "../../assets/images/Group-1.png";
import img3 from "../../assets/images/Group.png";
import { useSelector, useDispatch } from "react-redux";
import { createRecipeActions } from "../../store/createRecipe";
import Slider from "react-slick";
import { Fragment } from "react";
import EditIcon from "../components/EditIcon";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createRecipe } from "../../apis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { notification } from "antd";
import $ from 'jquery';

const image_slider_settings =  {
  dots: true,
  infinite: true,
  autoplaySpeed:2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  autoplay: true,
  arrows:false,
  pauseOnHover: true
};

const CreateRecipeStep5 = (props) => {
  
  const [is_create_recipe_loading, setIsCreateRecipeLoading]= useState(false)

  const dispatch = useDispatch();

  const create_recipe = useSelector(
    (state) => state.createrecipe.create_recipe
  );
  const recipe_data = useSelector((state) => state.recipedata);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickSubmit = (e) => {
    e.preventDefault();
    setIsCreateRecipeLoading(true)
    
    $('#create-recipe-submit').prop('disabled', true);
    const prep_time = create_recipe?.recipe_prep_time?.hours * 60 +
    create_recipe?.recipe_prep_time?.min 
    const bake_time = create_recipe?.recipe_bake_time?.hours * 60 +
    create_recipe?.recipe_bake_time?.min 
    const rest_time = create_recipe?.recipe_rest_time?.hours * 60 +
    create_recipe?.recipe_rest_time?.min 
    let ingredients = []
    for(let data of create_recipe?.recipe_ingredients){
      ingredients.push(data[0])
    }

    let directions =[]
    for(let data of create_recipe?.recipe_directions){
      directions.push(data.direction)
    }


    let cuisines= create_recipe?.recipe_selected_cuisines?.toString()
    cuisines = `{${cuisines}}`
    
    let bakes = create_recipe?.recipe_selected_bakes?.toString()
    bakes = `{${bakes}}`
    
    let tastes = create_recipe?.recipe_selected_tastes?.toString()
    tastes = `{${tastes}}`
   
    let occasions = create_recipe?.recipe_selected_occasions?.toString()
    occasions = `{${occasions}}`

    const no_of_images = create_recipe?.recipe_images?.length

    let create_recipe_data = new FormData();
    create_recipe_data.append("name_of_recipe", create_recipe.recipe_name);
    create_recipe_data.append("chefs_note", create_recipe.chefs_note);
    create_recipe_data.append("difficulty", create_recipe.recipe_difficulty.toLowerCase());
    create_recipe_data.append("servings", create_recipe.recipe_portion_type);
    create_recipe_data.append("prep_time", prep_time);
    create_recipe_data.append("baking_time", bake_time);
    create_recipe_data.append("resting_time", rest_time);
    create_recipe_data.append("ingredients", JSON.stringify(ingredients));
    create_recipe_data.append("direction", JSON.stringify(directions));
    create_recipe_data.append("recipe_type", bakes);
    create_recipe_data.append("taste", tastes);
    create_recipe_data.append("cuisine", cuisines);
    create_recipe_data.append("recipe_geography", occasions);
    if(no_of_images){
      if (no_of_images ===1) {
    create_recipe_data.append("image_1", create_recipe?.recipe_images[0]?.file,create_recipe?.recipe_images[0]?.file?.name);

      }
    else if (no_of_images ===2) {
    create_recipe_data.append("image_1", create_recipe?.recipe_images[0]?.file,create_recipe?.recipe_images[0]?.file?.name);
    create_recipe_data.append("image_2", create_recipe?.recipe_images[1]?.file,create_recipe?.recipe_images[0]?.file?.name);
      
    } else if (no_of_images ===3) {
      create_recipe_data.append("image_1", create_recipe?.recipe_images[0]?.file,create_recipe?.recipe_images[0]?.file?.name);
      create_recipe_data.append("image_2", create_recipe?.recipe_images[1]?.file,create_recipe?.recipe_images[0]?.file?.name);
      create_recipe_data.append("image_3", create_recipe?.recipe_images[2]?.file,create_recipe?.recipe_images[0]?.file?.name);
        
      }
      else if (no_of_images ===4) {
        create_recipe_data.append("image_1", create_recipe?.recipe_images[0]?.file,create_recipe?.recipe_images[0]?.file?.name);
        create_recipe_data.append("image_2", create_recipe?.recipe_images[1]?.file,create_recipe?.recipe_images[0]?.file?.name);
        create_recipe_data.append("image_3", create_recipe?.recipe_images[2]?.file,create_recipe?.recipe_images[0]?.file?.name);
        create_recipe_data.append("image_4", create_recipe?.recipe_images[3]?.file,create_recipe?.recipe_images[0]?.file?.name);
          
        }
        else if (no_of_images ===5) {
          create_recipe_data.append("image_1", create_recipe?.recipe_images[0]?.file,create_recipe?.recipe_images[0]?.file?.name);
          create_recipe_data.append("image_2", create_recipe?.recipe_images[1]?.file,create_recipe?.recipe_images[0]?.file?.name);
          create_recipe_data.append("image_3", create_recipe?.recipe_images[2]?.file,create_recipe?.recipe_images[0]?.file?.name);
          create_recipe_data.append("image_4", create_recipe?.recipe_images[3]?.file,create_recipe?.recipe_images[0]?.file?.name);
          create_recipe_data.append("image_5", create_recipe?.recipe_images[4]?.file,create_recipe?.recipe_images[0]?.file?.name);
          }
    }

    if(create_recipe?.recipe_video?.file){
      create_recipe_data.append("recipe_video", create_recipe?.recipe_video?.file,create_recipe?.recipe_video?.file?.name);
    }
    
    createRecipe(create_recipe_data)
    .then((resp)=>{
    notification.success({
      message: "Recipe is created Successfully",
      placement: "bottomRight"
  })
    })
    .catch((err)=>{
      notification.error({
        message: "Some Thing went worng please try again later",
        placement: "bottomRight"
    })
    })
    setIsCreateRecipeLoading(false)
    $('#create-recipe-submit').prop('disabled', false);

  };

  // getting the user info
  const user_info = ReactSession.get("user_data");


  let slidesToShow = create_recipe.recipe_images;
  const slider_settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: true,
    pauseOnHover: true,
    arrows: true,
    dots: true,
    infinite: true,
    focusOnSelect: true,
  };
  return (
    <>
      <section className="create-recipe-page-1">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-11 col-xl-12 col-md-12 col-lg-6 mt-3">
              <h5 className="create-recipe-title">
                {create_recipe?.recipe_name}
              </h5>
              <h1 className="create-recipe-heading">Create Recipe</h1>
              <p className="col-xl-6 col-11 p-0 create-recipe-paragraph-section-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est,
                adipiscing cursus auctor eget sed phasellus senectus.{" "}
              </p>
            </div>

            <div className="col-xl-12 col-md-12 col-11">
              <Slider {...image_slider_settings}>
                {create_recipe?.recipe_images?.map((image) => {
                  return (
                    <Fragment key={image.id}>
                      <img
                        src={image?.image}
                        alt=""
                        className="img-fluid responsive-img"
                      />
                    </Fragment>
                  );
                })}
              </Slider>
              <div className="img-text-top-right">
                Edit Image{" "}
                <Link to="/create-recipe-step-1">
                  <EditIcon />
                </Link>
              </div>
            </div>
            <div>
              <div className="img-text">
                {create_recipe.recipe_name}{" "}
                <Link to="/create-recipe-step-2">
                  <EditIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row d-flex justify-content-center mt-2">
          <div className="col-xl-6 col-md-12 col-11">
            { create_recipe?.recipe_video?.image &&
             <div>
              <span className="recipe-name play-video">Video</span>
              <Link to="/create-recipe-step-2">
                <EditIcon />
              </Link>
              <>
                <video
                  className="VideoInput_video video-play"
                  // style={{position:'relative'}}
                  width={400}
                  height={400}
                  controls
                  src={create_recipe.recipe_video.image}
                />
              </>
            </div>}
            <div className="create-recipe-step-5-box">
              <span className="recipe-name">
                Difficulty:{" "}
                <span style={{ color: "#85B492" }}>
                  {create_recipe.recipe_difficulty}
                </span>
                <Link to="/create-recipe-step-2">
                  <EditIcon />
                </Link>
              </span>
            </div>

            <div className="create-recipe-step-5-box">
              <span className="recipe-name">
                Portion:{" "}
                <span style={{ color: "#85B492" }}>
                  {create_recipe.recipe_portion_type}
                </span>
                <Link to="/create-recipe-step-2">
                  <EditIcon />
                </Link>
              </span>
            </div>

            <div className="create-recipe-step-5-box">
              <span className="recipe-name">
                chefs note:{" "}
                <span style={{ color: "#85B492" }}>
                  {create_recipe.chefs_note}
                </span>
                <Link to="/create-recipe-step-2">
                  <EditIcon />
                </Link>
              </span>
            </div>
            <div className="create-recipe-step-5-box">
              <div className="row d-flex">
                <span className="recipe-name">
                  Timings:{""}{" "}
                  <Link to="/create-recipe-step-2">
                    <EditIcon />
                  </Link>
                </span>
              </div>
              <div className="row d-flex mt-2">
                <div className="col-xl-4 col-md-4 d-flex flex-column align-items-center">
                  <button type="button" className="prep-btn">
                    {create_recipe?.recipe_prep_time?.hours * 60 +
                      create_recipe?.recipe_prep_time?.min}{" "}
                    Min
                  </button>
                  <span className="prep-text">cooking</span>
                </div>
                <div className="col-xl-4 col-md-4 d-flex flex-column align-items-center">
                  <button type="button" className="prep-btn">
                    {create_recipe?.recipe_bake_time?.hours * 60 +
                      create_recipe?.recipe_bake_time?.min}{" "}
                    Min
                  </button>
                  <span className="prep-text">Baking</span>
                </div>
                <div className="col-xl-4 col-md-4 d-flex flex-column align-items-center">
                  <button type="button" className="prep-btn">
                    {create_recipe?.recipe_rest_time?.hours * 60 +
                      create_recipe?.recipe_rest_time?.min}{" "}
                    Min
                  </button>
                  <span className="prep-text">Resting</span>
                </div>
              </div>
            </div>
            <div className="col-xl-12 create-recipe-step-5-box">
              {/* this is for the selected cuisins */}
              <span className="recipe-name">Occasion:</span>
              <Link to="/create-recipe-step-2">
                <EditIcon />
              </Link>
              <>
                <ol className="col-xl-8">
                  {recipe_data?.stored_occasion?.results?.map((occasion) => {
                    return (
                      <React.Fragment key={occasion.id}>
                        {occasion?.is_selected && (
                          <li data-list-index={`*`}>{occasion?.name}</li>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ol>
              </>
            </div>
            <div className="col-xl-12 create-recipe-step-5-box">
              {/* this is for the selected cuisins */}
              <span className="recipe-name">Cuisines:</span>
              <Link to="/create-recipe-step-2">
                <EditIcon />
              </Link>
              <>
                <ol className="col-xl-8">
                  {recipe_data?.stored_cuisine?.results?.map((cuisine) => {
                    return (
                      <React.Fragment key={cuisine.id}>
                        {cuisine?.is_selected && (
                          <li data-list-index={`*`}>{cuisine?.name}</li>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ol>
              </>
            </div>
            <div className="col-xl-12 create-recipe-step-5-box">
              {/* this is selected bake */}
              <span className="recipe-name">Bakes:</span>
              <Link to="/create-recipe-step-2">
                <EditIcon />
              </Link>
              <>
                <ol className="col-xl-8">
                  {recipe_data?.stored_bake?.results?.map((bake) => {
                    return (
                      <React.Fragment key={bake.id}>
                        {bake?.is_selected && (
                          <li data-list-index={`*`}>{bake?.name}</li>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ol>
              </>
            </div>
            <div className="col-xl-12 create-recipe-step-5-box">
              {/* this is for the selected test */}
              <span className="recipe-name">Tastes:</span>
              <Link to="/create-recipe-step-2">
                <EditIcon />
              </Link>
              <>
                <ol className="col-xl-8">
                  {recipe_data?.stored_taste?.results?.map((taste) => {
                    return (
                      <React.Fragment key={taste.id}>
                        {taste?.is_selected && (
                          <li data-list-index={`*`}>{taste?.name}</li>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ol>
              </>
            </div>
          </div>
          <div className="col-xl-6 col-md-12 col-11 mt-5 ">
            <div className="col-xl-10 mt-2 add-ingredient-box">
              <span className="recipe-name">Ingreadients:</span>
              <Link to="/create-recipe-step-2">
                <EditIcon />
              </Link>
              <>
                <ol>
                  {create_recipe?.recipe_ingredients?.map(
                    (ingredient) => {
                      return (
                        <React.Fragment key={ingredient[0].id}>
                          
                            <li>
                            <div style={{width:"100%"}} className="d-flex align-items-center justify-content-between">
                             <span style={{width: "35%"}} className="d-flex align-items-center"> {ingredient[0]?.name}</span> 
                             <div> {ingredient[0]?.quantity} {ingredient[0]?.unit}</div>
                             <div style={{float:'right'}} >{ingredient[0]?.image ?
                                       ( <img className='modal-ing-img' src={ingredient[0]?.image} alt="ingredients" />)
                                       :
                                       ( <>
                                        <FontAwesomeIcon
                                            icon={faCartPlus}
                                            size="2x"
                                            color="#85B492"
                                        />{ingredient?.is_selected}
                                    </>)}</div>
                                    </div>
                            </li>
                          
                        </React.Fragment>
                      );
                    }
                  )}
                </ol>
              </>
            </div>
            <div className="row">
              <div className="col-xl-12 form-group mt-2">
                <div className="input-effect-create-recips"></div>
              </div>
            </div>
            <div className="col-xl-10 mt-2 add-ingredient-box">
              <span className="recipe-name">Directions:</span>
              <Link to="/create-recipe-step-2">
                <EditIcon />
              </Link>

              <>
                <ol className="directon-preview">
                  {create_recipe?.recipe_directions?.map((direction, index) => {
                    return (
                      <React.Fragment key={direction.id}>
                        <li data-list-index={`${index + 1}.`}>
                          {direction?.direction}
                        </li>
                      </React.Fragment>
                    );
                  })}
                </ol>
              </>
            </div>
            <div className="row">
              <div className="col-xl-12 form-group mt-2">
                <div className="input-effect-create-recips"></div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mb-5 hr-color" />
        <div className="row  mb-5">
          <div className="col-xl-12 col-12 d-flex justify-content-between">
            <Link to="/create-recipe-step-4">
              <button
                type="button"
                className="btn btn-success btn-rounded previous-btn "
              >
                Previous
              </button>
            </Link>
            <button 
            type="button"
            className="btn" 
            onClick={(e)=>handleClickSubmit(e)}
            id="create-recipe-submit"
            >
            {is_create_recipe_loading ?
            (<>
            <div className="spinner-border text-info" role="status" id='spinner' onClick={(e)=>e.stopPropagation()}>
              <span className="visually-hidden"></span>
            </div>
            </>):
            ( <> Submit </>)}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateRecipeStep5;

import React from 'react'

const Occasion = props => {
    return (

        <>
            {props.occasion?.map((occasion => {
                return (
                    <button
                        key={occasion?.id}
                        type="button"
                        className="cuisine-btn"
                        style={occasion.is_selected ? { background: '#85b492', color: "#fff" } : { background: '#FFF' }}
                        onClick={() => props.handleClickOccasion(occasion?.id)}
                    >
                        {occasion?.name}
                    </button>

                )
            }))}
        </>
    )
}

export default Occasion
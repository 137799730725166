import React from 'react'
import Checkout from './checkout'
import {loadStripe} from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';




const stripePromise = loadStripe(process.env.React_App_STRIPE_PUBLISHABLE_KEY);

const CheckoutContainer=()=> {
  const params = useParams()
  return (
    <Elements stripe={stripePromise}>
    <Checkout
    plan_id={params.plan_id}
     />
  </Elements>

  )
}

export default CheckoutContainer;
import React from "react";
import Modal from "react-modal";

const IngredientQuantityModal = (props) => {
  const unit = props?.unit;
  return (
    <>
      <Modal
        isOpen={
          props?.show_ingredient_quantity_modal &&
          props?.id === props.clicked_quantity_id
        }
        onRequestClose={props.handleCloseIngredientQuantityModal}
        className="forgotPasswordModal ingredient-quantity-model ingredient-box-model"
        overlayClassName="forgotPasswordOverlay"
        closeTimeoutMS={500}
        ariaHideApp={false}
        id={`${props.id}`}
      >
        <>
          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-xl-4 mt-5">
              <input
                type="number"
                className="ingredient-quantity"
                onChange={(e) => props.handleChangeQuantity(e, props.id)}
                value={props?.quantity}
                placeholder="quantity"
              />
            </div>
            <div className="col-xl-1"></div>
            <div className="col-xl-6 mt-5">
              <select
                className="add-ingredient-dropdown"
                id="select-tags"
                onChange={(e) =>
                  props.handleChangeIngredientsUnit(e, props?.index)
                }
              >
                {/* {is_selected && ( */}
                <option className="dropdown-menu-ingredient" value="" disabled selected>
                  Select Unit
                </option>
                 {/* )}  */}
                {props.units?.map((unit, index) => {
                  return (
                    <React.Fragment key={`${unit.name}-${index}`}>
                      {unit.value !== props.unit && (
                        <option value={unit.value}>{unit.name}</option>
                      )}
                      {unit.value === props.unit && (
                        <option value={unit.value} selected>
                          {unit.name}
                        </option>
                      )}
                    </React.Fragment>
                  );
                })}
              </select>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default IngredientQuantityModal;

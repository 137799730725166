import React, { Component } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
export default class Subscription_switch_modal extends Component {
  render() {
    return (
      <>
        {!this.props?.current_subscription ? (
          <>
            <Link to={`/checkout/${this.props?.plan_id}`}>
              <button className="btn mr-auto ml-auto d-block">Buy Now </button>
            </Link>
          </>
        ) : (
          <>
            {this.props?.current_subscription?.id !== this.props?.plan_id && (
              <div className="col-12 col-xl-12 col-md-12 text-center">
                <a
                  className="btn my-account-apply"
                  onClick={() =>
                    this.props.handleClickSwitchPreview(this.props?.plan_id)
                  }
                  style={{ marginTop: "-90px" }}
                >
                  Switch
                </a>
              </div>
            )}
          </>
        )}
        <Modal
          isOpen={
            this.props?.plan_id === this.props?.currunt_plan_id &&
            this.props?.show_modal
          }
          onRequestClose={this.props.handleClosePlanSwitchModal}
          className={
            this.props?.preview_invoice?.type === "downgrade"
              ? "subscription-preview-modal-downgrade"
              : "subscription-preview-modal-upgrade"
          }
          overlayClassName="forgotPasswordOverlay"
          closeTimeoutMS={500}
          ariaHideApp={false}
          id={`${this.props?.plan_id}`}
        >
          <>
            {this.props?.preview_invoice?.type === "downgrade" ? (
              <>
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 container-border p-5">
                      <div className="row">
                        <div className="col-xl-12 d-flex justify-content-between">
                          <p className="mt-2 mb-0 plan-modal-paragraph">
                            Are you sure you want to downgrade this plan
                          </p>
                          <div className="d-flex align-items-center"></div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-xl-12 d-flex justify-content-between">
                          <p className="mt-2 mb-0 plan-modal-paragraph">
                            If you choose to downgrade the current subcription
                            the new subscription will start when the current
                            subscription ends
                          </p>
                          <div className="d-flex align-items-center"></div>
                        </div>
                      </div>
                      {/* <hr/> */}
                      {/* <div className="row mt-2">
                    <div className="col-xl-12 d-flex align-items-center payable-container">
                        <div className="col-xl-7 p-0">
                            <h4 className="heading-bottom">payable from</h4>
                            <span>Undefined</span>
                        </div>
                        <div className="col-xl-5 d-flex justify-content-end">
                            <h5>$ undefined USD</h5>
                        </div>
                    </div>
                </div> */}
                      <div className="row">
                        <div className="col-xl-12">
                          <hr />
                          <button
                            className="btn mr-auto ml-auto d-block"
                            onClick={() =>
                              this.props.handleClickSwitch(this?.props?.plan_id)
                            }
                          >
                            Switch{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </>
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-xl-12 container-border p-5">
                    <div className="row">
                      <div className="col-xl-12 d-flex justify-content-between">
                        <p className="mt-2 mb-0 plan-modal-paragraph">
                          Thank you for choosing to upgrade to ultimate yearly
                          plan
                        </p>
                        <div className="d-flex align-items-center"></div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-xl-5 switch-plan-modal-box">
                        <span className="decoration">
                          {this.props?.preview_invoice?.existing_plan?.name}
                        </span>
                        <span>
                          {
                            this.props?.preview_invoice?.existing_plan
                              ?.plan_type
                          }
                        </span>
                        <span>
                          $ {this.props?.preview_invoice?.existing_plan?.price}{" "}
                          USD
                        </span>
                      </div>
                      <div className="col-xl-2 d-flex justify-content-center align-items-center right-arrow">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          size="sm"
                          transform="shrink-6"
                          color="#85B492"
                        />
                      </div>
                      <div className="col-xl-5 switch-plan-modal-box">
                        <span className="decoration">
                          {this.props?.preview_invoice?.new_plan?.name}
                        </span>
                        <span>
                          {this.props?.preview_invoice?.new_plan?.plan_type}
                        </span>
                        <span>
                          $ {this.props?.preview_invoice?.new_plan?.price} USD
                        </span>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-xl-12 d-flex align-items-center payable-container">
                        <div className="col-xl-7 p-0">
                          <h4 className="heading-bottom">payable today</h4>
                          <p>
                            your current plan remaining days credit will be
                            discounted against the plan value{" "}
                          </p>
                        </div>
                        <div className="col-xl-5 d-flex justify-content-end">
                          <h5>$ undefined USD</h5>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row mt-2">
                      <div className="col-xl-12 d-flex align-items-center payable-container">
                        <div className="col-xl-7 p-0">
                          <h4 className="heading-bottom">payable from</h4>
                          <span>Undefined</span>
                        </div>
                        <div className="col-xl-5 d-flex justify-content-end">
                          <h5>$ undefined USD</h5>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <hr />
                        <button
                          className="btn mr-auto ml-auto d-block"
                          onClick={() =>
                            this.props.handleClickSwitch(this?.props?.plan_id)
                          }
                        >
                          Switch{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            )}
          </>
        </Modal>
      </>
    );
  }
}

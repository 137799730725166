import React from 'react';

class termsandconditions extends React.Component {
        componentDidMount() {
                window.scrollTo(0, 0)
        }
        render() {
                return (
                        <div className="banner-content contact-section">
                                <div className="container">
                                        <h2 className="c13" id="h.h248cbehp9hs"><span className="c14">Flavorable</span><span className="c14 c15">&nbsp;Terms &amp;
                                                Conditions of Use</span></h2>
                                        <h2 className="c9" id="h.x79f6s8y3n1r"><span className="c0">Thank you for visiting this website. <br /><br />By accessing and
                                                using this website (the &ldquo;Site&rdquo;) of Flavorable, you agree to abide by the terms and conditions of
                                                use contained herein (these &ldquo;Terms of Use&rdquo;). This Site contains general information about
                                                Flavorable, our business, services, history, and portfolio, and is directed at our customers and potential
                                                customers. These Terms of Use are a binding agreement between you and Flavorable, and govern your access and
                                                use of the Site, including, without limitation, any information, text, software, photos, video, graphics,
                                                audio, data, tools, products, services, documents, links, advertisements, policies, functions, materials,
                                                and other content (collectively, the &ldquo;Content&rdquo;) available on the Site.<br /><br />PLEASE READ THESE
                                                TERMS OF USE CAREFULLY BEFORE ENTERING THE SITE. EACH TIME YOU ENTER THE SITE, YOU ARE AGREEING TO FULLY
                                                ACCEPT AND AGREE TO ABIDE BY THESE TERMS OF USE. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, EXIT THE SITE
                                                AND DO NOT ACCESS, REQUEST, OR DOWNLOAD ANY PORTION OF THE CONTENT.<br /><br />YOU ACKNOWLEDGE AND AGREE THAT
                                                Flavorable MAY, IN ITS SOLE DISCRETION AND WITHOUT ADVANCE NOTICE, UPDATE OR OTHERWISE MODIFY THE SITE AND
                                                THE CONTENT, AND MAY MODIFY THESE TERMS OF USE AT ANY TIME. MODIFICATIONS WILL BE EFFECTIVE IMMEDIATELY UPON
                                                POSTING UNLESS WE INDICATE OTHERWISE. YOU AGREE TO PERIODICALLY REVIEW THESE TERMS AND USE. EACH TIME YOU
                                                ENTER THE SITE, YOU AGREE TO AND FULLY ACCEPT THESE TERMS OF USE IN THEIR CURRENT FORM. YOUR CONTINUED USE
                                                OF THE SITE WILL INDICATE ACCEPTANCE BY YOU OF SUCH RULES, CHANGES, OR MODIFICATIONS.</span></h2>
                                        <p className="c7"><span className="c3">AMENDMENT OF TERMS</span></p>
                                        <p className="c7"><span className="c14">We reserve the right to change, modify, add or remove portions of these terms at any
                                                time. Please check these terms regularly prior to using our website to ensure you are aware of any changes.
                                                We will endeavour to highlight any significant or substantive changes to you where possible. If you choose
                                                to use our platform then we will regard that use as conclusive evidence of your agreement and acceptance
                                                that these terms govern your and Flavorable&rsquo;s rights and obligations to each other.</span></p>
                                        <p className="c7 c8"><span className="c6"></span></p>
                                        <h3 className="c2" id="h.xiak2t282yjt"><span className="c3">INTELLECTUAL PROPERTY RIGHTS AND OWNERSHIP</span></h3>
                                        <p className="c4"><span className="c0">The Site and all of the Content, code, software, data, and other materials thereon,
                                                the look and feel of the Site, and the design and organization of the Site, and the Flavorable name and
                                                logo, and all related product and service names, design marks and slogans are the service marks, trademarks
                                                or registered service marks or trademarks (the &ldquo;Marks&rdquo;) are owned by or licensed to Flavorable,
                                                subject to copyright and other intellectual property rights under Australian laws and international
                                                conventions. The Site is provided by Flavorable for informational purposes, as a service to
                                                Flavorable&rsquo;s customers and potential customers.</span></p>
                                        <p className="c4"><span className="c0">You may not use any Flavorable trademarks or logos without the prior written consent
                                                of Flavorable or the applicable trademark owner. Nothing contained on the site should be construed as
                                                granting, by implication, estoppel or otherwise, any license or right to use any Content without the written
                                                permission of Flavorable or such other party that may own any Content, the Marks, or other
                                                trademarks.</span></p>
                                        <p className="c4"><span className="c0">You shall not upload, post, or otherwise make available on the Site any information
                                                and material protected by copyright, trademark, or other proprietary right without the express written
                                                permission of the owner of such right(s). You shall be solely liable for any damages resulting from any
                                                infringement of copyright, proprietary rights, or any other harm resulting from such a submission.</span>
                                        </p>
                                        <p className="c4"><span className="c0">The Site contains content that is derived in whole or in part from information and
                                                materials supplied by Flavorable and other sources. You are prohibited from removing any copyright,
                                                trademark, or other proprietary notice or legend contained on (or printed from) the Site or on any printed
                                                pages. Use of the Site is not a transfer of title in the Content and by your use of the Site, you
                                                acknowledge that you do not acquire any ownership or other rights to the Content, except that you are hereby
                                                granted a non-exclusive license to use the Content, but only while accessing this Site.</span></p>
                                        <h3 className="c2" id="h.ccbzz4h180so"><span className="c3">RESTRICTIONS ON USE OF THE SITE</span></h3>
                                        <p className="c4"><span className="c0">You agree to not engage in the use, copying, or distribution of any of the Content,
                                                including any use, copying, or distribution of third parties&rsquo; materials obtained through the Site,
                                                provided you are granted a limited license to print copies of any Content, but only for personal,
                                                non-commercial use. At any time and for any reason, Flavorable may revoke your right to use all or any
                                                portion of the Site.</span></p>
                                        <h3 className="c2" id="h.gdzyfh8kg8w6"><span className="c3">SITE SECURITY</span></h3>
                                        <p className="c4"><span className="c0">While accessing or using any portions of the Site, you agree that neither you nor
                                                your agents shall do any of the following, including, without limitation, violating or attempting to violate
                                                the security of the Site:</span></p>
                                        <ul className="c15 lst-kix_1lqgnsx8u0ee-0 start">
                                                <li className="c1 li-bullet-0"><span className="c0">Tamper with any portion of the Site;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Impersonate any person or entity or misrepresent your affiliation
                                                        with any other person or entity;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Conduct fraudulent activities on the Site;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Obtain or attempt to gain unauthorized access to other computer
                                                        systems, materials, information, or any services available on or through the Site through any means,
                                                        including through the use of meta tags or other hidden text or by other means not intentionally made
                                                        publicly available or provided for through the Site;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Engage in &ldquo;spidering,&rdquo; &ldquo;screen scraping,&rdquo;
                                                        &ldquo;database scraping,&rdquo; mining or harvesting of e-mail addresses or other personal information,
                                                        or any other automatic or unauthorized means of accessing, logging-in or registering on any of the Site,
                                                        or obtaining lists of users or other information from or through the Site, including, without
                                                        limitation, any information residing on any server or database connected to the Site;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Interrupt, damage, disable, overburden or impair the Site or
                                                        interfere with any other party&rsquo;s use and enjoyment of the Site, such as sending mass unsolicited
                                                        messages or &ldquo;flooding&rdquo; servers with requests;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Circumvent, reverse engineer, decrypt, or otherwise alter or
                                                        interfere with (or attempt, encourage or support anyone else&rsquo;s attempt to do any of the foregoing)
                                                        the Site or its services or any software on the Site;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Attempt to probe, scan, or test the vulnerability of the Site or to
                                                        breach the security or authentication measures without proper authorization;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Violate, plagiarize or infringe the rights of third parties,
                                                        including without limitation, copyright, trademark, trade secret, confidentiality, contract, patent,
                                                        rights of privacy or rights of publicity or any other proprietary or legal right;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Remove any notices, warnings, labels, annotations, or instructions
                                                        from any portion of the Site, including, without limitation, any patent, trademark, copyright, or other
                                                        proprietary notices or license provisions; or</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Submit any information to the Site, including obscene content that
                                                        is in violation of any applicable law, rule, or regulation.</span></li>
                                        </ul>
                                        <p className="c4"><span className="c0">Any violations of these Terms of Use, the Site, system, or network security,
                                                including attempts to intentionally access a computer without authorization or exceed your authorized access
                                                level, may result in civil and criminal charges. Flavorable may investigate occurrences that might involve
                                                such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who
                                                are involved in such violations. Flavorable may, without prior notice or warning of any kind, restrict or
                                                terminate the access of any and all users at any time and for any reason, to all or any portion of the Site
                                                if Flavorable concludes in its sole discretion that such restriction or termination is necessary to prevent,
                                                or prevent the further spread of, a virus, security breach, or system malfunction.</span></p>
                                        <h3 className="c2" id="h.fw971v4myj5u"><span className="c3">DISCLAIMERS</span></h3>
                                        <p className="c4"><span className="c0">When reviewing the Content on the Site, there are various risks you assume. You agree
                                                that Flavorable is not liable for any action you take or decision you make in reliance on any of the Content
                                                on the Site.</span></p>
                                        <h3 className="c2" id="h.o69jms55qi70"><span className="c3">FORWARD-LOOKING STATEMENTS:</span></h3>
                                        <p className="c4"><span className="c0">The Content provided on the Site is for informational purposes only. Any estimates,
                                                projections, recommendations or predictions on the Site are intended to be forward-looking statements.
                                                Although Flavorable believes that the expectations in such forward-looking statements are reasonable, it can
                                                give no assurance that any forward-looking statements will prove to be correct. Such reccomendations are
                                                subject to actual known and unknown risks, uncertainties, and other factors that could cause actual results
                                                to differ materially from those projected. Flavorable expressly disclaims any obligation or undertaking to
                                                update or revise any forward-looking statement contained herein to reflect any change in its expectations or
                                                any change in circumstances upon which such statement is based. No statements contained on the Site should
                                                be construed as a guarantee or assurance of future performance or future results.</span></p>
                                        <p className="c4"><span className="c3">&nbsp;CONTENT FOR INFORMATIONAL PURPOSES ONLY</span></p>
                                        <p className="c4"><span className="c14">The contents of Flavorable app or website, such as text, videos, graphics, images,
                                                and other material contained on this site (&ldquo;Content&rdquo;) are for informational purposes only. The
                                                Content is not intended to be a substitute for professional medical advice on health benefits, diagnosis, or
                                                treatment. Always seek the advice of your doctor with any questions you may have regarding your medical
                                                condition. Never disregard professional advice or delay in seeking it because of something you have read on
                                                this website!</span></p>
                                        <h3 className="c2" id="h.ar5us5wx423m"><span className="c3">CONTENT DISCLAIMERS:</span></h3>
                                        <p className="c4"><span className="c0">We make reasonable efforts to ensure accuracy with respect to the Content, but at
                                                times we may not promptly update or correct the Site even if we are aware that it is inaccurate, outdated,
                                                or otherwise inappropriate. Dated information speaks only as of the date indicated. Flavorable does not
                                                warrant or guaranty the accuracy or completeness of the information made available on the Site. In addition,
                                                we do not endorse the opinions of, or warrant the accuracy of facts or other content contributed by, any
                                                third party.</span></p>
                                        <h3 className="c2" id="h.kkcsq5k4uok9"><span className="c3">DISCLAIMER OF WARRANTY:</span></h3>
                                        <p className="c4"><span className="c0">THE SITE AND THE CONTENT IS PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS
                                                AVAILABLE,&rdquo; FOR YOUR INFORMATION AND PERSONAL NON-COMMERCIAL USE ONLY AND MAY NOT BE USED, COPIED,
                                                REPRODUCED, DISTRIBUTED, TRANSMITTED, BROADCAST, DISPLAYED, SOLD, LICENSED, OR OTHERWISE EXPLOITED FOR ANY
                                                OTHER PURPOSES WHATSOEVER WITHOUT THE PRIOR WRITTEN CONSENT OF Flavorable. ALL USE OF THE SITE IS AT YOUR
                                                OWN RISK. Flavorable DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND WITH
                                                RESPECT TO THE SITE INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                                                NON-INFRINGEMENT OF INTELLECTUAL PROPERTY AND PROPRIETARY RIGHTS. THIS DISCLAIMER WILL APPLY TO THE FULLEST
                                                EXTENT PERMITTED BY APPLICABLE LAW.</span></p>
                                        <p className="c4"><span className="c0">WITHOUT IN ANY WAY LIMITING ANY OTHER PROVISION HEREIN, WE DO NOT WARRANT THE
                                                AVAILABILITY, ACCURACY, COMPLETENESS, TIMELINESS, FUNCTIONALITY, RELIABILITY, SEQUENCING, OR SPEED OF
                                                DELIVERY OF THE SITE OR ANY PART OF THE CONTENT CONTAINED WITHIN OR PRESENTED BY THE SITE.</span></p>
                                        <p className="c4"><span className="c0">Flavorable ASSUMES NO RESPONSIBILITY, AND SHALL NOT BE LIABLE FOR ANY DAMAGES RELATED
                                                TO VIRUSES, WORMS, OR OTHER MALWARE THAT MAY INFECT YOUR COMPUTER EQUIPMENT OR OTHER PROPERTY ON ACCOUNT OF
                                                YOUR ACCESS TO, USE OF, OR BROWSING IN THE SITE (INCLUDING ANY BLOGS TO THE EXTENT THEY EXIST) OR YOUR
                                                DOWNLOADING OF ANY MATERIALS OR THE CONTENT FROM THE SITE.</span></p>
                                        <p className="c4"><span className="c0">Flavorable&rsquo;S LIABILITY WITH RESPECT TO THE SITE IS LIMITED TO THE MAXIMUM
                                                EXTENT PERMITTED BY LAW.</span></p>
                                        <h3 className="c2" id="h.pkj7c4bujiac"><span className="c3">THIRD PARTY LINKS</span></h3>
                                        <p className="c4"><span className="c0">While Flavorable tries to link only to sites that share its high standards and
                                                respect for privacy, Flavorable is not responsible for information on any third party website that may be
                                                referenced in, or accessible or connected by hyperlink to, the Site. If you access any third party website
                                                through the Site or otherwise, you do so at your own risk. Flavorable makes no warranty or representation
                                                regarding, and does not sponsor or endorse, any linked websites or the information or materials appearing
                                                thereon or any of products and services described thereon. Furthermore, links do not imply that Flavorable
                                                is affiliated or associated with, or that any linked site is authorized to use any service mark, trademark,
                                                trade name, logo, or copyright of Flavorable.</span></p>
                                        <h3 className="c2" id="h.qo45tgv3l84b"><span className="c3">LIMITATION OF LIABILITY</span></h3>
                                        <p className="c4"><span className="c0">If you are dissatisfied with any of the Content contained in the Site, or with any of
                                                these Terms of Use, your sole and exclusive remedy is to discontinue accessing and using the Site.</span>
                                        </p>
                                        <p className="c4"><span className="c0">UNDER NO CIRCUMSTANCES SHALL Flavorable OR ANY OF ITS RESPECTIVE OFFICERS, DIRECTORS,
                                                AFFILIATES, PARTNERS, MEMBERS, PRINCIPALS, AGENTS, INVESTORS OR EMPLOYEES BE LIABLE FOR ANY CLAIMS,
                                                LIABILITIES, LOSSES, COSTS OR DAMAGES, INCLUDING DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR
                                                CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING WITHOUT LIMITATION, COSTS AND EXPENSES OF ANY TYPE INCURRED,
                                                LOST PROFITS, LOST DATA OR PROGRAMS, AND BUSINESS INTERRUPTION), ARISING OUT OF OR IN ANY WAY CONNECTED WITH
                                                THE USE, INABILITY TO USE OR THE RESULTS OF USE OF THE SITE, ANY WEBSITES LINKED TO THE SITE, OR ANY
                                                MATERIALS CONTAINED AT ANY OR ALL SUCH WEBSITES (INCLUDING BUT NOT LIMITED TO THOSE CAUSED BY OR RESULTING
                                                FROM A FAILURE OF PERFORMANCE; ERROR; OMISSION; LINKING TO OTHER WEBSITES; INTERRUPTION; DELETION; DEFECT;
                                                DELAY IN OPERATION OR TRANSMISSION; COMPUTER VIRUS; COMMUNICATION LINE FAILURE; OR DESTRUCTION, UNAUTHORIZED
                                                ACCESS TO, ALTERATION OF, OR USE OF ANY COMPUTER OR SYSTEM), ANY DELAY OR TECHNICAL PROBLEMS IN USING THE
                                                SITE, OR ANY INFORMATION AND THE CONTENT OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF
                                                THE SITE; IN ANY CASE WHETHER BASED ON THEORIES ARISING IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE.
                                                SUCH LIMITATIONS APPLY EVEN IF Flavorable OR ANY OF ITS RESPECTIVE OFFICERS, DIRECTORS, AFFILIATES,
                                                PARTNERS, MEMBERS, PRINCIPALS, AGENTS, INVESTORS OR EMPLOYEES HAVE BEEN ADVISED OF THE POSSIBILITY OF
                                                DAMAGES.</span></p>
                                        <h3 className="c2" id="h.dy11r1fyrgex"><span className="c3">INDEMNIFICATION</span></h3>
                                        <p className="c4"><span className="c0">As a condition to your use of the Site, you agree to indemnify, defend and hold
                                                harmless Flavorable Capital and its respective, officers, directors, affiliates, partners, members,
                                                principals, agents, investors, employees, and third party sources from and against any and all suits,
                                                losses, claims, demands, liabilities, damages, costs and expenses (including reasonable attorneys&rsquo;
                                                fees) that arise from or relate to: (i) your use of the Site; (ii) your breach of these Terms of Use or any
                                                representation, warranty or covenant made by you in these Terms of Use; (iii) your violation of any
                                                applicable law, statute, ordinance, regulation or of any third party&rsquo;s rights; or (iv) claims asserted
                                                by third parties which, if proven, would place you in breach of representations, warranties, covenants or
                                                other provisions contained in these Terms of Use.</span></p>
                                        <h3 className="c2" id="h.3eukudqicsf"><span className="c3">COMPLETE AGREEMENT</span></h3>
                                        <p className="c4"><span className="c0">These Terms of Use constitute the entire agreement between you and Flavorable Capital
                                                relating to your use of the Site and the Content, and supersede any prior agreements or understandings not
                                                incorporated herein. Certain restricted areas of the Site may require you to agree to supplemental terms and
                                                conditions. These Terms of Use are not intended to modify or amend other agreements you may have with
                                                Flavorable regarding other matters.</span></p>
                                        <h3 className="c2" id="h.s7zmk13uvuzi"><span className="c3">SEVERABILITY AND WAIVER</span></h3>
                                        <p className="c4"><span className="c0">If any provision of these Terms of Use is found to be invalid, void, or for any
                                                reason unenforceable in any jurisdiction, such provision shall be deemed modified to the minimum extent
                                                necessary so that such provision shall no longer be held to be invalid or unenforceable, and these Terms of
                                                Use shall be interpreted so as to achieve the intent expressed herein to the greatest extent possible in the
                                                jurisdiction in question. Any such modification, invalidity or unenforceability shall be strictly limited
                                                both to such provision. If it is not possible to construe the provision in question in such a manner that
                                                would make the provision valid and enforceable, then only the term or portion of the provision that renders
                                                the provision unenforceable will be stricken without affecting the enforceability of the remaining
                                                provisions of these Terms of Use. Any failure of Flavorable Capital to act on or enforce any provision of
                                                these Terms of Use shall not be construed as a waiver of that provision or any other provision of these
                                                Terms of Use.</span></p>
                                        <h3 className="c2" id="h.c78an2wbx4sp"><span className="c3">GOVERNING LAW AND VENUE</span></h3>
                                        <p className="c4"><span className="c0">These Terms of Use shall be governed by and construed under the internal laws of the
                                                State of Illinois, without regard to its choice of law rules. Any legal claim or action brought hereunder
                                                shall be brought exclusively in state or federal courts located in Chicago, Illinois, and you further agree
                                                and submit to the exercise of personal jurisdiction of such courts for the purpose of litigating any such
                                                claim or action.</span></p>
                                        <h3 className="c2" id="h.ypi7mlflf5n3"><span className="c3">CONTACT</span></h3>
                                        <p className="c4"><span className="c0">You may contact Flavorable, Attention: General Counsel with questions about these
                                                Terms of Use by sending an email to support@neevista.com.</span></p>
                                        <p className="c8 c13"><span className="c5"></span></p>
                                </div>
                        </div>
                )
        }
}


export default termsandconditions;
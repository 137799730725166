import React, { Component } from 'react'

import default_person from "../../assets/images/default_person.png";
import $ from 'jquery';
import Moment from 'react-moment';


export class LoggedInComment extends Component {
    componentDidMount() {
        //Input label effects JS
        $("input").val("");

        $(".input-effect input").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });
        $(".input-effect textarea").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });
        $(".input-effect select").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });
    }

    render() {

        return (
            <>
                {this.props?.comments?.map((comment) => (
                    <React.Fragment key={comment?.id}>
                        <div className="author-box" >
                            <div className="profile-box">
                                <span className="author-img">
                                    {comment?.user?.profile_img ? (<img src={comment?.user?.profile_img} alt="" className="img-fluid" />) : (<img src={default_person} alt="" className="img-fluid" />)}
                                </span>
                                <div className="author-content">
                                    <h4 className='p-0'  >
                                        <strong>{comment?.user?.username}</strong>
                                        <span className="date">
                                            {<Moment format='MMM D, YYYY'>{comment?.time_stamp}</Moment>} at {<Moment format='h:mm a'>{comment?.time_stamp}</Moment>}
                                        </span>
                                        <a onClick={() => this.props.handleDisplayReply(comment?.id)}>Reply</a>
                                    </h4>
                                </div>
                            </div>
                            <p className=" p-comments">{comment?.comment} </p>
                            {comment.displayReply &&
                                (<div className="row">
                                    <div className="col-12 col-xl-12 col-md-12">
                                        <div className="input-effect">
                                            <textarea className="effect" id="message" type="text" name="reply" value={this?.props?.reply} onChange={this?.props?.handleChangeReply} autoComplete="off"></textarea>
                                            <label>Your reply</label>
                                            <span className='errorValidation'>{this?.props?.errorReply}</span>
                                        </div>
                                        <button id="btn1" onClick={() => this.props?.handleClickPostReply(comment.id)} className="btn1">Post
                                            {this.props.isLoading &&
                                                <div className="spinner-border text-info" role="status">
                                                    <span className="visually-hidden"></span>
                                                </div>}
                                            &nbsp; Reply
                                        </button>
                                    </div>
                                </div>)}
                        </div>
                        {comment?.replies?.length > 0 && comment?.replies?.map((reply, index) => (
                            <div className="sub-comment" key={`${reply?.time_stamp}-${index}`}>
                                <div className="author-box">
                                    <div className="profile-box">
                                        <span className="author-img">
                                            {reply.user?.profile_img ? (<img src={reply?.user?.profile_img} alt="" className="img-fluid" />) : (<img src={default_person} alt="" className="img-fluid" />)}
                                        </span>
                                        <div className="author-content">
                                            <h4 className='p-0' >
                                                <strong>{reply?.user?.username}</strong>
                                                <span className="date">
                                                {<Moment format='MMM D, YYYY'>{reply?.time_stamp}</Moment>} at {<Moment format='h:mm a'>{reply?.time_stamp}</Moment>}
                                                </span>
                                            </h4>
                                        </div>
                                    </div>
                                    <p className=" p-comments">{reply?.reply}</p>
                                </div>
                            </div>
                        )
                        )}
                    </React.Fragment>
                )

                )}
            </>
        )
    }
}

export default LoggedInComment;
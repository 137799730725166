import React from 'react';

import { Link } from 'react-router-dom';

import blog_banner from "../../assets/images/blog-banner.png";
import blog_banner_mob from "../../assets/images/blog-banner-mob.png";


import { blogApiContent, listBlogsByPage, popularTagsForRecipe, searchBlogs, searchBlogsByPage, tagBlogs, tagsBlogsByPage } from '../../apis'

import $ from 'jquery';

import LazyLoad from 'react-lazyload';


import Loading from '../components/Loading';
import Article from "../components/Article";

import InfiniteScroll from "react-infinite-scroll-component";

import { useParams } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { connect } from 'react-redux';


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Blogs extends React.Component {
    constructor(props) {
        super(props);
        this.feedback_type = React.createRef();
        this.state = {
            loading: true,
            data: {},
            fields: { search: "" },
            isTag: false,
            is_Loged_In: false,
            isBlogs: true,
            isSearch: false,
            tag: "",
            tagg: "",
            search: "",
            isData: false,
            noData: false,
            tagged: "",
            popular_tags:null
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleDropdownChangeTags = this.handleDropdownChangeTags.bind(this);
        this.handleClickSearch = this.handleClickSearch.bind(this);
    }

    enableSelect() {
        var state = this.state.tag;
        var customSelect = $('select.custom-select');
        var _ = this;
        // FIRST, create the custom select menus from the existing select
        customSelect.each(function () {
            var that = $(this);
            var listID = that.attr('id'),
                groups = that.children('optgroup'),
                theOptions = "",
                startingOption = "",
                customSelect = "";
            //check if there are option groups 
            if (groups.length) {
                groups.each(function () {
                    var curGroup = $(this);
                    var curName = curGroup.attr('label');
                    //Open the option group
                    theOptions += '<li class="optgroup">' + curName + '</li>';
                    //get the options
                    curGroup.children('option').each(function () {
                        var curOpt = $(this);
                        var curVal = curOpt.attr('value'),
                            curHtml = curOpt.html(),
                            isSelected = curOpt.attr('defaultValue');
                        if (isSelected === 'selected') {
                            startingOption = curHtml;
                            theOptions += '<li class="selected" data-value="' + curVal + '">' + curHtml + '</li>';
                        } else {
                            theOptions += '<li data-value="' + curVal + '">' + curHtml + '</li>';
                        }
                    });
                    //Close the option group
                    //theOptions += '<li class="optgroup-close"></li>';
                });
                //add options not in a group to the top of the list
                that.children('option').each(function () {
                    var curOpt = $(this);
                    var curVal = curOpt.attr('value'),
                        curHtml = curOpt.html(),
                        isSelected = curOpt.attr('selected');
                    if (isSelected === 'selected') {
                        startingOption = curHtml;
                        theOptions = '<li class="selected" data-value="' + curVal + '">' + curHtml + '</li>' + theOptions;
                    } else {
                        theOptions = '<li data-value="' + curVal + '">' + curHtml + '</li>' + theOptions;
                    }
                });
            } else {
                that.children('option').each(function () {
                    var curOpt = $(this);
                    var curVal = curOpt.attr('value'),
                        curHtml = curOpt.html(),
                        isSelected = curOpt.attr('defaultValue');
                    if (curVal !== 'tags') {
                        theOptions += '<li data-value="' + curVal + '">' + curHtml + '</li>';
                    }
                });
            }
            //build the custom select
            customSelect = `<div class="dropdown-container"><div class="dropdown-select entypo-down-open-big"><span> ${state ? state.toUpperCase() : "Tags"} </span></div><ul class="dropdown-select-ul" data-role="${listID}"> ${theOptions} </ul></div> <!-- .custom-select-wrapper -->`;
            //append it after the actual select
            $(customSelect).insertAfter(that);
        });

        var selectdd = $('.dropdown-select'),
            selectul = $('.dropdown-select-ul'),
            selectli = $('.dropdown-select-ul li');
        //THEN make them work
        selectdd.on('click', function () {
            $(this).parent('.dropdown-container').toggleClass('active');
        });
        //Hide it on mouseleave
        selectul.on('mouseleave', function () {
            $(this).parent('.dropdown-container').removeClass('active');
        });
        //select the option
        selectli.on('click', function (event) {
            var that = $(this);
            if (!that.hasClass('optgroup')) {
                var parentUl = that.parent('ul'),
                    thisdd = parentUl.siblings('.dropdown-select'),
                    lihtml = that.html(),
                    livalue = that.attr('data-value'),
                    originalSelect = '#' + parentUl.attr('data-role');

                //close the dropdown
                parentUl.parent('.dropdown-container').toggleClass('active');
                //remove selected class from all list items
                that.siblings('li').removeClass('selected');
                //add .selected to clicked li
                that.addClass('selected');
                //set the value of the hidden input
                $(originalSelect).val(livalue);
                //change the dropdown text
                thisdd.children('span').html(lihtml);

                _.handleDropdownChangeTags(lihtml)
            }

        });

    }

    componentDidMount() {

        window.scrollTo(0, 0)

        let tag = this.props?.params?.tag;
        let tagg = "{" + tag + "}";

        if (tagg === "{undefined}") {

            blogApiContent()
                .then(resp => {
                    this.setState({
                        data: resp.data,
                        loading: false
                    })
                    this.enableSelect();
                })
                .catch(err => {

                })

                popularTagsForRecipe()
                .then((resp)=>{

                 this.setState({
                    popular_tags:resp.data?.popular_tags[0]?.article?.slice(0, 10)
                 })
                })
                .catch((err)=>{

                })

        }

        else {

            tagBlogs(tagg)
                .then(resp => {
                    this.setState({
                        data: resp.data,
                        loading: false,
                        isBlogs: false,
                        isTag: true,
                        tag: tag,
                        tagg: tagg

                    })
                    this.enableSelect();
                })

                .catch(err => {

                })
        }
        const token = ReactSession?.get('authtoken');

        this.setState({
            is_Loged_In: token ? true : false,
        })

    }

    componentDidUpdate(pP, pS, cC) {

        if (this.props.refreshblogs !== pP.refreshblogs) {

            this.setState({
                loading: true
            })

            blogApiContent()
                .then(resp => {
                    let fields = this.state.fields
                    fields.search = ""
                    this.setState({
                        data: resp.data,
                        isTag: false,
                        isSearch: false,
                        isBlogs: true,
                        noData: resp.data.count === 0 ? true : false,
                        fields: fields,
                        tag: "",
                        loading: false
                    })
                    this.enableSelect();
                })

                .catch(err => {

                })

            const token = ReactSession?.get('authtoken');

            this.setState({
                is_Loged_In: token ? true : false,
            })

        }
    }


    handleChange(e) {
        let fields = this.state.fields;
        fields[e?.target?.name] = e?.target?.value;
        this.setState({
            fields
        });
    }

    handleDropdownChangeTags(tag) {
        this.setState({
            loading: true
        })
        let tagg = tag?.toLowerCase();
        tagg = "{" + tagg + "}";
        tagBlogs(tagg)
            .then(resp => {

                this.setState({
                    data: {},
                })
                let fields = this.state.fields
                fields.search = ""
                this.setState({
                    data: resp.data,
                    loading: false,
                    isBlogs: false,
                    isTag: true,
                    isSearch: false,
                    tag: tag,
                    tagg: tagg,
                    fields: fields,
                    noData: resp.data.count === 0 ? true : false,

                })
                this.enableSelect()
            })

            .catch(err => {

            })
    }

    handleClickSearch(e) {
        e?.preventDefault();
        this.setState({
            loading: true
        })
        let search = this.state.fields['search'].trim();
        if (search) {
            if (search) {
                searchBlogs(search)
                    .then(resp => {
                        this.setState(
                            {
                                data: {},
                            })

                        this.setState(
                            {
                                data: resp.data,
                                loading: false,
                                isBlogs: false,
                                isTag: false,
                                isSearch: true,
                                search: search,
                                noData: resp.data.count === 0 ? true : false,
                                tag: "",

                            })
                        this.enableSelect();
                    })

                    .catch(err => {

                    })
            }
        }
        else {
            this.setState({
                loading: true
            })
            let page_no = 1;
            listBlogsByPage(page_no)
                .then(resp => {
                    this.setState({
                        data: {},
                        loading: true
                    })
                    this.setState({
                        data: resp.data,
                        loading: false,
                        isBlogs: true,
                        isTag: false,
                        tag: "",
                        noData: resp.data.count === 0 ? true : false,
                    });
                    this.enableSelect();
                })


                .catch(err => {

                });
        }
    }

    fetchMoreBlogs = () => {

        if (this.state.isBlogs) {
            let page = this.state.data?.next;
            let page_no = page.split('=').pop();
            listBlogsByPage(page_no)
                .then(resp => {
                    let new_result = this.state.data.results.concat(resp.data.results);
                    let new_data =
                    {
                        count: resp?.data?.count,
                        next: resp?.data?.next,
                        previous: resp?.data?.previous,
                        results: new_result
                    }

                    this.setState({
                        data: new_data
                    })
                })

                .catch(err => {
                })
        }
        else if (this.state.isSearch) {
            // reading page no
            let page = this.state.data?.next;
            page = page.split('page=');
            let page_no = page[1].charAt(0)
            // let page_no = page.charAt(49);   should i read page no by above aproch or the one i commented on the left 
            searchBlogsByPage(this.state.search, page_no)
                .then(resp => {
                    let new_result = this.state.data.results.concat(resp.data.results);
                    let new_data =
                    {
                        count: resp?.data?.count,
                        next: resp?.data?.next,
                        previous: resp?.data?.previous,
                        results: new_result
                    }

                    this.setState(
                        {
                            data: new_data
                        })
                })

                .catch(err => {

                })
        }
        else if (this.state.isTag) {
            let page = this.state.data?.next;
            page = page.split('page=');
            let page_no = page[1].charAt(0)
            tagsBlogsByPage(this.state.tagg, page_no)
                .then(resp => {
                    let new_result = this.state.data.results.concat(resp.data.results);
                    let new_data =
                    {
                        count: resp?.data?.count,
                        next: resp?.data?.next,
                        previous: resp?.data?.previous,
                        results: new_result
                    }


                    this.setState({
                        data: new_data
                    })
                })

                .catch(err => {

                })

        }


    };


    render() {
        return (
            <div>
                {this.state?.loading || !this.state?.data ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        {/* // <!--Banner container start--> */}
                        {this.state.is_Loged_In &&
                            <Link to="/createblog">
                                <div className="creatblog"> + </div>
                            </Link>}
                        <section className="inner-page-banner ">
                            <div className="banner-content">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-10 col-xl-7 col-md-7 col-lg-7">
                                            <h1 className="title-register "> <span>Blog</span> Delicious, Healthy Meals Without Compromise</h1>
                                            <div className="col-12 col-xl-9 col-md-10 col-lg-9 p-0">
                                                <p className="contact-p">Browse through our library of recipes to find the perfect meal for your family. With categories tailored to your lifestyle, you're sure to find something that meets your needs. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="inner-img-box">
                                    <LazyLoad> <img src={blog_banner} className="img-fluid desktop-display" alt="" /> </LazyLoad>
                                    <img src={blog_banner_mob} className="device-display" alt="" />
                                </div>
                            </div>
                        </section>
                        {/* // <!--Banner container end-->  */}

                        {/* // <!--Banner section end--> 
                         // <!--Mid Section start--> */}

                        <section className="mid-section">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-12 col-lg-4" >
                                        <div className="input-effect">
                                            <select defaultValue="selected tags" className="custom-select effect" id="select-tags" ref={this.feedback_type}   >
                                                <option value="tags" defaultValue >Tags</option>
                                                {/* <option value="VEGETABLES">VEGETABLES</option>
                                                <option value="HEALTHY_FAT">HEALTHY_FAT</option>
                                                <option value="TEA">TEA</option>
                                                <option value="COFFEE">COFFEE</option> */}
                                                {this?.state?.popular_tags?.map((tag,index)=>{
                  return( <option value={tag} key={`${index}-${tag}`}>{tag?.toUpperCase()}</option>)})}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-4">
                                        <div className="input-group search-box">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search drinks, recipe, blogs..."
                                                aria-label="Search drinks, recipe, blogs..."
                                                aria-describedby="basic-addon2" name="search"
                                                value={this.state.fields.search}
                                                onChange={this.handleChange}
                                                onKeyPress={event => {
                                                    if (event.key === 'Enter') {
                                                        this.handleClickSearch()
                                                    }
                                                }} autoComplete="off" />
                                            <div className="input-group-append">
                                                <button className="btn" type="button" onClick={this.handleClickSearch}><i className="fas fa-search" ></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* start of blogs */}

                                <div className="blog-area">
                                    <InfiniteScroll
                                        dataLength={this.state?.data?.count}
                                        next={this.fetchMoreBlogs}
                                        hasMore={this.state?.data?.next}
                                        loader={
                                            <>
                                                <Loading />
                                            </>
                                        }
                                    >
                                        <div className="container">
                                            {this.state.isTag &&
                                                <div >
                                                    <h1 style={{ textAlign: "center" }}>Articles for the "{this.state.tag?.toUpperCase()}" tag</h1>
                                                </div>
                                            }
                                            {!this.state.noData && this.state.isSearch &&
                                                <div>
                                                    <h1 style={{ textAlign: "center" }}>Articles for the "{this.state.fields.search?.toUpperCase()}" search</h1>
                                                </div>
                                            }
                                            <div className="row">
                                                {this.state.data.results?.map((blog) => {
                                                    return (
                                                        < div className="col-md-6 col-lg-4" key={blog.id}>

                                                            <Article
                                                                title={blog?.title}
                                                                abstract={blog?.abstract}
                                                                images={blog?.images}
                                                                tags={blog?.tags}
                                                                date={blog?.created_at}
                                                                id={blog?.id}
                                                            />
                                                        </div>)
                                                })
                                                }
                                                {this.state.noData &&
                                                    <>
                                                        <div className="col-xl-12 col-11 col-md-12 col-lg-12 col-sm-12">
                                                            <h1 style={{ margin: "0 0 5px 0 " }}>
                                                                No blogs available for given search result!
                                                            </h1>
                                                            <span>Make sure all words are spelled correctly.</span>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </section>
                    </>
                )}
                {/* // <!--Mid Section end-->  */}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        refreshblogs: state.refreshblog.refreshblogs
    };
}

export default connect(mapStateToProps)(withParams(Blogs));
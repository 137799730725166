import React, { Component } from 'react'
import LazyLoad from 'react-lazyload';
import TagBlogs from './TagBlogs';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';



export class RelatedPost extends Component {
    render() {
        let title = this.props?.title?.replaceAll(" ", "-");
        return (
            <>

                <LazyLoad>
                    <Link to={`/blog/${this.props?.id}/${title}`} className="blog-box">
                        <span className="blog-thumbnail">
                            {this.props?.images?.filter(tru => tru?.is_hero === true)?.map((img, index) => (
                                < img src={img?.image} alt="" key={`${img?.image}-${index}`} />
                            ))}
                        </span>
                        <h2 className="title">
                            <span>{<Moment format='MMM D, YYYY'>{this.props?.date}</Moment>}</span>
                            {this.props?.title}
                        </h2>
                        <p>{this.props?.abstract}</p>
                    </Link>
                    <div className="discover">
                        {this.props?.tags && <TagBlogs tags={this.props?.tags} />}
                    </div>
                </LazyLoad>

            </>
        )
    }
}

export default RelatedPost;
import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth.js';
import refreshBlogsReducer from './refreshblogs';
import createRecipeReducer from './createRecipe';
import recipeDataReducer from './recipeData';



const store = configureStore({
    reducer:
    {
        auth: authReducer,
        refreshblog: refreshBlogsReducer,
        createrecipe: createRecipeReducer,
        recipedata: recipeDataReducer

    },
});

export default store;

import React, { useState, useRef } from "react";

export default function VideoInput(props) {
  const { width, height, img } = props;

  const inputRef = useRef();

  const handleVideoChoose = (event) => {
    inputRef.current.click();
  };

  return (
    <div className="VideoInput">
      {!props.video?.image && (
        <>
          <label
            onClick={handleVideoChoose}
            htmlFor="video_uploader"
            className="upload_label"
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative"
            }}
          >
            <img
              src={img}
              alt=""
              className="img-fluid"
              id="video_uploader"
              style={{
                width: "fit-content",
                cursor: "pointer",
              }}
            />
          </label>
          <input
            ref={inputRef}
            className="VideoInput_input"
            type="file"
            onChange={props.handleChangeVideo}
            style={{
              display: "none",
            }}
            accept=".mov,.mp4"
          />
        </>
      )}
      {props.video?.image && (
        <>
          <div style={{ position: "relative" }} className="video-cross-container">
            <video
              className="VideoInput_video"

              width={width}
              height={height}
              controls
              src={props.video.image}
            />

            <div
              className="image-delete image-delete-video"
              onClick={props.handleDeleteVideo}
            >
              x
            </div>
          </div>
        </>
      )}
    </div>
  );
}

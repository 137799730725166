import React from 'react';

import '../assets/css/privacy.css';

class Privacy extends React.Component {
        componentDidMount() {
                window.scrollTo(0, 0)
        }
        render() {
                return (
                        // <div>
                        <div className="banner-content contact-section">
                                <div className="container">
                                        <h2 className="c13" id="h.h248cbehp9hs"><span className="c14 c14-01">Flavorable</span><span className="c14 c15">&nbsp;Privacy Policy</span></h2>
                                        <p className="c3"><span className="c2 c2-01">Flavorable</span><span className="c0">&nbsp;is committed to respecting and protecting
                                                your privacy. This Privacy Policy governs the manner in which we collect, use, maintain, and disclose
                                                Personal Information collected when you visit this website at: <a href="https://www.Flavorable.io/">https://www.Flavorable.io/</a>  &nbsp;(or any
                                                successor website), and any desktop, subpages, mobile versions, and Flavorable mobile apps (Android, iOS)
                                                &nbsp;including all content, materials, features, applications, functionality, products, and services, made
                                                available on this website or other related platforms (&ldquo;the Site&rdquo;).</span></p>
                                        <p className="c3"><span className="c0">This Privacy Policy also applies when you interact with us offline. For example, this
                                                Privacy Policy applies if you provide your contact information to us and our ongoing dealings with you as a
                                                client, investor, borrower, vendor, lender, or sponsor.</span></p>
                                        <p className="c3"><span className="c0 c0-1">PERSONAL INFORMATION</span></p>
                                        <p className="c3"><span className="c0">&ldquo;Personal Information&rdquo; &ndash; or &ldquo;personal data&rdquo; in other
                                                jurisdictions like the European Union (&ldquo;EU&rdquo;) &ndash; means information that identifies, relates
                                                to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or
                                                indirectly, with an individual or household. We do not consider Personal Information to include information
                                                that has been aggregated or de-identified so that it does not allow a third party to reasonably identify a
                                                specific individual.</span></p>
                                        <p className="c3"><span className="c0 c0-1">ACCEPTANCE</span></p>
                                        <p className="c3"><span className="c0">Please read this Privacy Policy carefully so that you understand our privacy
                                                practices. By providing Personal Information to us or by using the Site, you acknowledge that you agree to
                                                be bound and abide by Privacy Policy and the Terms of Service, and you are consenting to the collection,
                                                use, and disclosure of that information in accordance with this Privacy Policy.</span></p>
                                        <p className="c3"><span className="c0 c0-1">PART OF THE TERMS OF SERVICE</span></p>
                                        <p className="c3">
                                                <span className="c0">This Privacy Policy forms part of our Terms of Service at:
                                                        &nbsp;<a href='https://www.Flavorable.io/terms-conditions/'>https://www.Flavorable.io/terms-conditions/</a><br></br>Any capitalized, but undefined terms in this
                                                        Privacy Policy have the meaning given to them in the Terms of Service.
                                                </span>
                                        </p>
                                        <p className="c3"><span className="c0 c0-1">CHANGES IN OUR PRIVACY POLICY</span></p>
                                        <p className="c3"><span className="c0">We may change this Privacy Policy from time to time, and we may provide you with
                                                additional notice through one or more of our channels. We encourage you to review the Privacy Policy
                                                whenever you interact with us to stay informed about our information practices.&#8239; Any changes will
                                                become effective when the revised Privacy Policy is posted on the Site. Your use of the Site following these
                                                changes means that you accept the revised Privacy Policy.</span></p>
                                        <h3 className="c4" id="h.5q00o2kvsm7o"><span className="c5">1. &nbsp;PERSONAL INFORMATION WE COLLECT</span></h3>
                                        <p className="c3"><span className="c0">The information we collect falls into two categories: (1) information you voluntarily
                                                provide us when you visit our Site, or otherwise interact with us both online and offline, and submit your
                                                information, including via name, email, telephone, dietary preferences, health conditions, food recipes,
                                                using a Site contact form, as part of the transaction, and through other documents and correspondence; and
                                                (2) tracking information gathered as you visit our Site.</span></p>
                                        <p className="c3"><span className="c0 c0-personal-info">PERSONAL INFORMATION YOU PROVIDE</span></p>
                                        <p className="c3"><span className="c0">The types of Personal Data we collect and share depend on the product or service you
                                                have with us and the nature of our relationship with you. We may collect your Personal Information in
                                                various ways including by telephone, the Site, from documents or correspondence, and by email. Wherever
                                                practicable, we will collect information about you from you personally.</span></p>
                                        <p className="c3"><span className="c0">In order to provide our products and services, including establishing and
                                                administering any food reccomendations, providing general dietary advice and providing information about our
                                                products and services, and to comply with relevant legislation, in the past twelve months we may have
                                                collected Personal Information concerning you in the categories below we may have collected the following
                                                information:</span></p>
                                        <ol className="c8 lst-kix_rqcqa5j9gmj3-0 start" start="1">
                                                <li className="c1 li-bullet-0"><span className="c0">Identifiers (e.g., real name, alias, email address, signature,
                                                        telephone number, spouse&rsquo;s name, financial information including account/ credit-card details, and
                                                        other contact information);</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Sensitive/protected characteristic information (e.g., age/date of
                                                        birth, country of residence, gender, race, and other information used to comply with laws and
                                                        regulations);</span></li>
                                        </ol>
                                        <p className="c10"><span className="c0"></span></p>
                                        <ol className="c8 lst-kix_rqcqa5j9gmj3-0" start="3">
                                                <li className="c1 li-bullet-0"><span className="c0">Personal and food preferences (e.g.,dietary preferences, liked/
                                                        disliked ingredients, allergies, cooking skills);</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Health related information such as weight, height, diabetes status,
                                                        and information about other health conditions.</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Exercise related information gathered from external apps such as
                                                        Fitbit and possibly other similar apps in the future.</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Inferences drawn from other Personal Information (e.g., profiles
                                                        reflecting preferences, characteristics, predispositions, behavior, aptitude, and trends, based on
                                                        personal, health, and exercise information transaction history).</span></li>
                                        </ol>
                                        <p className="c3"><span className="c0">It may be necessary at times to collect some of the Personal Information listed above
                                                about you from other external sources, such as:</span></p>
                                        <ol className="c8 lst-kix_e4djldmek08t-0 start" start="1">
                                                <li className="c1 li-bullet-0"><span className="c0">Third-party apps like Fitbit;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Authentication providers such as Google, Facebook, Apple;</span>
                                                </li>
                                                <li className="c1 li-bullet-0"><span className="c0">Authorized representatives, such as executors or
                                                        administrators;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Credit check providers;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Publicly available registries; and</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Identification verification service providers</span></li>
                                        </ol>
                                        <p className="c3"><span className="c0">We may combine Personal Information that you provide to us with Personal Information
                                                that we collect from, or about you from other sources, in some circumstances to provide better experience on
                                                our Site. This will include Personal Information collected in an online or offline context.</span></p>
                                        <p className="c3"><span className="c0">You may use the Site as a guest and are not required to register or create an account
                                                in order to use it to explore our content. &nbsp;However, not all functionality may be available to you as a
                                                user or account holder on the Site. It is essential that you create an account for the mobile
                                                application.</span></p>
                                        <p className="c3"><span className="c0 c0-personal-info">PASSIVE INFORMATION COLLECTION</span></p>
                                        <p className="c3"><span className="c0">We may use analytics tools, including third-party services such as Google Analytics,
                                                to help us measure traffic, usage, and sales trends by your use of our Site. These tools use various
                                                technologies, including cookies or similar technologies, to collect certain information sent by your devices
                                                to the Site, including:</span></p>
                                        <ol className="c8 lst-kix_zb050jb2dqhv-0 start" start="1">
                                                <li className="c1 li-bullet-0"><span className="c0">Your browser name,</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">The pages you visit on the Site,</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Whether you have previously accessed the Site,</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">The IP (Internet Protocol) address for your computer</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">The type of computer, mobile device, and technical information about
                                                        your means of connection to our Site, such as the operating system and the Internet service providers
                                                        utilized, and</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Navigational Data, also known as log files, server logs or
                                                        clickstream data.</span></li>
                                        </ol>
                                        <p className="c3"><span className="c0">We conduct the passive collection of the above information in a variety of ways,
                                                including:</span></p>
                                        <ol className="c8 lst-kix_hot193uo2wm5-0 start" start="1">
                                                <li className="c1 li-bullet-0"><span className="c0">Through your browser or device.&#8239;Certain information is
                                                        collected by most browsers or automatically through your devices, such as your Media Access Control
                                                        (MAC) address, computer type (Windows or Macintosh), screen resolution, operating system name and
                                                        version, device manufacturer, and model, language, and Internet browser type and version. We use this
                                                        information to ensure that the Site and its services function properly.</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Through your use of the Site.&#8239;When you visit the Site, we may
                                                        track and collect the usage data, including but not limited to the date and time your device accessed
                                                        the Site.</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Using cookies.&#8239;Cookies are pieces of information stored
                                                        directly on the computer that you are using. Cookies allow us to collect information such as browser
                                                        type, time spent on the Site, pages visited, language preferences, and other traffic data of the Site.
                                                        We use the information for security purposes, to facilitate navigation and display information more
                                                        effectively. We can recognize your computer to assist your use of the Site.</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">If you do not want information collected through the use of cookies,
                                                        you may choose to set your web browser to refuse cookies or to alert you when cookies are being sent.
                                                        &nbsp;However, if you do not accept these cookies, you may experience some inconveniences in your use of
                                                        the Site and some parts of the Site may not function properly.</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Using tracking technologies.&#8239;We may use tracking technologies
                                                        to track you across third-party websites. We do not currently respond to &ldquo;do not track&rdquo;
                                                        signals or other mechanisms that might enable users to opt-out of tracking on the Site.</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Using pixel tags and other similar technologies.&#8239;Pixel tags
                                                        (also known as web beacons and clear GIFs) may be used in connection with the Site to, among other
                                                        things, track the actions of users of the Site to improve the Site and its associated services.</span>
                                                </li>
                                                <li className="c1 li-bullet-0"><span className="c0">Using your IP Address.&#8239;Your &ldquo;IP Address&rdquo; is a
                                                        number that is automatically assigned to the computer or mobile device that you are using. An IP Address
                                                        may be identified and logged automatically in our server log files whenever you access the Site, along
                                                        with the time of the visit and the page(s) that were visited. Collecting IP Addresses is standard
                                                        practice and is done automatically by many websites, applications and other services. We may use IP
                                                        Addresses for purposes such as calculating usage levels of the Site, helping diagnose server problems,
                                                        and administering the Site and its associated services. We may block certain IP addresses if they
                                                        attempt to access the administrative portions of the site without authorization.</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">If you connect to us from a social network, such as Google, Apple,
                                                        LinkedIn or Facebook, we will collect personal data from the social network in accordance with your
                                                        privacy settings on that social network. The connected social network may provide us with information
                                                        such as your name, profile picture, network, gender, username, user ID, age or age range, language,
                                                        country, friends list, follower list, job title, and any other information you have agreed it can share
                                                        or that the social network provides to us.</span></li>
                                        </ol>
                                        <h3 className="c4" id="h.ns2ppdxa9yhq"><span className="c5">2. &nbsp;HOW WE USE INFORMATION</span></h3>
                                        <p className="c3"><span className="c0">We use information about you for various purposes, including:</span></p>
                                        <ol className="c8 lst-kix_1527032byqph-0 start" start="1">
                                                <li className="c1 li-bullet-0"><span className="c0">To provide the services you request, including provding food
                                                        recommendation based on dietary preferences, health conditions and exercise data, reccomending profiles
                                                        of other users who may share similar food/ health related interests, conducting transactions, and
                                                        sending you related information, including any confirmations and receipts;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">To manage and administer customer services, including but not
                                                        limited to the administration of your customer account.</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">To communicate with you about Flavorable, including our events,
                                                        news, and information we think will be of interest to you.</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">To protect the security of and manage access to our premises, IT and
                                                        communication systems, online platforms, websites, and other systems, preventing and detecting security
                                                        threats, fraud, or other criminal or malicious activities</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">For insurance purposes, as necessary</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">For our recruitment, when you apply for a position with us</span>
                                                </li>
                                                <li className="c1 li-bullet-0"><span className="c0">To comply with our legal and regulatory obligations such as to
                                                        enforce our Terms of Use, for financial and credit checks, audit requirements, fraud, and crime
                                                        prevention and detection, and record keeping. This may include automated checks of Personal Information
                                                        you or your organization provide about your identity against relevant databases, and contacting you to
                                                        confirm your identity, or making records of our communications with you for compliance purposes</span>
                                                </li>
                                                <li className="c1 li-bullet-0"><span className="c0">Fulfill any other purposes related and/or ancillary to any of the
                                                        above, or any other purposes for which your Personal Information was provided to us, consistent with the
                                                        commitments made in this Privacy Policy</span></li>
                                        </ol>
                                        <p className="c3"><span className="c0">From time to time, we may desire to use Personal Information for uses not previously
                                                disclosed in our Privacy Policy. If our practices change regarding previously collected Personal Information
                                                in a way that would be materially less restrictive than stated in the version of this Privacy Policy in
                                                effect at the time we collected the information, we will make reasonable efforts to provide notice and
                                                obtain consent to any such uses as may be required by law.</span></p>
                                        <h3 className="c4" id="h.a5jqltng142i"><span className="c5">3. &nbsp;HOW WE SHARE INFORMATION</span></h3>
                                        <p className="c3"><span className="c0">We do not sell, trade, or rent your Personal Information to others.</span></p>
                                        <p className="c3"><span className="c0">We may share the categories of Personal Information described above with service
                                                providers and third parties that we partner with to assist us in various functions, including providing
                                                technical support and performing security and anti-fraud services, providing you with our services and
                                                products, and providing you with communications and marketing information on our behalf. These third parties
                                                have access to your Personal Information only to perform specific tasks on our behalf and are obligated not
                                                to disclose or use your Personal Information for any other purpose.</span></p>
                                        <p className="c3"><span className="c0">These service providers and third parties can be classified in the following
                                                categories:</span></p>
                                        <ol className="c8 lst-kix_rm2geegoz7xb-0 start" start="1">
                                                <li className="c1 li-bullet-0"><span className="c0">Marketing service providers, which we use to send you promotional
                                                        communications, for instance;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Tax authorities, in order to comply with applicable laws and
                                                        regulations and where required or as requested by tax authorities in the territory in which you are
                                                        located;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Event planners, which we use to organize and manage events and
                                                        event-related services, including registering your attendance and the processing of your credit card
                                                        data with respect to event-related expenses.</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Financing counterparties, in order to assess such counterparties
                                                        with regulatory checks and sourcing credit for fund-related entities in the course of our transactions
                                                        and fund life cycles;</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">IT service providers, such as our data hosting provider and
                                                        companies we employ to deliver and facilitate the services needed to support our business relationship
                                                        with you, manage our Site, to provide Site-related services and/or to assist us in analyzing how our
                                                        Site is used; or</span></li>
                                                <li className="c1 li-bullet-0"><span className="c0">Other service providers that we may engage to provide services to us
                                                        or on our behalf.</span></li>
                                                <li className="c11 li-bullet-0"><span className="c0">We may disclose your personal information to any of our employees,
                                                        officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably
                                                        necessary for the purposes set out in this Policy. Personal information is only supplied to a third
                                                        party when it is required for the delivery of our services.</span></li>
                                                <li className="c11 li-bullet-0"><span className="c0">We may from time to time need to disclose personal information to
                                                        comply with a legal requirement, such as a law, regulation, court order, subpoena, warrant, in the
                                                        course of a legal proceeding or in response to a law enforcement agency request.</span></li>
                                                <li className="c11 li-bullet-0"><span className="c0">We may also use your personal information to protect the copyright,
                                                        trademarks, legal rights, property or safety of Neevista Pty Ltd, its application, website and customers
                                                        or third parties.</span></li>
                                                <li className="c11 li-bullet-0"><span className="c2">Information that we collect may from time to time be stored,
                                                        processed in or transferred between parties located in countries outside of Australia.</span></li>
                                        </ol>
                                        <p className="c6"><span className="c9"></span></p>
                                        <p className="c3"><span className="c0">Your Personal Information may be disclosed to a third party if we are required to do
                                                so because of applicable law, court order, or governmental regulation, or if such disclosure is otherwise
                                                necessary in support of any criminal or other legal investigation or proceeding. Your Personal Information
                                                may be disclosed to a third party if the disclosure is reasonably related to the potential or actual sale or
                                                other disposition of all or part of our business or assets.</span></p>
                                        <p className="c3"><span className="c0">We may share aggregated or de-identified information with third-party partners,
                                                affiliates, or service providers for the purposes outlined above.</span></p>
                                        <p className="c3"><span className="c0">We may use third-party services such as Google Analytics to collect, monitor, and
                                                analyse the information as described above. These third-party service providers have their own privacy
                                                policies addressing how they use such information. To opt-out of Google Analytics on the Site go to
                                                https://tools.google.com/dlpage/gaoptout.</span></p>
                                        <h3 className="c4" id="h.je1kbop75rzp"><span className="c5">4. &nbsp;INFORMATION RETENTION</span></h3>
                                        <p className="c3"><span className="c0">Your Personal Information will be retained in accordance with our data retention
                                                policy, which identifies the types of records held by Flavorable, and specifies the appropriate retention
                                                period for each record type. Those periods are determined by the purpose for which the information is
                                                collected and used, taking into account applicable data protection laws, legal, and regulatory requirements
                                                to retain the information for a minimum period, limitation periods for taking legal action, and
                                                Flavorable&rsquo; business requirements.</span></p>
                                        <h3 className="c4" id="h.osf8ph7420tq"><span className="c5">5. &nbsp;HOW WE PROTECT YOUR INFORMATION</span></h3>
                                        <p className="c3"><span className="c0">We take the protection of our users&rsquo; Personal Information very seriously We
                                                seek to use commercially reasonable data collection, storage, and processing practices and security measures
                                                to protect against unauthorized access, alteration, disclosure, or destruction of your Personal Information
                                                under our control, in accordance with this Privacy Policy. However, the Internet is not 100% secure or
                                                error-free. We cannot guarantee that your use of our Site will be completely safe, and we cannot ensure or
                                                warrant the security of any information you provide to us. We do not accept liability for unintentional
                                                disclosure. &nbsp;We encourage you to use caution when using the Internet.</span></p>
                                        <p className="c3"><span className="c0">By using the Site or providing Personal Information to us, you agree that we may
                                                communicate with you electronically regarding security, privacy, and administrative issues relating to your
                                                use of the Site. If we learn of a security system&rsquo;s breach, we may attempt to notify you
                                                electronically by posting a notice on the Site or sending an email to you. You may have a legal right to
                                                receive this notice in writing. If you have any questions or concerns, please notify us.</span></p>
                                        <p className="c3"><span className="c0">The safety and security of your information also depend on you. Where you have chosen
                                                an account password for access to certain features of the Site, you are responsible for keeping this
                                                password confidential. We ask that you not share your password with anyone and encourage you not to use the
                                                same password on the Site as you use on other websites.</span></p>
                                        <h3 className="c4" id="h.t33k093c2cf7"><span className="c5">6. &nbsp;CHILDREN AND PRIVACY</span></h3>
                                        <p className="c3"><span className="c0">We do not knowingly permit children under age 13 (in the United States) without adult
                                                supervision as described in our Terms of Service or age 16 (in the European Economic Area (EEA) to register
                                                and/or to create an account for any content, product, or service. &nbsp;We will not knowingly collect, use,
                                                or disclose personally identifiable information about people under age 13 (in the United States) or age 16
                                                (in the EEA), except as permitted by law. If we learn that we have collected any Personal Information from
                                                anyone under age 13 (in the United States) or age 16 (in the EEA), we will promptly take steps to delete
                                                such information.</span></p>
                                        <p className="c3"><span className="c0">If you believe that we might have any information collected from an individual under
                                                age 13 (in the United States) or age 16 (in the EEA), please contact us.</span></p>
                                        <h3 className="c4" id="h.mpcbx56ofoli"><span className="c5">7. &nbsp;CONTACT US</span></h3>
                                        <p className="c3"><span className="c2">If you have questions about this policy, you can email us at </span><span
                                                className="c2 c2-1"><a href='support@neevista.com'>support@neevista.com</a></span><span className="c2">. </span></p>
                                </div>
                        </div>
                        // </div>
                )
        }
}


export default Privacy;
import React from "react";
// const stripe = require('stripe')('sk_test_51IIpzfG0USkrwpj2AU1sP01YRnc5bAyGXNMUrLv9nIxpXmeCo9jdoNBbES9nxwNrj1zirs4S8gmYcxWRvE3kJ96i001670XQZR');

import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { createSubscription } from "../apis";
import { notification } from "antd";




const Stripe_publishable_key=process.env.React_App_STRIPE_PUBLISHABLE_KEY
const Checkout = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleClickSubscribe= (e)=>{
    e.preventDefault()
    const subscription_plan = {'plan_id': props.plan_id }
    createSubscription(subscription_plan)
    .then(async(resp)=>{

      const card_element = elements.getElement(CardElement)

      const payment_method_request = await stripe.createPaymentMethod({
        type : 'card',
        card : card_element,
      })


      const confirm_card_payment = await stripe.confirmCardPayment(resp.data.data.clientSecret,{
        payment_method:payment_method_request.paymentMethod.id
      })
      notification.success({
        message: 'Subscription succesful.',
        placement: 'bottomRight'

    })
    })
    .catch((err)=>{
      notification.error({
        message: 'Subscription failed.',
        placement: 'bottomRight'

    })
    })
  }
  
  return (
    <>
      <section className="banner-section contact-section">
        <div className="banner-content">
          <div className="container">
            <div className="row d-flex  justify-content-center">
              <div className="col-10 col-xl-12 col-md-6 col-sm-9 align center ">
                <h1 className="title-register">
                  {" "}
                  <span>Get started</span> Checkout
                </h1>
                <div className="col-11 col-xl-6 col-md-12 col-lg-12 p-0">
                  <div style={{ fontSize: "11pt" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Est, adipiscing cursus auctor eget sed phasellus senectus.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-5">
                  <div className="checkout-manage-subscription-fold-1">
                    <h3 className="checkout_head">Pro</h3>
                    <p className="text-uppercase ">
                      <b>Pro</b> / month
                    </p>
                  </div>
                  <div className="checkout-manage-subscription-fold-2">
                    <p className="price_check login_text text-start align-items-center text-grey mb-2">
                      <span className="my-account-plan-price me-2">$749</span>
                      <span>
                        per
                        <br />
                        month
                      </span>
                    </p>
                    <p className="login_text text-grey text-start mb-4">
                      Make progress on your goals. With this plan, you get 4
                      coaching sessions per month with a certified coach, access
                      to a library of curated learning content, and professional
                      planning resources.
                    </p>
                    <div>
                      <p>Monthly recurring subscription (cancel any time)</p>
                    </div>
                  </div>
                  <div className="checkout-manage-subscription-fold-3">
                    <div>
                      <span>
                        {" "}
                        <strong> Flavorable Pro</strong>{" "}
                        <span style={{ float: "right" }}>$749</span> <br />
                        Billed Monthly
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3"></div>
                <div className="col-md-4">
                  <div className="mt-3" id="card_error">
                    <label htmlFor="exampleFormControlInput1" className="form-label">
                      Name on card
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="card_number"
                      className="my-account-form"
                    />
                  </div>

                  <div className="mt-3" id="card_error">
                    <label htmlFor="exampleFormControlInput1" className="form-label">
                      Country or region
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="card_number"
                      className="my-account-form"
                    />
                  </div>  
                       <CardElement />

                  <div className="mt-3">
                    <a 
                    className="btn apple-pay-btn"
                    onClick={handleClickSubscribe}
                    >
                    subscribe
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout

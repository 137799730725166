import { React, Component } from 'react'
import Moment from 'react-moment';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';

export class RecentPost extends Component {
    render() {
        let title = this.props?.title?.replaceAll(" ", "-");
        return (
            <>
                <Link to={`/blog/${this.props?.id}/${title}`}  >
                    <div className="post-list">
                        <LazyLoad>
                            <span>
                                {this.props?.image?.filter(tru => tru?.is_hero === true)?.map(img => (
                                    < img src={img?.image} alt="" key={img?.image} />
                                ))}
                            </span>
                        </LazyLoad>
                        <p style={{ color: "black" }}>{<Moment format='MMM D, YYYY'>{this.props?.date}</Moment>}</p>
                        <h4>{this.props?.title}</h4>
                    </div>
                </Link>
            </>
        )
    }
}

export default RecentPost;
import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import {
  postBlogComments,
  subscribeArticles,
  currentBlog,
  postBlogReply,
} from "../../apis";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import { notification } from "antd";
import { ReactSession } from "react-client-session";
import ReactHtmlParser from "react-html-parser";
import RecentPost from "../components/RecentPost";
import RelatedPost from "../components/RelatedPost";
import LoggedInComment from "../components/LoggedInComments";
import NotLoggedInComments from "../components/NotLoggedInComments";
import Tags from "../components/Tags";
import default_person from "../../assets/images/default_person.png";
import $ from "jquery";
import Loading from "../components/Loading";
import { useParams } from "react-router-dom";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: null,
      commentsField: { comment: "" },
      commentsError: {},
      newsLetterFields: { newsLetterEmail: "" },
      newsLetterError: { newsLetterErrorEmail: "" },
      isComment: false,
      currentBlog: {},
      loading: true,
      blog_url: "",
      is_Loged_In: false,
      user_profile: {},
      reply: { reply: "" },
      errorReply: { errorReply: "" },
      comment_id: 0,
      blog_id: 0,
      isLoading: false,
    };
    this.handleChangePostComment = this.handleChangePostComment.bind(this);
    this.handleChangeNewsLetter = this.handleChangeNewsLetter.bind(this);
    this.handleClickPostComment = this.handleClickPostComment.bind(this);
    this.handleClickNewsLetter = this.handleClickNewsLetter.bind(this);
  }

  componentDidMount() {
    let blog_id = this.props?.params?.blogId;

    window.scrollTo(0, 0);

    let blog_url = window?.location?.pathname;

    const token = ReactSession?.get("authtoken");
    const user_profile = ReactSession?.get("user_data");
    let is_Loged_In = false;
    if (token) {
      is_Loged_In = true;
    }
    this.setState({
      is_Loged_In: is_Loged_In,
    });

    currentBlog(blog_id)
      .then((resp) => {
        for (let data of resp.data.comments.comments) {
          data["displayReply"] = false;
        }

        this.setState({
          currentBlog: resp.data,
          blog_id: blog_id,
          loading: false,
          blog_url: blog_url,
          is_Loged_In: is_Loged_In,
          user_profile: user_profile,
        });
        this.inputLableOverLapping();
      })

      .catch((err) => {});

    this.setState({
      blog: this.blog_slider,
    });
  }

  inputLableOverLapping() {
    //Input label effects JS
    $("input").val("");

    $(".input-effect input").focusout(function () {
      if ($(this).val() !== "") {
        $(this).addClass("has-content");
      } else {
        $(this).removeClass("has-content");
      }
    });
    $(".input-effect textarea").focusout(function () {
      if ($(this).val() !== "") {
        $(this).addClass("has-content");
      } else {
        $(this).removeClass("has-content");
      }
    });
    $(".input-effect select").focusout(function () {
      if ($(this).val() !== "") {
        $(this).addClass("has-content");
      } else {
        $(this).removeClass("has-content");
      }
    });
  }

  componentDidUpdate(previousProps, pS, cC) {
    if (previousProps?.params?.blogId !== this.props?.params?.blogId) {
      this.setState({
        isLoading: true,
      });

      let blog_id = this.props?.params?.blogId;

      window.scrollTo(0, 0);

      let blog_url = window?.location?.pathname;

      const token = ReactSession?.get("authtoken");
      const user_profile = ReactSession?.get("user_data");
      let is_Loged_In = false;
      if (token) {
        is_Loged_In = true;
      }
      this.setState({
        is_Loged_In: is_Loged_In,
      });

      currentBlog(blog_id)
        .then((resp) => {
          for (let data of resp.data.comments.comments) {
            data["displayReply"] = false;
          }

          this.setState({
            currentBlog: resp.data,
            blog_id: blog_id,
            loading: false,
            blog_url: blog_url,
            is_Loged_In: is_Loged_In,
            user_profile: user_profile,
            isLoading: false,
          });
        })

        .catch((err) => {});

      this.setState({
        blog: this.blog_slider,
      });
    }
  }

  handleChangePostComment(e) {
    let commentsField = this.state?.commentsField;
    commentsField[e?.target?.name] = e?.target?.value;
    this.setState({
      commentsField,
    });
  }

  handleChangeNewsLetter(e) {
    let newsLetterFields = this.state?.newsLetterFields;
    newsLetterFields[e?.target?.name] = e?.target?.value;
    this.setState({
      newsLetterFields,
    });
  }

  handleClickPostComment(event) {
    event.preventDefault();
    const userData = {
      article: this.state?.currentBlog?.id,
      comment: this.state?.commentsField["comment"],
    };
    if (this.validateFormComments()) {
      this.setState({
        isLoading: true,
      });
      document.getElementById("btnc").disabled = true;
      postBlogComments(userData)
        .then((resp) => {
          notification.success({
            message: "You have succesfully commented",
            placement: "bottomRight",
          });

          let new_comment = {
            comment: resp?.data?.comment,
            id: resp?.data?.id,
            replies: [],
            time_stamp: resp?.data?.created_at,
            user: {
              email: this.state?.user_profile?.email,
              profile_img: this.state?.user_profile?.profile_image,
              username: this.state.user_profile?.username,
            },
          };

          this.setState({
            commentsField: { comment: "" },
            ...this.state.currentBlog?.comments?.comments?.unshift(new_comment),
            isLoading: false,
          });
          document.getElementById("btnc").disabled = false;
        })

        .catch((err) => {
          document.getElementById("btnc").disabled = false;
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  handleClickNewsLetter(event) {
    event.preventDefault();
    const newsLetterEmail = this.state?.newsLetterFields?.newsLetterEmail;
    const userData = {
      email: this.state?.newsLetterFields["newsLetterEmail"],
    };
    if (this.validateFormNewsLetter()) {
      this.setState({
        isLoading: true,
      });
      document.getElementById("btnnl").disabled = true;
      subscribeArticles(userData)
        .then((resp) => {
          notification.success({
            message: "Subscription Success",
            description: resp.data.detail,
            placement: "bottomRight",
          });

          this.setState({
            newsLetterFields: { newsLetterEmail: "" },
            isLoading: false,
          });
          document.getElementById("btnnl").disabled = false;
        })
        .catch((err) => {
          if (err.response) {
            notification.error({
              message: ` ${newsLetterEmail} has already subscribed`,
              placement: "bottomRight",
            });
          }
          this.setState({
            isLoading: false,
          });
          document.getElementById("btnnl").disabled = false;
        });
    }
  }

  validateFormComments() {
    let commentsField = this.state?.commentsField;
    let commentsError = {};
    let isValid = true;

    // checking whather confirm password value given or not
    if (!commentsField["comment"]?.trim()) {
      isValid = false;
      commentsError["errorComment"] = "*Please enter comment.";
    }

    this.setState({
      commentsError: commentsError,
    });

    return isValid;
  }
  validateFormNewsLetter() {
    let newsLetterFields = this.state.newsLetterFields;
    let newsLetterError = {};
    let isValid = true;

    if (!newsLetterFields["newsLetterEmail"]?.trim()) {
      isValid = false;
      newsLetterError["newsLetterErrorEmail"] = "*Please enter your email.";
    } else {
      //regex for email validation
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(newsLetterFields["newsLetterEmail"])) {
        isValid = false;
        newsLetterError["newsLetterErrorEmail"] = "*Please enter valid email.";
      }
    }

    this.setState({
      newsLetterError: newsLetterError,
    });
    return isValid;
  }

  handleDisplayReply = (comment_id) => {
    for (let data of this.state.currentBlog.comments.comments) {
      if (data.id === comment_id) {
        data.displayReply = !data.displayReply;
      }
    }

    this.setState({
      currentBlog: this.state.currentBlog,
    });
  };

  handleChangeReply = (e) => {
    let reply = this.state?.reply;
    reply[e.target.name] = e.target.value;

    this.setState({
      reply: reply,
    });
  };

  handleClickPostReply = (comment_id) => {
    const user_profile = ReactSession?.get("user_data");
    const userData = {
      comment: comment_id,
      reply: this.state?.reply?.reply,
    };
    if (this.validateFormReply()) {
      this.setState({
        isLoading: true,
      });
      document.getElementById("btn1").disabled = true;
      postBlogReply(userData)
        .then((resp) => {
          notification.success({
            message: "you have succesfully replyed",
            description: "",
            placement: "bottomRight",
          });

          let new_reply = {
            reply: resp?.data?.reply,
            time_stamp: resp?.data?.created_at,
            user: {
              email: user_profile?.email,
              profile_img: user_profile?.profile_image,
              username: user_profile?.username,
            },
          };
          for (let data of this.state.currentBlog.comments.comments) {
            if (data.id === comment_id) {
              data.displayReply = !data.displayReply;
            }
          }

          this.setState({
            currentBlog: this.state.currentBlog,
          });

          let current_comment =
            this.state?.currentBlog?.comments?.comments?.filter(
              (com) => com?.id === comment_id
            );
          current_comment[0]?.replies?.unshift(new_reply);

          this.setState({
            isReply: false,
            reply: { reply: "" },
            currentBlog: this.state.currentBlog,
            isLoading: false,
          });
          // document.getElementById("btn1").disabled = false;
        })

        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          document.getElementById("btn1").disabled = false;
        });
    }
  };

  validateFormReply() {
    let reply = this.state.reply;
    let errorReply = {};
    let isValid = true;

    if (!reply["reply"]?.trim()) {
      isValid = false;
      errorReply["errorReply"] = "*Please enter reply.";
    }

    this.setState({
      errorReply: errorReply,
    });

    return isValid;
  }

  render() {
    let slidesToShow =
      this.state.currentBlog?.related_articles?.length < 3
        ? this.state.currentBlog?.related_articles?.length
        : 3;
    const blog_slider_settings = {
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      autoPlay: true,
      pauseOnHover: true,
      lazyLoad: "progressive",
      arrows: true,
      dots: false,
      infinite: true,
      focusOnSelect: true,
      responsive: [
        ,
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: slidesToShow,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          },
        },
        ,
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    let reply_count = 0;
    let comments_length = this.state?.currentBlog?.comments?.comments?.length;
    if (comments_length) {
      for (let i = 0; i <= comments_length - 1; i++) {
        reply_count +=
          this.state?.currentBlog?.comments?.comments[i]?.replies?.length;
      }
    }
    const total_count = reply_count + comments_length;
    const currunt_blog_url = window.location.href;
    return (
      <div>
        {this.state?.loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            {this.state.is_Loged_In && (
              <Link to="/createblog">
                <div className="creatblog"> + </div>
              </Link>
            )}
            <div>
              {window.screen.width > 767 ? (
                <>
                  <section className="single-blog-section">
                    <div
                      className="banner-content "
                      style={{
                        backgroundImage: `url(${
                          this.state.currentBlog.images?.filter(
                            (image) => image?.is_hero === true
                          )[0]?.image
                        }) `,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <span></span>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-xl-6 col-md-6 col-lg-5">
                            <h1 className="title">
                              <span>
                                {this.state?.currentBlog?.tags?.map(
                                  (tag, index) => {
                                    return (
                                      <React.Fragment key={`${tag}-${index}`}>
                                        {tag}{" "}
                                        {index <
                                          this.state?.currentBlog?.tags
                                            ?.length -
                                            1 && <>&nbsp;| &nbsp;</>}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                                {/* tags here */}
                                <strong>
                                  {
                                    <Moment format="MMM D, YYYY">
                                      {this.state?.currentBlog?.created_at}
                                    </Moment>
                                  }
                                </strong>
                              </span>
                              {this.state?.currentBlog?.title}
                            </h1>
                            <p>
                              {" "}
                              by {
                                this.state?.currentBlog?.author?.first_name
                              }{" "}
                              {this.state?.currentBlog?.author?.last_name}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="blog-submenu">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-xl-10 col-md-12 col-lg-10">
                          <Link to="/blogs" style={{ fontSize: "14px" }}>
                            Blog
                          </Link>
                          <strong style={{ color: "black" }}>
                            &nbsp; | &nbsp;
                          </strong>
                          {this.state?.currentBlog?.tags?.map((tag, index) => {
                            return (
                              <React.Fragment key={`${tag}-${index}`}>
                                <Link
                                  style={{ fontSize: "14px" }}
                                  to={`/blogs/${tag}`}
                                >
                                  {tag} &nbsp;
                                </Link>{" "}
                                <strong style={{ color: "black" }}>
                                  {" "}
                                  | &nbsp;
                                </strong>
                              </React.Fragment>
                            );
                          })}
                          <strong></strong>
                          <span
                            style={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "600",
                            }}
                          >
                            {this.state?.currentBlog?.title?.toUpperCase()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!--Sub Menu end-->  */}
                  {/* <!--Mid Section start--> */}
                  <section className="mid-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-9 col-md-12">
                          <div className="blog-sin-lft">
                            <div className="row">
                              <div className="col-12">
                                <span className="share-blog-links">
                                  {" "}
                                  <strong>Share</strong>
                                  <ul className="social blog-share">
                                    <li style={{ listStyle: "none" }}>
                                      <b className="share-icon"></b>{" "}
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                      <FacebookShareButton
                                        url={currunt_blog_url}
                                      >
                                        <li>
                                          <Link
                                            to={""}
                                            className="fb-icon"
                                          ></Link>
                                        </li>
                                      </FacebookShareButton>
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                      <TwitterShareButton
                                        url={currunt_blog_url}
                                      >
                                        <li>
                                          <Link
                                            to={""}
                                            className="twitter-icon"
                                          ></Link>
                                        </li>
                                      </TwitterShareButton>
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                      <WhatsappShareButton
                                        url={currunt_blog_url}
                                      >
                                        <li>
                                          <Link
                                            to={""}
                                            className="what-icon"
                                          ></Link>
                                        </li>
                                      </WhatsappShareButton>
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                      <LinkedinShareButton
                                        url={currunt_blog_url}
                                      >
                                        <li>
                                          <Link
                                            to={""}
                                            className="link-icon"
                                          ></Link>
                                        </li>
                                      </LinkedinShareButton>
                                    </li>
                                  </ul>
                                </span>
                                <div className="blog-content-area">
                                  {ReactHtmlParser(
                                    this.state?.currentBlog?.content
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="author-box d-flex justify-content-center align-item-center">
                                  <span className="author-img">
                                    {this.state?.currentBlog?.author
                                      ?.profile_image ? (
                                      <img
                                        src={
                                          this.state.currentBlog.author
                                            .profile_image
                                        }
                                        alt=""
                                        className="img-fluid"
                                      />
                                    ) : (
                                      <img
                                        src={default_person}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    )}
                                  </span>
                                  <div className="author-content">
                                    <h4>
                                      {
                                        this.state?.currentBlog?.author
                                          ?.first_name
                                      }{" "}
                                      {
                                        this.state?.currentBlog?.author
                                          ?.last_name
                                      }
                                    </h4>
                                    <p style={{color:'#000'}}>
                                      {this.state?.currentBlog?.author.bio}{" "}
                                    </p>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-12">
                          <div className="react-post">
                            <h4 className="recent-posts">Recent Posts</h4>
                            {this.state?.currentBlog?.recent_articles?.map(
                              (article) => (
                                <RecentPost
                                  key={article?.id}
                                  title={article?.title}
                                  date={article?.created_at}
                                  image={article?.images}
                                  id={article?.id}
                                  handleClickRecentPost={(blog_id) =>
                                    this.handleClickRecentPost(blog_id)
                                  }
                                />
                              )
                            )}
                          </div>
                          <div className="newsletter-box">
                            <h4 style={{ fontSize: 24 }}>Newsletter</h4>
                            <div className="input-group search-box">
                              <div className="input-effect">
                                <input
                                  className="effect"
                                  name="newsLetterEmail"
                                  value={
                                    this.state?.newsLetterFields[
                                      "newsLetterEmail"
                                    ]
                                  }
                                  placeholder="Email"
                                  onChange={this.handleChangeNewsLetter}
                                  autoComplete="off"
                                  aria-describedby="basic-addon2"
                                />
                                <div className="input-group-append">
                                  <button
                                    id="btnnl"
                                    className="btn"
                                    type="button"
                                    onClick={this.handleClickNewsLetter}
                                  >
                                    {this.state.isLoading && (
                                      <div
                                        className="spinner-border text-info"
                                        role="status"
                                      >
                                        <span className="visually-hidden"></span>
                                      </div>
                                    )}
                                  </button>
                                </div>
                                <span className="errorValidation">
                                  {
                                    this.state?.newsLetterError
                                      ?.newsLetterErrorEmail
                                  }
                                </span>
                                <span className="focus-border"></span>
                              </div>
                            </div>
                            {/* <span className="author-social"> <a>FB</a> <b></b> <a>TWI</a> <b></b> <a >inst</a> </span> */}
                          </div>
                          <div className="tags-box col-8 col-md-6 col-lg-12">
                            <h4 style={{ fontSize: 24 }}>Tags</h4>
                            <Tags tags={this.state?.currentBlog?.tags} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="blog-slider-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <h2 style={{ fontSize: 35 }}>Related</h2>
                        </div>
                      </div>
                      <Slider
                        asNavFor={this.state?.blog}
                        ref={(slider) => (this.blog_slider = slider)}
                        className="react-blog-slider"
                        {...blog_slider_settings}
                      >
                        {this.state.currentBlog.related_articles.map(
                          (article) => (
                            <RelatedPost
                              key={article?.id}
                              title={article?.title?.slice(0, 50)}
                              abstract={article?.abstract?.slice(0, 135)}
                              images={article?.images}
                              date={article?.created_at}
                              id={article?.id}
                              tags={article?.tags}
                              handleClickRelatedPost={(blog_id) =>
                                this.handleClickRelatedPost(blog_id)
                              }
                            />
                          )
                        )}
                      </Slider>
                    </div>
                  </section>
                  {/* <!--blog-slider-section end-->  */}

                  {/* <!--Commnet Section start--> */}
                  <section className="commnet-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-8">
                          <div className="comment-area">
                            <h2 className="b-comment">
                              Comments (
                              {this.state?.currentBlog?.comments?.comments
                                ?.length > 0
                                ? total_count
                                : "0"}
                              )
                            </h2>
                            <div className="commnet-box">
                              {this.state.is_Loged_In ? (
                                <LoggedInComment
                                  comments={
                                    this.state?.currentBlog?.comments?.comments
                                  }
                                  handleChangeReply={(e) =>
                                    this.handleChangeReply(e)
                                  }
                                  handleDisplayReply={(comment_id) =>
                                    this.handleDisplayReply(comment_id)
                                  }
                                  handleClickPostReply={(comment_id) =>
                                    this.handleClickPostReply(comment_id)
                                  }
                                  reply={this.state?.reply?.reply}
                                  errorReply={
                                    this.state?.errorReply?.errorReply
                                  }
                                  comment_id={this.state?.comment_id}
                                  isLoading={this.state.isLoading}
                                />
                              ) : (
                                <NotLoggedInComments
                                  comments={
                                    this.state?.currentBlog?.comments?.comments
                                  }
                                />
                              )}
                            </div>
                          </div>
                          {/* Comments end */}
                          <div className="contact-form">
                            <div className="row">
                              <div className="col-12">
                                <h2 style={{ fontSize: 35, fontWeight: 500 }}>
                                  Leave a reply
                                </h2>
                              </div>
                            </div>
                            {this.state.is_Loged_In ? (
                              <>
                                <p className="mb-5" style={{ color: "black" }}>
                                  Leave your comment below.
                                </p>
                                <div className="col-12 col-xl-12 col-md-12">
                                  <div className="input-effect">
                                    <textarea
                                      className="effect"
                                      id="message"
                                      type="text"
                                      name="comment"
                                      value={this.state?.commentsField?.comment}
                                      onChange={this.handleChangePostComment}
                                      autoComplete="off"
                                    ></textarea>
                                    <label>Your comment</label>
                                    <span className="errorValidation">
                                      {this.state?.commentsError?.errorComment}
                                    </span>
                                  </div>
                                  <button
                                    id="btnc"
                                    onClick={this.handleClickPostComment}
                                    className="btn "
                                  >
                                    Post
                                    {this.state.isLoading && (
                                      <div
                                        className="spinner-border text-info"
                                        role="status"
                                      >
                                        <span className="visually-hidden"></span>
                                      </div>
                                    )}
                                    &nbsp; Comment
                                  </button>
                                </div>
                              </>
                            ) : (
                              <div className="col-12 col-xl-12 col-md-12 p-0">
                                <Link to="/register" className="btn ">
                                  {" "}
                                  Register to share your thoughts
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <>
                  <section className="single-blog-section">
                    <div
                      className="banner-content "
                      style={{
                        backgroundImage: `url(${
                          this.state.currentBlog.images?.filter(
                            (image) => image?.is_hero === true
                          )[0]?.image
                        }) `,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <span></span>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-xl-6 col-md-6 col-lg-5">
                            <h1 className="title">
                              <span>
                                {this.state?.currentBlog?.tags?.map(
                                  (tag, index) => {
                                    return (
                                      <React.Fragment key={`${tag}-${index}`}>
                                        {tag}{" "}
                                        {index <
                                          this.state?.currentBlog?.tags
                                            ?.length -
                                            1 && <>&nbsp;| &nbsp;</>}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                                {/* tags here */}
                                <strong>
                                  {
                                    <Moment format="MMM D, YYYY">
                                      {this.state?.currentBlog?.created_at}
                                    </Moment>
                                  }
                                </strong>
                              </span>
                              {this.state?.currentBlog?.title}
                            </h1>
                            <p>
                              {" "}
                              by {
                                this.state?.currentBlog?.author?.username
                              }{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="blog-submenu">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-xl-10 col-md-12 col-lg-10">
                          <Link to="/blogs" style={{ fontSize: "14px" }}>
                            Blog
                          </Link>
                          <strong style={{ color: "black" }}>
                            &nbsp; | &nbsp;
                          </strong>
                          {this.state?.currentBlog?.tags?.map((tag, index) => {
                            return (
                              <React.Fragment key={`${tag}-${index}`}>
                                <Link
                                  style={{ fontSize: "14px" }}
                                  to={`/blogs/${tag}`}
                                >
                                  {tag} &nbsp;
                                </Link>{" "}
                                <strong style={{ color: "black" }}>
                                  {" "}
                                  | &nbsp;
                                </strong>
                              </React.Fragment>
                            );
                          })}
                          <strong></strong>
                          <span
                            style={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "600",
                            }}
                          >
                            {this.state?.currentBlog?.title?.toUpperCase()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!--Sub Menu end-->  */}
                  {/* <!--Mid Section start--> */}
                  <section className="mid-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-9 col-md-12">
                          <div className="blog-sin-lft">
                            <div className="row">
                              <div className="col-12">
                                <span className="share-blog-links">
                                  {" "}
                                  <strong>Share</strong>
                                  <ul className="social blog-share">
                                    <li style={{ listStyle: "none" }}>
                                      <b className="share-icon"></b>{" "}
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                      <FacebookShareButton
                                        url={currunt_blog_url}
                                      >
                                        <li>
                                          <Link
                                            to={""}
                                            className="fb-icon"
                                          ></Link>
                                        </li>
                                      </FacebookShareButton>
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                      <TwitterShareButton
                                        url={currunt_blog_url}
                                      >
                                        <li>
                                          <Link
                                            to={""}
                                            className="twitter-icon"
                                          ></Link>
                                        </li>
                                      </TwitterShareButton>
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                      <WhatsappShareButton
                                        url={currunt_blog_url}
                                      >
                                        <li>
                                          <Link
                                            to={""}
                                            className="what-icon"
                                          ></Link>
                                        </li>
                                      </WhatsappShareButton>
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                      <LinkedinShareButton
                                        url={currunt_blog_url}
                                      >
                                        <li>
                                          <Link
                                            to={""}
                                            className="link-icon"
                                          ></Link>
                                        </li>
                                      </LinkedinShareButton>
                                    </li>
                                  </ul>
                                </span>
                                <div className="blog-content-area">
                                  {ReactHtmlParser(
                                    this.state?.currentBlog?.content
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="author-box">
                                  <span className="author-img">
                                    {this.state?.currentBlog?.author
                                      ?.profile_image ? (
                                      <img
                                        src={
                                          this.state.currentBlog.author
                                            .profile_image
                                        }
                                        alt=""
                                        className="img-fluid"
                                      />
                                    ) : (
                                      <img
                                        src={default_person}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    )}
                                  </span>
                                  <div className="author-content">
                                    <h4>
                                      {
                                        this.state?.currentBlog?.author
                                          ?.username
                                      }
                                    </h4>
                                    <p>
                                      {this.state?.currentBlog?.author.bio}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="blog-slider-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <h2 style={{ fontSize: 35 }}>Related</h2>
                        </div>
                      </div>
                      <Slider
                        asNavFor={this.state?.blog}
                        ref={(slider) => (this.blog_slider = slider)}
                        className="react-blog-slider"
                        {...blog_slider_settings}
                      >
                        {this.state.currentBlog.related_articles.map(
                          (article) => (
                            <RelatedPost
                              key={article?.id}
                              title={article?.title?.slice(0, 50)}
                              abstract={article?.abstract?.slice(0, 135)}
                              images={article?.images}
                              date={article?.created_at}
                              id={article?.id}
                              tags={article?.tags}
                              handleClickRelatedPost={(blog_id) =>
                                this.handleClickRelatedPost(blog_id)
                              }
                            />
                          )
                        )}
                      </Slider>
                    </div>
                  </section>
                  <section className="commnet-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-8">
                          <div className="comment-area">
                            <h2 className="b-comment">
                              Comments (
                              {this.state?.currentBlog?.comments?.comments
                                ?.length > 0
                                ? total_count
                                : "0"}
                              )
                            </h2>
                            <div className="commnet-box">
                              {this.state.is_Loged_In ? (
                                <LoggedInComment
                                  comments={
                                    this.state?.currentBlog?.comments?.comments
                                  }
                                  handleChangeReply={(e) =>
                                    this.handleChangeReply(e)
                                  }
                                  handleDisplayReply={(comment_id) =>
                                    this.handleDisplayReply(comment_id)
                                  }
                                  handleClickPostReply={(comment_id) =>
                                    this.handleClickPostReply(comment_id)
                                  }
                                  reply={this.state?.reply?.reply}
                                  errorReply={
                                    this.state?.errorReply?.errorReply
                                  }
                                  comment_id={this.state?.comment_id}
                                  isLoading={this.state.isLoading}
                                />
                              ) : (
                                <NotLoggedInComments
                                  comments={
                                    this.state?.currentBlog?.comments?.comments
                                  }
                                />
                              )}
                            </div>
                          </div>
                          {/* Comments end */}
                          <div className="contact-form">
                            <div className="row">
                              <div className="col-12">
                                <h2 style={{ fontSize: 35, fontWeight: 500 }}>
                                  Leave a reply
                                </h2>
                              </div>
                            </div>
                            {this.state.is_Loged_In ? (
                              <>
                                <p className="mb-5" style={{ color: "black" }}>
                                  Leave your comment below.
                                </p>
                                <div className="col-12 col-xl-12 col-md-12">
                                  <div className="input-effect">
                                    <textarea
                                      className="effect"
                                      id="message"
                                      type="text"
                                      name="comment"
                                      value={this.state?.commentsField?.comment}
                                      onChange={this.handleChangePostComment}
                                      autoComplete="off"
                                    ></textarea>
                                    <label>Your comment</label>
                                    <span className="errorValidation">
                                      {this.state?.commentsError?.errorComment}
                                    </span>
                                  </div>
                                  <button
                                    id="btnc"
                                    onClick={this.handleClickPostComment}
                                    className="btn "
                                  >
                                    Post
                                    {this.state.isLoading && (
                                      <div
                                        className="spinner-border text-info"
                                        role="status"
                                      >
                                        <span className="visually-hidden"></span>
                                      </div>
                                    )}
                                    &nbsp; Comment
                                  </button>
                                </div>
                              </>
                            ) : (
                              <div className="col-12 col-xl-12 col-md-12">
                                <Link to="/register" className="btn ">
                                  {" "}
                                  Register to share your thoughts
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="mid-section">
                    <div className="col-12 col-lg-3 col-md-12">
                      <div className="react-post">
                        <h4 className="recent-posts">Recent Posts</h4>
                        {this.state?.currentBlog?.recent_articles?.map(
                          (article) => (
                            <RecentPost
                              key={article?.id}
                              title={article?.title}
                              date={article?.created_at}
                              image={article?.images}
                              id={article?.id}
                              handleClickRecentPost={(blog_id) =>
                                this.handleClickRecentPost(blog_id)
                              }
                            />
                          )
                        )}
                      </div>
                      <div className="newsletter-box">
                        <h4 style={{ fontSize: 24 }}>Newsletter</h4>
                        <div className="input-group search-box">
                          <div className="input-effect">
                            <input
                              className="effect"
                              name="newsLetterEmail"
                              value={
                                this.state?.newsLetterFields["newsLetterEmail"]
                              }
                              placeholder="Email"
                              onChange={this.handleChangeNewsLetter}
                              autoComplete="off"
                              aria-describedby="basic-addon2"
                            />
                            <div className="input-group-append">
                              <button
                                id="btnnl"
                                className="btn"
                                type="button"
                                onClick={this.handleClickNewsLetter}
                              >
                                {this.state.isLoading && (
                                  <div
                                    className="spinner-border text-info"
                                    role="status"
                                  >
                                    <span className="visually-hidden"></span>
                                  </div>
                                )}
                              </button>
                            </div>
                            <span className="errorValidation">
                              {
                                this.state?.newsLetterError
                                  ?.newsLetterErrorEmail
                              }
                            </span>
                            <span className="focus-border"></span>
                          </div>
                        </div>
                        {/* <span className="author-social"> <a>FB</a> <b></b> <a>TWI</a> <b></b> <a >inst</a> </span> */}
                      </div>
                      <div className="tags-box col-8 col-md-6 col-lg-12">
                        <h4 style={{ fontSize: 24 }}>Tags</h4>
                        <Tags tags={this.state?.currentBlog?.tags} />
                      </div>
                    </div>
                  </section>
                </>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withParams(Blog);

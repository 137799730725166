import React, { Component } from 'react'
import Modal from 'react-modal';



export default class IncorrectPasswordModal extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false
        };
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }
    componentDidMount() {
        this.handleOpenModal();
    }

    handleOpenModal() {
        this.setState({ showModal: true });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
        this.props.resetstateIPM()
    }

    render() {
        return (
            <>
                <Modal
                    isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    className="forgotPasswordModal incorrect-password-modal incorrect-password"
                    overlayClassName="forgotPasswordOverlay"
                    closeTimeoutMS={500}
                    ariaHideApp={false}
                >
                    <>
                        <div style={{ textAlign: "center" }}> Having trouble signing in? </div>
                        <div className="col-12 mt-4 form-group">
                            <div className="input-effect">
                                <input className="effect" type="text" name="email" value={this?.props?.email} onChange={this?.props?.handleChange} autoComplete="off" />
                                <span className='errorValidation'>{this.props.errorEmail}</span>
                            </div>
                        </div>
                        <div style={{ textAlign: "center" }}>Please click on the button below to send the magic link to your email:</div>
                        <div className="col-12 col-xl-12 col-md-12 mt-3 text-center">
                            <a onClick={this.props.handleClickMagicLink} className="btn">Send login link</a>
                        </div>
                        <div className="col-12 col-xl-12 col-md-12">
                            <div className="login-other">
                                <h3><strong > Or reset your password</strong></h3>
                            </div>
                        </div>
                        <div style={{ textAlign: "center" }}>Please click on the button below to  send the reset password link to your email</div>
                        <div className="col-12 col-xl-12 col-md-12 mt-3 text-center">
                            <a onClick={this.props.handleClickResetPassword} className="btn">Reset password</a>
                        </div>
                    </>
                </Modal>
            </>
        )
    }
}

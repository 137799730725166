import React, { Component } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
export default class SubCancelReactivateModal extends Component {
  render() {
    return (
      <>
      {/* {this.props?.current_subscription?.id !== this.props.plan_id &&
        <div className="col-12 col-xl-12 col-md-12 text-center">
          <a
            className="btn my-account-apply"
            onClick={() =>
              this.props.handleClickSwitchPreview(this.props?.plan_id)
            }
            style={{ marginTop: "-90px" }}
          >
            Switch
          </a>
        </div>} */}
        <Modal
          isOpen={this.props?.show_cancel_reactivate_modal}
          onRequestClose={this.props.handleCloseCancelReactivateModal}
          className={ "subscription-preview-modal-downgrade"}
          overlayClassName="forgotPasswordOverlay"
          closeTimeoutMS={500}
          ariaHideApp={false}
          id={`${this.props.plan_id}`}
        >
          <>
            {this.props?.is_recurring ? (
            <>
            <div className="container">
        <div className="row">
            <div className="col-xl-12 container-border p-5">
                <div className="row">
                    <div className="col-xl-12 d-flex justify-content-between">
                        <p className="mt-2 mb-0 plan-modal-paragraph">Are you sure you want to cancel the subscription?</p>
                        <div className="d-flex align-items-center">
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-xl-12 d-flex justify-content-between">
                        <p className="mt-2 mb-0 plan-modal-paragraph">If you choose to cancel the subcription the you will loose the feture of the current plans </p>
                        <div className="d-flex align-items-center">
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-xl-12 d-flex justify-content-between">
                        <p className="mt-2 mb-0 plan-modal-paragraph">The subscription will cancel when the current subscription expires</p>
                        <div className="d-flex align-items-center">
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <hr/>
                        <button className="btn mr-auto ml-auto d-block" onClick={()=>this.props.handleClickCancelRactive()}>Cancel </button>
                    </div>
                </div>
            </div>
            <hr/>
        </div>
    </div>
            
            </>)
             :(
                <div className="container">
                <div className="row">
                    <div className="col-xl-12 container-border p-5">
                        <div className="row">
                            <div className="col-xl-12 d-flex justify-content-between">
                                <p className="mt-2 mb-0 plan-modal-paragraph">Thank you for choosing to continue with the plan.</p>
                                <div className="d-flex align-items-center">
                                </div>
                            </div>
                        </div>
                        {/* <hr/>
                        <div className="row">
                            <div className="col-xl-12 d-flex justify-content-between">
                                <p className="mt-2 mb-0 plan-modal-paragraph">If you choose to cancel the subcription the you will loose the feture of the current plans </p>
                                <div className="d-flex align-items-center">
                                </div>
                            </div>
                        </div> */}
                        <hr/>
                        <div className="row">
                            <div className="col-xl-12 d-flex justify-content-between">
                                <p className="mt-2 mb-0 plan-modal-paragraph">Click on the below reactivate button to auto renew the subscription</p>
                                <div className="d-flex align-items-center">
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <hr/>
                                <button className="btn mr-auto ml-auto d-block" onClick={()=>this.props.handleClickCancelRactive()}>Reactivate </button>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            </div> )   }
          </>
        </Modal>
      </>
    );
  }
}

import React from "react";
import "../../assets/css/tcreate-recipe.css";
import { ReactSession } from "react-client-session";
import img1 from "../../assets/images/unsplash_kcA-c3f_3FE.png";
import img2 from "../../assets/images/Frame 1131.png";
import img3 from "../../assets/images/ant-design_cloud-upload-outlined.png";
import { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createRecipeActions } from "../../store/createRecipe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import VideoInput from "../components/videoInput";
import { retrieveRecipe } from "../../apis";

const EditRecipeStep1 = (props) => {
  const params = useParams();

  const [recipe_name, setRecipeName] = useState("");

  const [curruntDifficulty, setCurruntDifficulty] = useState("");

  const [images, setImages] = useState([]);

  const [selected_images, setSelectedImages] = useState([]);

  const [video, setVideo] = useState([]);

  const [errors, setErrors] = useState({
    recipe_name: "",
    image: "",
    difficulty: "",
  });
  const [is_validated, setIsValidate]= useState(false)

  const dispatch = useDispatch();

  const create_recipe = useSelector(
    (state) => state.createrecipe.create_recipe
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    if(!create_recipe.recipe_name ){
    retrieveRecipe(params.slug)
    .then((resp=>{
      let user_inserted_images = []
      if(resp?.data?.results?.image_1){
        let image1 = {
          'id' : 1,
          'image' : resp?.data?.results?.image_1
        }
        user_inserted_images?.push(image1)
        if(resp?.data?.results?.image_2){
          let image2 = {
            'id' : 2,
            'image' : resp?.data?.results?.image_2
          }
          user_inserted_images?.push(image2)
          if(resp?.data?.results?.image_3){
            let image3 = {
              'id' : 3,
              'image' : resp?.data?.results?.image_3
            }
            user_inserted_images?.push(image3)
          
          if(resp?.data?.results?.image_4){
            let image4 = {
              'id' : 4,
              'image' : resp?.data?.results?.image_4
            }
            user_inserted_images?.push(image4)

            if(resp?.data?.results?.image_5){
              let image5 = {
                'id' : 5,
                'image' : resp?.data?.results?.image_5
              }
              user_inserted_images?.push(image5)
            }
            }
          }
        }
      }
      let new_video ={
        image : resp?.data?.results?.recipe_video
      }
    setRecipeName(resp?.data?.results?.name_of_recipe);
    setCurruntDifficulty(resp?.data?.results?.difficulty);
    setSelectedImages(user_inserted_images);
    setVideo(new_video);
    }))
    .catch((err=>{
      console.log('this is the error for the current recipe'.err.response);
    }))
  }
  else{
    setRecipeName(create_recipe.recipe_name);
    setCurruntDifficulty(create_recipe.recipe_difficulty);
    setSelectedImages(create_recipe.recipe_images);
    setVideo(create_recipe.recipe_video);
  }
  }, []);

  const handleClickNext = () => {
    if(isValidated()){
      dispatch(
        createRecipeActions.createRecipe({
          ...create_recipe,
          recipe_name: recipe_name,
          recipe_difficulty: curruntDifficulty,
          recipe_images: selected_images,
          recipe_video: video,
        })
      );
      setIsValidate(true)
    }

  };

  const handleChangeRecipeNmae = (e) => {
    setRecipeName(e.target.value);
  };

  const handleClickDifficultyEasy = (e) => {
    e.preventDefault();
    setCurruntDifficulty("easy");
  };

  const handleClickDifficultyMedium = (e) => {
    e.preventDefault();
    setCurruntDifficulty("medium");
  };

  const handleClickDifficultyHard = (e) => {
    e.preventDefault();
    setCurruntDifficulty("hard");
  };

  const handleChangeImage = (e) => {
    let images = [...selected_images];
    let image = e.target.files[0];
    let id = images?.length;
    if (id === 0) {
      id = 1;
    } else {
      id = images[id - 1]?.id + 1;
    }
    let new_image = {
      id: id,
      image: URL.createObjectURL(image),
      file: image,
    };
    images.push(new_image);
    setSelectedImages(images);
    setImages(image);
  };

  const handleDeleteImage = (id) => {
    let images = [...selected_images];
    for (let image of images) {
      if (image.id === id) {
        let index = images.indexOf(image);
        // only splice array when item is found
        if (index > -1) {
          // 2nd parameter means remove one item only
          images.splice(index, 1);
        }
      }
    }
    setSelectedImages(images);
  };

  const handleDeleteVideo = () => {
    setVideo([]);
  };

  const handleChangeVideo = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    let new_video = {
      image: url,
      file: file,
    };
    setVideo(new_video);
  };

  // validating the form

  const isValidated = () => {
    let is_validted = true;
    let error = {};
    if (!recipe_name?.trim()) {
      is_validted = false;
      error.recipe_name = "please add the recipe name";
    }
    if (!selected_images.length) {
      is_validted = false;
      error.image = "Please add atleast one image";
    }
    if (!curruntDifficulty) {
      is_validted = false;
      error.difficulty = "please select the difficulty";
    }
    setErrors(error);
    return is_validted;
  };

  const user_info = ReactSession.get("user_data");
  return (
    <>
      <>
      {is_validated && (<Navigate to={`/edit-recipe-step-2/${params.slug}`} replace={true} />)}
        <section className="create-recipe-page-1">
          <div className="container">
            <div className="row d-flex justify-content-center mt-2">
              <div className="col-11 col-xl-12 col-md-12 col-lg-6">
                <h5 className="create-recipe-title">
                  HI, {user_info?.first_name}!
                </h5>
                <span className="create-recipe-heading">Create Recipe</span>
                <p className="col-xl-6 col-11 p-0 create-recipe-paragraph-section-1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est,
                  adipiscing cursus auctor eget sed phasellus senectus.{" "}
                </p>
              </div>
              <div className="col-xl-12 col-md-12 col-11">
                <ul className="progressbar pl-0">
                  <li className="active">Step 1</li>
                  <li>Step 2</li>
                  <li>Step 3</li>
                  <li>Step 4</li>
                </ul>
              </div>
              <div className="col-xl-12 col-11">
                <div className="progress mt-2 p-0" style={{ height: "5px" }}>
                  <div
                    className="progress-bar steps-bg-color"
                    role="progressbar"
                    aria-label="Example 1px high"
                    style={{ width: "25%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="row recipe-section-1 d-flex justify-content-center">
            <div className="col-xl-4 col-md-12 col-11 mt-3">
              <div>
                <span className="recipe-name">Name your recipe</span>
                <span className="red-star"> *</span>
              </div>
              <div className="step-2-input-fields">
                <div className="row">
                  <div className="col-xl-12 form-group mt-2">
                    <div className="input-effect-create-recips">
                      <input
                        className="effect-1"
                        type="text"
                        name="recipe_name"
                        autoComplete="off"
                        value={recipe_name}
                        onChange={handleChangeRecipeNmae}
                        placeholder="E.g. Apple Pie"
                      />
                    </div>
                    <span className='errorValidation'>{errors?.recipe_name}</span>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <span className="recipe-name">
                  Add recipe images
                  <span className="no-of-images">(up to 5)</span>
                </span>
                <span className="red-star">*</span>
                <div className="upload-image-container">
                  {selected_images.map((image) => (
                    <div className="image-container" key={image.id}>
                      <img src={image.image} alt="" className="upload-image" />
                      <div
                        className="image-delete"
                        onClick={() => handleDeleteImage(image.id)}
                      >
                        x
                      </div>
                    </div>
                  ))}
                  {selected_images.length < 5 && (
                    <label htmlFor="img_uploader">
                      <img
                        src={img2}
                        alt=""
                        className="upload-image"
                        style={{ cursor: "pointer" }}
                      />
                      <input
                        type="file"
                        id="img_uploader"
                        accept="image/*"
                        className="d-none"
                        onChange={handleChangeImage}
                      />
                    </label>
                  )}
                   <span className='errorValidation'>{errors?.image}</span>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-md-12 col-11 mt-3">
              <div>
                <span className="recipe-name">Video upload</span>
                <span className="red-star"> *</span>
                <div className="video-upload-section mt-3 ">
                  <div className="d-flex justify-content-center"></div>
                  <div className="d-flex justify-content-center text-center">
                    {video?.length === 0 ? (
                      <span className="upload-video">Uplaod a new video</span>
                    ) : (
                      <span className="upload-video">Uplaoded video</span>
                    )}
                  </div>
                  <VideoInput
                    img={img3}
                    width={400}
                    height={200}
                    handleChangeVideo={handleChangeVideo}
                    video={video}
                    handleDeleteVideo={handleDeleteVideo}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-12 col-11 mt-3">
              <div>
                <span className="recipe-name">Difficulty</span>
                <div className="d-flex flex-column mt-3">
                  <button
                    type="button"
                    className="difficulty-button"
                    style={
                      curruntDifficulty === "easy"
                        ? { background: "#85b492", color: '#fff' }
                        : { background: "#fff" }
                    }
                    name="Easy"
                    onClick={handleClickDifficultyEasy}
                  >
                    <span>easy</span>
                  </button>

                  <button
                    type="button"
                    className="difficulty-button"
                    name="Medium"
                    style={
                      curruntDifficulty === "medium"
                        ? { background: "#85b492", color: '#fff' }
                        : { background: "#fff" }
                    }
                    onClick={handleClickDifficultyMedium}
                  >
                    <span>medium</span>
                  </button>

                  <button
                    type="button"
                    className="difficulty-button"
                    name="Hard"
                    style={
                      curruntDifficulty === "hard"
                        ? { background: "#85b492", color: '#fff' }
                        : { background: "#fff" }
                    }
                    onClick={handleClickDifficultyHard}
                  >
                    <span>hard</span>
                  </button>
                </div>
                <span className='errorValidation'>{errors?.difficulty}</span>
              </div>
            </div>
          </div>
          <hr className="mb-5 hr-color" />
          <div className="d-flex justify-content-end mb-5">
              <button
                type="button"
                className="btn btn-success btn-rounded previous-btn"
                onClick={handleClickNext}
              >
                Next
              </button>
          </div>
        </div>
      </>
    </>
  );
};

export default EditRecipeStep1;

import React, { useState } from "react";
import Modal from "react-modal";

const ListDirection = (props) => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    return (
        <>
            <a className="btn btn-add-directions" onClick={handleOpenModal}>
                Add Directions
            </a>
      <span className='errorValidation'>{props.direction_error}</span>

            <Modal
                isOpen={showModal}
                onRequestClose={handleCloseModal}
                className="forgotPasswordModal directionListModal"
                overlayClassName="forgotPasswordOverlay"
                closeTimeoutMS={500}
                ariaHideApp={false}
            >
                <>
                    <ol className="col-12">
                        {props.directions_list?.map((direction, index) => {
                            return (
                                <li
                                    data-list-index={`${index + 1}.`}
                                    key={direction.id}
                                >
                                    <div>
                                    {direction?.direction}
                                    </div>
                                </li>
                            );
                        })}
                    </ol>

                    <div className="col-12 form-group">
                        <div className="input-effect">
                            <textarea
                                className="effect"
                                type="text"
                                name="direction_input"
                                onChange={props.handleChangeDirection}
                                value={props.direction_input}
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        props.handleClickAddDirection();
                                    }
                                }}
                                autoComplete="off"
                            />
                            <span className="errorValidation"></span>
                        </div>
                    </div>

                    <div className="col-12 col-xl-12 col-md-12 text-center">
                        <a
                            style={{ color: "#85b492" }}
                            onClick={props.handleClickAddDirection}
                        >
                            +Add Next Step
                        </a>
                    </div>
                </>
            </Modal>
        </>
    );
};

export default ListDirection;

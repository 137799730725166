import React from 'react'

const Taste = props => {
    return (
        <>
            {props.tastes?.map((taste => {
                return (
                    <button
                        type="button"
                        className="cuisine-btn"
                        onClick={() => props?.handleClickTaste(taste.id)}
                        key={taste.id}
                        style={taste.is_selected ? { background: '#85b492', color: "#fff" } : { background: '#FFF' }}
                    >
                        {taste?.name}
                    </button>

                )
            }))}
        </>
    )
}

export default Taste
import React from 'react';
import { notification } from 'antd';
import { submitContactForm } from '../apis'
import $ from 'jquery';
import { Navigate, Link } from "react-router-dom";
// import Link from

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.feedback_type = React.createRef();
        this.state = {
            fields: {},
            errors: {},
            isMessege: false,
            isLoading: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        var customSelect = $('select.custom-select');
        // FIRST, create the custom select menus from the existing select
        customSelect.each(function () {
            var that = $(this);
            var listID = that.attr('id'),
                groups = that.children('optgroup'),
                theOptions = "",
                startingOption = "",
                customSelect = "";

            //check if there are option groups 
            if (groups.length) {
                groups.each(function () {
                    var curGroup = $(this);
                    var curName = curGroup.attr('label');
                    //Open the option group
                    theOptions += '<li class="optgroup">' + curName + '</li>';
                    //get the options
                    curGroup.children('option').each(function () {
                        var curOpt = $(this);
                        var curVal = curOpt.attr('value'),
                            curHtml = curOpt.html(),
                            isSelected = curOpt.attr('defaultValue');
                        if (isSelected === 'selected') {
                            startingOption = curHtml;
                            theOptions += '<li class="selected" data-value="' + curVal + '">' + curHtml + '</li>';
                        } else {
                            theOptions += '<li data-value="' + curVal + '">' + curHtml + '</li>';
                        }
                    });
                    //Close the option group
                    //theOptions += '<li class="optgroup-close"></li>';
                });
                //add options not in a group to the top of the list
                that.children('option').each(function () {
                    var curOpt = $(this);
                    var curVal = curOpt.attr('value'),
                        curHtml = curOpt.html(),
                        isSelected = curOpt.attr('selected');
                    if (isSelected === 'selected') {
                        startingOption = curHtml;
                        theOptions = '<li class="selected" data-value="' + curVal + '">' + curHtml + '</li>' + theOptions;
                    } else {
                        theOptions = '<li data-value="' + curVal + '">' + curHtml + '</li>' + theOptions;
                    }
                });
            } else {
                that.children('option').each(function () {
                    var curOpt = $(this);
                    var curVal = curOpt.attr('value'),
                        curHtml = curOpt.html(),
                        isSelected = curOpt.attr('defaultValue');
                    if (curVal !== 'request_type') {
                        theOptions += '<li data-value="' + curVal + '">' + curHtml + '</li>';

                    }
                });
            }
            //build the custom select
            customSelect = '<div class="dropdown-container"><div class="dropdown-select entypo-down-open-big"><span>' + 'Request Type?' + '</span></div><ul class="dropdown-select-ul" data-role="' + listID + '">' + theOptions + '</ul></div> <!-- .custom-select-wrapper -->';
            //append it after the actual select
            $(customSelect).insertAfter(that);
        });

        var selectdd = $('.dropdown-select'),
            selectul = $('.dropdown-select-ul'),
            selectli = $('.dropdown-select-ul li');
        //THEN make them work
        selectdd.on('click', function () {
            $(this).parent('.dropdown-container').toggleClass('active');
        });
        //Hide it on mouseleave
        selectul.on('mouseleave', function () {
            $(this).parent('.dropdown-container').removeClass('active');
        });
        //select the option
        selectli.on('click', function () {
            var that = $(this);
            //ensure clicking group labels does not cause change
            if (!that.hasClass('optgroup')) {
                var parentUl = that.parent('ul'),
                    thisdd = parentUl.siblings('.dropdown-select'),
                    lihtml = that.html(),
                    livalue = that.attr('data-value'),
                    originalSelect = '#' + parentUl.attr('data-role');
                //close the dropdown
                parentUl.parent('.dropdown-container').toggleClass('active');
                //remove selected class from all list items
                that.siblings('li').removeClass('selected');
                //add .selected to clicked li
                that.addClass('selected');
                //set the value of the hidden input
                $(originalSelect).val(livalue);
                //change the dropdown text
                thisdd.children('span').html(lihtml);
            }
        });
        //Input label effects JS
        $("input").val("");

        $(".input-effect input").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });
        $(".input-effect textarea").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });
        $(".input-effect select").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });
    }

    handleChange(e) {
        let fields = this.state.fields;
        let errors = this.state.errors;
        fields[e.target.name] = e.target.value;
        errors[e.target.name] = "";
        this.setState({
            errors: errors,
            fields
        });
    }


    handleClick() {
        const userData = {
            'name': this.state.fields["name"],
            'email': this.state.fields["email"],
            'phone': this.state.fields["phone"],
            'description': this.state.fields["message"],
            'feedback_type': this.feedback_type.current.value,
            'created_by': ''
        }
        if (this.validateForm()) {
            this.setState({
                isLoading: true
            })
            document.getElementById("btn mt-1").disabled = true;
            submitContactForm(userData)
                .then(resp => {
                    notification.success({
                        message: 'Details Captured Successfully.',
                        description: resp.data.detail,
                        placement: 'bottomRight'
                    })
                    let isMessege = true;

                    this.setState({
                        isMessege
                    })
                })

                .catch(err => {
                    if (err?.response?.data?.detail) {
                        notification.error({
                            message: 'Submission failed',
                            description: err.response.data.detail,
                            placement: 'bottomRight'
                        })
                    }
                    else if (err?.response?.data?.email) {

                        notification.error({
                            message: 'Submission failed',
                            description: err.response.data.email,
                            placement: 'bottomRight'
                        })
                    }
                    else if (err?.response?.data?.feedback_type) {

                        notification.error({
                            message: 'Submission failed',
                            description: err.response.data.feedback_type,
                            placement: 'bottomRight'
                        })
                    }
                    this.setState({
                        isLoading: false
                    })
                    document.getElementById("btn mt-1").disabled = false;
                })
        }
    }
    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let isValid = true;
        let name = fields["name"]?.trim()

        if (!name) {
            isValid = false;
            errors["name"] = "*Please enter your name.";
        }
        else {
            // checking only alphabet chars are passed
            if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
                isValid = false;
                errors["name"] = "*Please enter alphabets only.";
            }

        }

        if (!fields["email"]) {
            isValid = false;
            errors["email"] = "*Please enter your email.";
        }

        // checking whather given email is valid or not
        else {
            //regex for email validation
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                isValid = false;
                errors["email"] = "*Please enter valid email.";
            }
        }

        //checking whather message give or not 
        if (!fields["message"]?.trim()) {
            isValid = false;
            errors["message"] = "*Please enter message.";
        }

        if(this.feedback_type.current.value==='request_type'){
            isValid = false;
            errors["request_type"] = "*Please select the request type.";
        }

        this.setState({
            errors: errors,
        })

        return isValid;
        // return true;
    }
    render() {
        let isMessege = this.state.isMessege;
        return (
            // <!--Banner container start-->
            <section className="banner-section contact-section">
                {isMessege && (<Navigate to="/thankYou" replace={true} />)}
                <div className="banner-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-9 col-xl-6 col-md-6 col-sm-9 align center ">
                                <h1 className="title-register"> <span>Contact</span> We would love to hear from you! </h1>
                                <p className='contact-p'>Have something to share? Fill out the form or email us at  </p>                                
                                <a href="mailto:contact@flavorable.io">contact@flavorable.io</a>
                                <div className="contact-form">
                                    <div className="row">
                                        <div className="col-12 col-xl-6 col-md-12 form-group">
                                            <div className="input-effect">
                                                <input className="effect" type="text" name="name" value={this.state.name} onChange={this.handleChange} autoComplete="off" />
                                                <label>Name</label>
                                                <span className='errorValidation'>{this.state.errors.name}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6 col-md-12 form-group">
                                            <div className="input-effect">
                                                <input className="effect" type="text" name="email" value={this.state.email} onChange={this.handleChange} autoComplete="off" />
                                                <label>Email</label>
                                                <span className='errorValidation'>{this.state.errors.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-xl-6 col-md-12 form-group">
                                            <div className="input-effect">
                                                <input className="effect" type="number" name="phone" value={this.state.phone} onChange={this.handleChange} autoComplete="off" />
                                                <label>Phone</label>
                                            </div>
                                        </div>

                                        <div className="col-12 col-xl-6 col-md-12 form-group">
                                            <div className="input-effect">
                                                <select className="custom-select effect" ref={this.feedback_type} id="select-tags" >
                                                    <option value="request_type" defaultValue >Request Type?</option>
                                                    <option value="create_ingredient">Add an Ingredient</option>
                                                    <option value="comment">Comment</option>
                                                    <option value="suggestion">Suggestion</option>
                                                    <option value="bug_report">Bug Report</option>
                                                    <option value="query">Query</option>
                                                </select>
                                                <span className='errorValidation'>{this.state.errors.request_type}</span>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-xl-12 col-md-12">
                                            <div className="input-effect">
                                                <textarea id='message' className="effect mb-5" type="text" name="message" value={this.state.message} onChange={this.handleChange}></textarea>
                                                <label>Message</label>
                                                <span className='errorValidation'>{this.state.errors.message}</span>
                                            </div>
                                            <button id="btn mt-1" onClick={this.handleClick} className="btn mt-1">Send
                                                {this.state.isLoading &&
                                                    <div className="spinner-border text-info" role="status">
                                                        <span className="visually-hidden"></span>
                                                    </div>}
                                                &nbsp;Message
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3 col-xl-6 col-md-6 col-sm-3">
                                <div className="contact-veg-bag"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}


export default Contact;
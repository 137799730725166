import React from 'react'
import { Link } from 'react-router-dom'


const RecipeTags=(props)=> {
  return (
    <>
    {props?.tags?.map((tag, index) => {
        return (    
            <Link to={`/recipes/${tag}`} className="btn" key={`${tag}-${index}`}  >
                {tag}
            </Link>
        )
    })}

</>
  )
}

export default RecipeTags
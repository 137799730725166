import React from "react";
import CircularSlider from "@fseehawer/react-circular-slider";

const InputCircularTime = (props) => {
  return (
    <>
      <CircularSlider
        width={150}
        label="HOURS"
        labelColor="#005a58"
        knobColor="#005a58"
        progressColorFrom="#00bfbd"
        progressColorTo="#009c9a"
        max={24}
        min={0} 
        dataIndex={
                 props.identifier === 'bake' ? props.bake_time?.hours : 
                  props.identifier === 'prep' ? props.prep_time?.hours :
                 props.identifier === 'rest' ? props.resting_time?.hours : 0     
                  }
        onChange={(value) => {
          props.handleChangeHours(value, props.identifier);
        }}
        knobSize={30}
        trackSize={5}
      />
      <CircularSlider
        width={150}
        label="MINUTES"
        labelColor="##00bfbd"
        knobColor="#00bfbd"
        progressColorFrom="#00bfbd"
        progressColorTo="#009c9a"
        max={59}
        min={0}
        dataIndex={
          props.identifier === 'bake' ? props.bake_time?.min : 
           props.identifier === 'prep' ? props.prep_time?.min :
          props.identifier === 'rest' ? props.resting_time?.min : 0     
           }
        onChange={(value) => {
          props.handleChangeMinutes(value, props.identifier);
        }}
        knobSize={30}
        trackSize={6}
      />
    </>
  );
};

export default InputCircularTime;

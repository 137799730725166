import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  listCommentsForRecipe,
  postCommentsForRecipe,
  retrieveRecipe,
  addRecipeToFavourite,
  addLikeToRecipe,
  removeLikeFromRecipe,
  getRecipes,
  followUnfollowUser,
  getRelatedRecipe,
} from "../../apis";
import "../../assets/css/list-recipes.css";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faFire,
  faHeart,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import RecipeComments from "../components/recipe_comment";
import ReactStars from "react-rating-stars-component";
import { notification } from "antd";
import ReactHtmlParser from "react-html-parser";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import RecipeTags from "../components/recipe_tags";
import RecentRecipes from "../components/recent_recipes";
import Recipee from "../components/Recipe";
import Loading from "../components/Loading";


// adding the setting to the related recipes
const related_recipes_slider_settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  autoPlay: true,
  pauseOnHover: true,
  lazyLoad: "progressive",
  arrows: true,
  dots: false,
  infinite: true,
  focusOnSelect: true,
  responsive: [
    ,
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      },
    },
    ,
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const image_slider_settings =  {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  autoplay: true,
  arrows:false,
  pauseOnHover: true
};

const Recipe = (props) => {
  const params = useParams();
  const [current_recipe_data, setCurrentRecipeData] = useState({});
  const [user_entered_comment, setUserEnterdComment] = useState("");
  const [current_recipe_list_comment, setCurrentRecipeListComment] = useState(
    {}
  );
  const [error, setError] = useState({});
  const [user_entered_stars, setUserEnteredStarts] = useState(0);
  const [is_loading, setIsLoading] = useState(false);
  const [recent_recipes, setRecentRecipes] = useState([]);
  const [related_recipes, setRelatedRecpes] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentRecipeData({});
    retrieveRecipe(params.slug)
      .then((resp) => {
          getRelatedRecipe(resp.data.results.id)
          .then((resp) => {
            console.log("this are the related recipes", resp);
            setRelatedRecpes(resp.data);
          })
          .catch((err) => {
            console.log(
              "this is the error while fetching the related recipes",
              err.response
            );
          });
        setCurrentRecipeData(resp.data);
      })
      .catch((err) => {
        console.log("this is the error for the retrieve recipes", err.response);
      });

    listCommentsForRecipe(params.slug)
      .then((resp) => {
        console.log(
          "this is the response for the list comments for the recipes",
          resp
        );
        setCurrentRecipeListComment(resp);
      })
      .catch((err) => {
        console.log("this is the error for the list comments".err.response);
      });

    // to get the recent recipes
    const page_no = 1;
    getRecipes(page_no)
      .then((resp) => {
        let recent = resp.data.results.slice(0, 5);
        setRecentRecipes(recent);
      })

      .catch((err) => {
        console.log(
          "some error occurd while fetching the recent recipes ",
          err.response
        );
      });
  }, [params.slug]);

  const handleChangeComment = (event) => {
    setUserEnterdComment(event.target.value);
    setError({ ...error, comment: "" });
  };

  const handleClickPostRecipeComment = (event, comment_id) => {
    event.preventDefault();
    if (isValidated()) {
      setIsLoading(true);
      let post_comment = {
        star_rating: user_entered_stars,
        review: user_entered_comment.trim(),
      };

      postCommentsForRecipe(params.slug, post_comment)
        .then((resp) => {
          notification.success({
            message: "You have succesfully commented",
            placement: "bottomRight",
          });
          setUserEnterdComment("");
          setUserEnteredStarts(0);
          setIsLoading(false);
          listCommentsForRecipe(params.slug)
            .then((resp) => {
              setCurrentRecipeListComment(resp);
            })
            .catch((err) => {
              console.log(
                "this is the error for the list comments",
                err.response
              );
              setIsLoading(false);
            });
        })

        .catch((err) => {
          console.log(
            "this is the error in the post comment api",
            err.response
          );
        });
    }
  };

  const isValidated = () => {
    let error = {};
    let is_validate = true;
    if (!user_entered_comment.trim()) {
      error.comment = "Please enter the comment";
      is_validate = false;
    }
    if (!user_entered_stars) {
      error.rating = "Please rate the recipe";
      is_validate = false;
    }
    setError(error);
    return is_validate;
  };

  const handleClickFavourite = (e) => {
    e.preventDefault();
    let recipe_id = { recipe_id: current_recipe_data.results.id };

    addRecipeToFavourite(recipe_id)
      .then((resp) => {
        notification.success({
          message: resp.data.results.detail,
          placement: "bottomRight",
        });

        retrieveRecipe(params.slug)
          .then((resp) => {
            setCurrentRecipeData(resp.data);
          })
          .catch((err) => {
            console.log(
              "this is the error for the retrieve recipes",
              err.response
            );
          });
      })

      .catch((err) => {
        console.log("this is the error for the favourite recipe", err.response);
      });
  };

  const handleClickLike = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (current_recipe_data.results.user_has_liked) {
      removeLikeFromRecipe(params.slug)
        .then((resp) => {
          notification.success({
            message: "Like is removed",
            placement: "bottomRight",
          });
          let new_data = {
            count: current_recipe_data?.count,
            next: current_recipe_data?.next,
            previous: current_recipe_data?.previous,
            results: resp.data,
          };
          setCurrentRecipeData(new_data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(
            "an error occured while remove like to the recipe",
            err.response
          );
          setIsLoading(false);
        });
    } else {
      addLikeToRecipe(params.slug)
        .then((resp) => {
          notification.success({
            message: "Like is added",
            placement: "bottomRight",
          });
          let new_data = {
            count: current_recipe_data?.count,
            next: current_recipe_data?.next,
            previous: current_recipe_data?.previous,
            results: resp.data,
          };
          setCurrentRecipeData(new_data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(
            "an error occured while adding like to the recipe",
            err.response
          );
          setIsLoading(false);
        });
    }
  };

  const handleFollowUnfollowUser = (e) => {
    e.preventDefault();
    const user_id = { user_id: current_recipe_data.results.author_id };
    followUnfollowUser(user_id)
      .then((resp) => {
        notification.success({
          message: resp.data.results.detail,
          placement: "bottomRight",
        });
        retrieveRecipe(params.slug)
        .then((resp) => {
          setCurrentRecipeData(resp.data);
        })
        .catch((err) => {
          console.log(
            "this is the error for the retrieve recipes",
            err.response
          );
        });
      })
      .catch((err) => {
        console.log("some error occured while follwing the user", err.response);
      });
  };

  const currunt_blog_url = window.location.href;
  return (
    <>
    {! current_recipe_data?.results ?
   ( <>
   <Loading
   />
    </>):
   ( <>
      <section className="single-blog-section">
        <div
          className="banner-content "
          style={{
            backgroundImage: `url(${current_recipe_data?.results?.image_1}) `,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "400px",
          }}
        >
          <div className="mt-5" style={{ float: "right" }}>
            <h1 className="title like-heart-icon d-flex  mr-5">
              <div
                className="heart-create-recipe-thumbnail-bg mr-5"
                onClick={handleClickFavourite}
              >
                <FontAwesomeIcon
                  icon={faHeart}
                  size={"xs"}
                  className="mr-5"
                  color={
                    current_recipe_data?.results?.is_favourite ? "red" : "#000"
                  }
                  title="Add to favourite"
                />{" "}
                {is_loading && (
                  <div class="spinner-border text-dark" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                )}
              </div>
              <div className="thumbs-up" onClick={handleClickLike}>
                <FontAwesomeIcon
                  icon={faThumbsUp}
                  size={"xs"}
                  className="mr-4"
                  color={
                    current_recipe_data?.results?.user_has_liked
                      ? "#85b492"
                      : "#000"
                  }
                  title={
                    current_recipe_data?.results?.user_has_liked
                      ? "Remove Like"
                      : "Add Like"
                  }
                />
                {/* {is_loading && (
                  <div class="spinner-border text-dark" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                )} */}
                <span
                  className="d-flex align-items-center"
                  style={
                    current_recipe_data?.results?.user_has_liked
                      ? { color: "#85b492", margin: "0" }
                      : { color: "#000", margin: "0" }
                  }
                >
                  {current_recipe_data?.results?.likes_count}
                </span>
              </div>
            </h1>
          </div>
        </div>
      </section>

      <div className="blog-submenu">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-12 col-md-12 col-lg-10">
              <span className="recipe-heading-name">
                {current_recipe_data?.results?.name_of_recipe}
              </span>
              <strong style={{ color: "black" }}>&nbsp; | &nbsp;</strong>
              <a onClick={handleFollowUnfollowUser}>
                {current_recipe_data?.results?.is_author_followed ? (
                  <span className="follow-link">UnFollow</span>
                ) : (
                  <span className="follow-link">Follow</span>
                )}
              </a>
              <span className="auther-by" style={{ float: "right" }}>
                by: {current_recipe_data?.results?.author} {""}
              </span>
              <span
                style={{ fontSize: "14px", color: "black", fontWeight: "600" }}
              >
                {/* {this.state?.currentBlog?.title?.toUpperCase()} */}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <!--Sub Menu end-->  */}
      {/* <!--Mid Section start--> */}
      <section className="mid-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-9 col-md-12">
              <div className="blog-sin-lft">
                <div className="row">
                  <div className="col-xl-10 col-10">
                    <span className="share-blog-links">
                      {" "}
                      <strong>Share</strong>
                      <ul className="social blog-share">
                        <li style={{ listStyle: "none" }}>
                          <b className="share-icon"></b>{" "}
                        </li>
                        <li style={{ listStyle: "none" }}>
                          <FacebookShareButton url={currunt_blog_url}>
                            <li>
                              <Link to={""} className="fb-icon"></Link>
                            </li>
                          </FacebookShareButton>
                        </li>
                        <li style={{ listStyle: "none" }}>
                          <TwitterShareButton url={currunt_blog_url}>
                            <li>
                              <Link to={""} className="twitter-icon"></Link>
                            </li>
                          </TwitterShareButton>
                        </li>
                        <li style={{ listStyle: "none" }}>
                          <WhatsappShareButton url={currunt_blog_url}>
                            <li>
                              <Link to={""} className="what-icon"></Link>
                            </li>
                          </WhatsappShareButton>
                        </li>
                        <li style={{ listStyle: "none" }}>
                          <LinkedinShareButton url={currunt_blog_url}>
                            <li>
                              <Link to={""} className="link-icon"></Link>
                            </li>
                          </LinkedinShareButton>
                        </li>
                      </ul>
                    </span>
                    {/* <div className="blog-content-area">
                      {ReactHtmlParser(this.state?.currentBlog?.content)}
                    </div> */}
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-12">
                    <div className="author-box d-flex justify-content-center align-item-center">
                      <span className="author-img">
                        {this.state?.currentBlog?.author?.profile_image ? (
                          <img
                            src={this.state.currentBlog.author.profile_image}
                            alt=""
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={default_person}
                            alt=""
                            className="img-fluid"
                          />
                        )}
                      </span>
                      <div className="author-content d-flex align-items-center">
                        <h4>{this.state?.currentBlog?.author?.username}</h4>
                        <p>{this.state?.currentBlog?.author.bio} </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row d-flex justify-content-xl-start justify-content-center ">
                <div className="col-11 col-lg-9 col-md-12 calc-time">
                  <span className="ml-5">
                    <FontAwesomeIcon
                      icon={faClock}
                      size={"xl"}
                      color="#85b492"
                    />{" "}
                    Prep time: {current_recipe_data?.results?.prep_time}{" "}
                  </span>
                  <span className="ml-5">
                    <FontAwesomeIcon
                      icon={faFire}
                      size={"xl"}
                      color="#85b492"
                    />{" "}
                    Calories: {current_recipe_data?.results?.calories}
                  </span>
                </div>
              </div>
              <div className="row d-flex justify-content-xl-start justify-content-center">
                <div className="col-11 col-lg-9 col-md-12 mt-5 mb-5 calc-time d-flex justify-content-around">
                  <span className="dot">
                    <span className="energy-btn d-flex flex-column">
                      <span className="circle-with-nos">
                        {" "}
                        {current_recipe_data?.results?.nutrition?.calories} K
                        Energy
                      </span>
                    </span>
                  </span>
                  <span className="dot">
                    <span className="energy-btn d-flex flex-column">
                      <span className="circle-with-nos">
                        {" "}
                        {current_recipe_data?.results?.nutrition?.protein} g
                        protein
                      </span>
                    </span>
                  </span>
                  <span className="dot d-flex justify-content-center">
                    <span className="energy-btn d-flex flex-column">
                      <span className="circle-with-nos">
                        {current_recipe_data?.results?.nutrition?.carbs} g Carbs
                      </span>{" "}
                    </span>
                  </span>
                  <span className="dot d-flex justify-content-center">
                    <span className="energy-btn circle-with-fat-no d-flex flex-column">
                      <span className="circle-with-nos">
                        {current_recipe_data?.results?.nutrition?.fat} g Fats
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              {current_recipe_data?.results?.servings && 
              <div className="row d-flex justify-content-xl-start justify-content-center">
                <div className="col-xl-9 col-11 col-lg-9 col-md-12 calc-time">
                  Servings: {current_recipe_data?.results?.servings}
                </div>
              </div>}
              {current_recipe_data?.results?.image_1 &&
              <div className="row d-flex justify-content-xl-start justify-content-center">
                <div className="col-xl-9 col-11 col-lg-9 col-md-12 mt-5 calc-time">
                  <>
                    <Slider
                   { ...image_slider_settings}
                    >
                      {current_recipe_data?.results?.image_1 && (
                        <img src={current_recipe_data?.results?.image_1} />
                        )}

                      {current_recipe_data?.results?.image_2 && (
                        <img src={current_recipe_data?.results?.image_2} />
                      )}
                      {current_recipe_data?.results?.image_3 && (
                        <img src={current_recipe_data?.results?.image_3} />
                      )}
                      {current_recipe_data?.results?.image_4 && (
                        <img src={current_recipe_data?.results?.image_4} />
                      )}
                      {current_recipe_data?.results?.image_5 && (
                        <img src={current_recipe_data?.results?.image_5} />
                      )}
                    </Slider>
                  </>
                </div>
              </div>}
             {current_recipe_data?.results?.ingredients?.length && <div className="row d-flex justify-content-xl-start justify-content-center">
                <div className="col-xl-9 col-11 col-lg-9 col-md-12 mt-5 mb-5 calc-time">
                  <div className="add-ingredient-heading">
                    <span> Added ingreadients</span>
                  </div>
                  
                  <ol>
                    {current_recipe_data?.results?.ingredients?.map(
                      (ingredient) => {
                        return (
                          <React.Fragment key={ingredient.id}>
                            <li data-list-index={`*`} className="d-flex mt-5">
                              <p className="ingredient-name-in-list-1">
                                {ingredient?.name}
                              </p>
                              <p className="ingredient-name-in-list">
                                {ingredient?.amount} {" "}
                                {ingredient?.unit}
                              </p>
                              {/* <p className="ingredient-name-in-list">
                                
                              </p> */}
                              <img
                                className="modal-ing-img"
                                src={ingredient?.image}
                                alt="ingredient"
                              />
                            </li>
                            <hr></hr>
                          </React.Fragment>
                        );
                      }
                    )}
                  </ol>
                </div>
              </div>}
              {current_recipe_data?.results?.recipe_video && (
                <div className="row d-flex justify-content-xl-start justify-content-center">
                  <div className="col-xl-9 col-11 col-lg-9 col-md-12  justify-content-center calc-time">
                    {/* <span className="recipe-name play-video">Video:</span> */}
                    <video
                      className="VideoInput_video video-play"
                      style={{width:'600'}}
                      // style={{position:'relative'}}
                      // width={500}
                      // height={500}
                      controls
                      src={current_recipe_data?.results?.recipe_video}
                    />
                  </div>
                </div>
              )}
              {current_recipe_data?.results?.direction?.length &&
              <div className="row d-flex justify-content-xl-start justify-content-center">
                <div className="col-xl-9 col-11 col-lg-9 col-md-12  calc-time">
                  <div className="add-ingredient-heading">
                    <span> Added Directions</span>
                  </div>
                  <ol className="mt-5 col-xl-12">
                    {current_recipe_data?.results?.direction?.map(
                      (direction, index) => {
                        return (
                          <React.Fragment key={index}>
                            <li data-list-index={`*`} className="nos-color">
                              <p className="ingredient-name-in-list">
                                {direction}
                              </p>
                            </li>
                          </React.Fragment>
                        );
                      }
                    )}
                  </ol>
                </div>
              </div>}

             { current_recipe_data?.results?.chef_note&&
             <div className="row d-flex justify-content-xl-start justify-content-center">
                <div className="col-xl-9 col-11 col-lg-9 col-md-12  calc-time">
                  <div className="add-ingredient-heading">
                    <span> Chefs Note:</span>
                  </div>
                  {ReactHtmlParser(current_recipe_data?.results?.chef_note)}
                </div>
              </div>}
            </div>
            <div className="col-12 col-lg-3 col-md-12">
              <div className="react-post">
                <h4 className="recent-posts">Recent Recipes</h4>
                {recent_recipes?.map((recipe) => (
                  <RecentRecipes
                    key={recipe?.id}
                    title={recipe.name_of_recipe}
                    image={recipe?.image_1}
                    tags={recipe?.tags}
                    date={recipe?.created_at}
                    id={recipe?.id}
                    slug={recipe.slug}
                    // handleClickRecentPost={(blog_id) =>
                    //   this.handleClickRecentPost(blog_id)
                    // }
                  />
                ))}
              </div>
              <div className="newsletter-box">
                <h4 style={{ fontSize: 24 }}>Newsletter</h4>
                <div className="input-group search-box">
                  <div className="input-effect">
                    <input
                      className="effect"
                      name="newsLetterEmail"
                      // value={this.state?.newsLetterFields["newsLetterEmail"]}
                      placeholder="Email"
                      // onChange={this.handleChangeNewsLetter}
                      autoComplete="off"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <button
                        id="btnnl"
                        className="btn"
                        type="button"
                        // onClick={this.handleClickNewsLetter}
                      >
                        {/* {this.state.isLoading && (
                          <div
                            className="spinner-border text-info"
                            role="status"
                          >
                            <span className="visually-hidden"></span>
                          </div>
                        )} */}
                      </button>
                    </div>
                    <span className="errorValidation">
                      {/* {this.state?.newsLetterError?.newsLetterErrorEmail} */}
                    </span>
                    <span className="focus-border"></span>
                  </div>
                </div>
              </div>
              <div className="tags-box col-11 col-md-12 col-lg-12">
                <h4 style={{ fontSize: 24 }}>Tags</h4>
                <RecipeTags tags={current_recipe_data?.results?.tags} />
              </div>
            </div>
          </div>
        </div>
      </section>
    { related_recipes?.length && <section className="blog-slider-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 style={{ fontSize: 35 }}>Related</h2>
            </div>
          </div>
          <Slider {...related_recipes_slider_settings}>
            {related_recipes?.map((recipe,index) => (
              <Recipee
                key={recipe?.id}
                title={recipe.name_of_recipe}
                image={recipe?.image_1}
                tags={recipe?.tags}
                date={recipe?.created_at}
                id={recipe?.id}
                slug={recipe.slug}
              />
            ))}
          </Slider>
        </div>
      </section>}
      {/* <!--blog-slider-section end-->  */}

      {/* <!--Commnet Section start--> */}
      <section className="commnet-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="comment-area">
                <h2 className="b-comment">
                  Comments ({current_recipe_list_comment?.data?.count})
                </h2>
                <div className="commnet-box">
                  <RecipeComments
                    comments={current_recipe_list_comment?.data?.results}
                  />
                </div>
              </div>
              <div className="contact-form">
                <div className="row">
                  <div className="col-12">
                    <h2 style={{ fontSize: 35, fontWeight: 500 }}>
                      Leave a reply
                    </h2>
                  </div>
                </div>
                <>
                  <p className="mb-5" style={{ color: "black" }}>
                    Leave your comment below.
                  </p>
                  <div className="col-12 col-xl-12 col-md-12">
                    <div className="input-effect">
                      <textarea
                        className="effect"
                        id="message"
                        type="text"
                        name="comment"
                        value={user_entered_comment}
                        onChange={handleChangeComment}
                        autoComplete="off"
                      ></textarea>
                      <label>Your comment</label>
                      <span className="errorValidation">{error?.comment}</span>
                    </div>
                    <div className="input-effect">
                      <label>Rate the recipe </label>
                      <>
                        <ReactStars
                          size={30}
                          value={user_entered_stars}
                          onChange={(newValue) => {
                            setUserEnteredStarts(newValue);
                            setError({ ...error, rating: "" });
                            // setError({ ...error, comment: "" });
                          }}
                          count={5}
                          activeColor="#ffd700"
                        />
                      </>
                      <span className="errorValidation">{error?.rating}</span>
                    </div>
                    <button
                      id="btnc"
                      onClick={handleClickPostRecipeComment}
                      className="btn "
                    >
                      Post
                      {is_loading && (
                        <div className="spinner-border text-info" role="status">
                          <span className="visually-hidden"></span>
                        </div>
                      )}
                      &nbsp; Comment
                    </button>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>)}
  </>
  );
};

export default Recipe;

import React, { useEffect } from 'react'
import default_person from "../../assets/images/default_person.png";
import $ from 'jquery';
import Moment from 'react-moment';
import ReactStars from "react-rating-stars-component";

const RecipeComments=(props)=> {

    useEffect(()=>
    {
        //Input label effects JS
        $("input").val("");

        $(".input-effect input").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });
        $(".input-effect textarea").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });
        $(".input-effect select").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });

    },[])
            
        return (
            <>
                {props?.comments?.map((comment) => (
                    <React.Fragment key={comment?.id}>
                        <div className="author-box" >
                            <div className="profile-box">
                                <div className="author-content">
                                    <h4 className='p-0'  >
                                        <strong>{comment?.author}</strong>
                                        <span className="date">
                                            {<Moment format='MMM D, YYYY'>{comment?.created_at}</Moment>}
                                        </span>
                                    </h4>
                                </div>
                            </div>
                            <p className=" p-comments">{comment?.review} </p>
                            <ReactStars
                            size={30}
                            value={comment.star_rating}
                            count={5}
                            activeColor="#ffd700"
                            edit={false}
                            />
                        </div>
                        
                    </React.Fragment>
                )

                )}
            </>
        )
    }


export default RecipeComments;
import { React } from "react";
import { Link, NavLink } from "react-router-dom";
import Moment from "react-moment";
import TagBlogs from "./TagBlogs";
import LazyLoad from "react-lazyload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const Recipe = (props) => {
  return (
    <>
      <Link to={`/recipe/${props?.slug}`} className="blog-box">
        {/* <Link to={`/edit-recipe-step-1/${props?.slug}`} className="blog-box"> */}
        <span className="blog-thumbnail">
          {props?.image && (
            // <LazyLoad>
            <img src={props?.image} alt="" className="img-fluid" />
            // </LazyLoad>
          )}
        </span>
        <h2 className="title">
          <span>
            <Moment format="MMM D, YYYY">{props?.date}</Moment>
             { props.identifer === 'my-workspace' &&
            <>
             <FontAwesomeIcon
                className="trashIcon"
                icon={faEllipsisVertical}
                size="2x"
                transform="shrink-6"
                color="#85b492"
                style={{ float: "right" }}
                />
        <NavLink to={`/edit-recipe-step-1/${props?.slug}`} className="editWrapper">
              <div className={ "edit-drope-down" }>
              <span> <FontAwesomeIcon
                className="trashIcon"
                icon={faPenToSquare}
                size="2x"
                transform="shrink-6"
                color="#85b492"
                // style={{ float: "right" }}
                /> Edit </span>
              </div>
            </NavLink>
            </>}
          </span>
          {props?.title}{" "}
        </h2>
        <div className="discover">
          <TagBlogs tags={props?.tags} />
        </div>
      </Link>
    </>
  );
};

export default Recipe;

import React from 'react';

import cooking_video from "../assets/images/cooking-video.mp4"


class Thankyou extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <>
                <section className="banner-section contact-section">
                    <div className="banner-content thank-you-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-xl-11 col-md-12 ">
                                    <h1 className="title-register " style={{ textAlign: "center" }}>Thank you! </h1>
                                    <p>We’re thrilled to hear from you. We will be back in touch with you soon. In the meanwhile, download the app for more mouth-watering recipes. </p>
                                    <div className="video-box">
                                        <video className="video-fluid z-depth-1" autoPlay loop controls muted>
                                            <source src={cooking_video} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>

        )
    }
}


export default Thankyou;

import React, { Component } from 'react';

import { ThreeDots } from "react-loader-spinner";

class Loading extends Component {
    render() {
        return (
            <>
                <div className="banner-content contact-section">
                    <div className="container">
                        <div className="spinner">
                            <ThreeDots
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }

}
export default Loading;



import React, { Component } from 'react'
import Modal from 'react-modal';

export default class ForgotPasswordModal extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false
        };
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }


    handleOpenModal() {
        this.setState({ showModal: true });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    called() {
        console.log("this function is called")

    }


    render() {
        return (
            <> <div className="custom-control custom-checkbox">
                Forget Password? <a onClick={this.handleOpenModal} style={{ color: "#85b492" }}>Click here.</a>
            </div>
                <Modal
                    isOpen={this.state.showModal}
                    onRequestClose={this.called}
                    className="forgotPasswordModal password-forgot-modal"
                    overlayClassName="forgotPasswordOverlay"
                    closeTimeoutMS={500}
                    ariaHideApp={false}
                >
                    <>
                        <div style={{ textAlign: "center" }}>
                            <span >Having trouble signing in?</span>
                        </div>
                        <div className="col-12 mt-4 form-group">
                            <div className="input-effect">
                                <input className="effect" type="text" name="email" value={this?.props?.email} onChange={this?.props?.handleChange} autoComplete="off" />
                                <span className='errorValidation'>{this.props.errorEmail}</span>
                            </div>
                        </div>
                        <div style={{ textAlign: "center" }}>Please click on the button below to send the magic link to your email</div>
                        <div className="col-12 col-xl-12 col-md-12 mt-3 text-center">
                            <a onClick={this.props.handleClickMagicLink} className="btn">Send login link</a>
                        </div>
                        <div className="col-12 col-xl-12 col-md-12">
                            <div className="login-other">
                                <h3><strong > Or reset your password</strong></h3>
                            </div>
                        </div>
                        <div style={{ textAlign: "center" }}>Please click on the button below to send the reset passsword link to your email</div>
                        <div className="col-12 col-xl-12 col-md-12 mt-3 text-center">
                            <a onClick={this.props.handleClickResetPassword} className="btn">Reset password</a>
                        </div>
                    </>
                </Modal>
            </>
        )
    }
}

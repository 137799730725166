import React from 'react';
import Slider from 'react-slick';

import { Link } from 'react-router-dom';


//importing images
import dish_1 from '../assets/images/banner-dish-1.png';
import dish_2 from '../assets/images/banner-dish-2.png';
import thumb_1 from '../assets/images/banner-thumb-1.png';
import thumb_2 from '../assets/images/banner-thumb-2.png';
import thumb_3 from '../assets/images/banner-thumb-3.png';
import thumb_4 from '../assets/images/banner-thumb-4.png';
import mobile_screen from '../assets/images/mobile-screen.png';
import img_1 from '../assets/images/recipes-img-1.png';
import img_2 from '../assets/images/recipes-img-2.png';
import WatchTutorialModal from './components/WatchTutorialModal';
import playstore from '../assets/images/play-store-qr-code.png';
import Detect from "detect.js"



class Landing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dish: null,
            dish_nav: null,
            isDesktop: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({
            dish: this.dish_slider,
            dish_nav: this.dish_nav_slider
        });
        var ua = Detect.parse(navigator.userAgent);

        this.setState({
            isDesktop: ua.device.type === "Desktop" ? true : false
        })
    }

    render() {
        const dish_slider_settings = {
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            speed: 3000,
            dots: false,
            infinite: false,
            // asNavFor: '.dish__nav'
        };

        const dish_nav_settings = {
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            infinite: true,
            focusOnSelect: true,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            }, {
                breakpoint: 800,
                settings: {
                    slidesToShow: 4
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4
                }
            }, {
                breakpoint: 500,
                settings: {
                    slidesToShow: 4
                }
            },],
            // asNavFor: '.dish__slider',
        }

        const recipes_slider_settings = {
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: true,
            speed: 4000,
            infinite: true,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
        }

        const testimonials_slider_settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 3000,
            arrows: true,
            centerpadding: 10,
            speed: 3000,
        }
        return (
            <div>
                {/* // <!--Banner section Start--> */}
                <section className="banner-section-landing-page ">
                    <div className="banner-content banner-section-backgroung-img">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-xl-8 col-md-12 col-lg-6">
                                    <h1 className="title title-fold-1-landing">  Revolutionise Your Meals </h1>
                                    <p className='p-landing'>Flavorable allows you to create and share shopping lists, <br />keep a track of your health, and learn from budding chefs in your community. </p>
                                    <ul className="ul-btn">
                                        {this.state.isDesktop ?
                                            (<li className="btn">
                                                <a >
                                                    <i className="fab fa-google-play"></i> <span>GET IT ON <strong>Google Play</strong> </span>
                                                    <span className="tooltip google-btn">
                                                        <img src={playstore} alt="" />
                                                    </span>
                                                </a>
                                            </li>) :
                                            (
                                                <a href="https://play.google.com/store/apps/details?id=com.neevista.foodapp" target="_blank">
                                                    <li className="btn">
                                                        <i className="fab fa-google-play"></i>
                                                        <span>GET IT ON <strong>Google Play</strong></span>
                                                    </li>
                                                </a>
                                            )
                                        }

                                        {this.state.isDesktop ?
                                            (<li className="btn">
                                                <a >
                                                    <i className="fab fa-apple"></i> <span>Download on the <strong>App Store</strong> </span>
                                                    <span className="tooltip google-btn">
                                                        <img src={playstore} alt="" />
                                                    </span>
                                                </a>
                                            </li>) :
                                            (
                                                <a href="https://play.google.com/store/apps/details?id=com.neevista.foodapp" target="_blank">
                                                    <li className="btn">
                                                        <i className="fab fa-apple"></i> <span>Download on the <strong>App Store</strong> </span>
                                                    </li>
                                                </a>
                                            )
                                        }
                                        <WatchTutorialModal />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="banner-slider">
                            <div className="dish__item"> <span className="like-spe">

                                <a className="green-circle speak-icon">
                                    <span className="tooltip"><strong>Live</strong> Session</span></a>
                                <a className="cook-dish"><i></i>Cook this dish</a>
                                <a className="green-circle like-icon"><span className="tooltip"><strong>Favourites</strong> Pad Thai</span></a>
                            </span>
                                <Slider
                                    asNavFor={this.state.dish_nav}
                                    ref={slider => (this.dish_slider = slider)}
                                    className="dish__slider"
                                    {...dish_slider_settings}>
                                    <div className="item">
                                        <img src={dish_1} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={dish_2} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={dish_1} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={dish_2} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={dish_1} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={dish_2} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={dish_1} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={dish_2} alt="" />
                                    </div>
                                </Slider>
                                <Slider
                                    asNavFor={this.state.dish}
                                    ref={slider => (this.dish_nav_slider = slider)}
                                    className="dish__nav"
                                    {...dish_nav_settings}>
                                    <div className="item">
                                        <img src={thumb_1} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={thumb_2} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={thumb_3} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={thumb_4} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={thumb_1} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={thumb_2} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={thumb_3} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={thumb_4} alt="" />
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div >
                </section >
                {/* <!--Banner container end-->  */}

                {/* // <!--Live Stream Section start--> */}
                <section className="live-stream-section">
                    <div className="live-device-area">
                        <div className="live-device-box">
                            <div className="live-device-screen"> <span className="screen-video">
                                <img src={mobile_screen} alt="" />
                                <a ><i className="fas fa-play"></i></a> </span>
                                <div className="screen-content">
                                    <h2 className="title"> <span>Mar 6, 2022 | 13:00 AUT</span>How to make beef Soup <a className="upload-icon" ></a> </h2>
                                    <p style={{ fontSize: "15px" }}>This simple yet delicious beef soup is the perfect way to introduce summer veggies into your diet. Top it off with tender beef chunks to add a delicious flavor. This hearty soup will surely keep you craving for more.</p>
                                    <ul className="com-ul" style={{ fontSize: "15px" }}>
                                        <li>2 lbs of tender beef meat </li>
                                        <li>1 cup of yellow onion [chopped]</li>
                                        <li>5 cups of beef broth </li>
                                    </ul>
                                </div>
                                <a className="speak-icon green-circle"> <b className="notification">1</b> <span className="tooltip left"><strong>Live</strong> Session</span></a> <a className="scr-ply-btn green-circle">
                                    <span className="tooltip"><strong>Story</strong>Post A Story</span>
                                </a> </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-xl-7 col-md-12 col-lg-7">&nbsp;</div>
                            <div className="col-12 col-xl-5 col-md-12 col-lg-5">
                                <h2 className="title title-fold-1-landing"> <span>Features</span>Live Streaming </h2>
                                <p className='p-landing'>Share your passion for cooking with the world! Host virtual cooking sessions. <br />
                                    The app allows you to be a part of a like-minded community. You can share your recipes with others, attend virtual cooking sessions or even host your own sessions. </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!--Live Stream Section  end--> */}

                {/* <!--Allergy Alert & Recipes Section Start--> */}
                <section className="allergy-section">
                    <div className="container mob-width">
                        <div className="row">
                            <div className="col-12 col-xl-5 col-md-12 col-lg-5">
                                <h2 className="title title-fold-1-landing allergy-alert-recipes"> <span>Allergy Alert & Recipes</span>Customised Recipes </h2>
                                <p className='p-landing allergy-alert-recipes-paragraph'>Find ingredients that are just right for you with our AI power allergen detection feature.
                                    <br />  Our app suggests recipes based on your preferences,
                                    keeping your dietary and lifestyle choices in mind.
                                    Whether you're following a vegan, paleo, or keto diet, there is a recipe for you. </p>

                            </div>
                        </div>
                    </div>
                    <div className="recipes-slider-area"> <a className="green-circle bell-icon"> <b
                        className="notification">1</b> <span className="tooltip"><strong className="red-txt">Allergy Alert</strong>Green
                            peas</span></a> <span className="like-spe">
                            <a className="green-circle like-icon"> <span className="tooltip"><strong>Recipe</strong>Pad
                                Thai</span></a>
                            <a className="green-circle gallery-icon"><span className="tooltip"><strong>Add
                                Breakfast</strong>Rec 293 kcal</span></a> </span>
                        <Slider className="recipes-slider" {...recipes_slider_settings}>
                            <div>
                                <img src={img_1} alt="" />
                            </div>
                            <div>
                                <img src={img_2} alt="" />
                            </div>
                            <div>
                                <img src={img_1} alt="" />
                            </div>
                            <div>
                                <img src={img_2} alt="" />
                            </div>
                        </Slider>

                    </div>
                </section>
                {/* <!--Allergy Alert & Recipes Section end--> */}

                {/* <!--Meal Planner Section start--> */}
                <section className="meal-plan-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-xl-6 col-md-12 col-lg-6">&nbsp;</div>
                            <div className="col-12 col-xl-6 col-md-12 col-lg-6">
                                <h2 className="title title-fold-1-landing"> <span>Features</span>Meal Planning
                                </h2>
                                <p className='p-landing'>Recipes that make it easy for you to follow a healthy diet.
                                    We're all about getting your desired meal on the table fast and easy.
                                    Explore recipes by cuisine, indulge in a symphony of flavors from different parts of the world. </p>
                            </div>
                        </div>
                    </div>
                    <div className="shopping-bag"> <span className="shop-list">
                        <a className="green-circle list-icon"><b className="notification">1</b>
                            <span className="tooltip listtip">
                                <h3>Meal Planner</h3>
                                <ul className="ul-tooltip">
                                    <p>Mon 02/11/22</p>
                                    <li>Bolognese Pasta <strong>Lunch</strong></li>
                                    <li>Rib eye Steak <strong>Dinner</strong></li>
                                </ul>
                                <ul className="ul-tooltip">
                                    <p>Mon 02/11/22</p>
                                    <li>Bolognese Pasta <strong>Lunch</strong></li>
                                    <li>Rib eye Steak <strong>Dinner</strong></li>
                                </ul>
                                <p>
                                    <strong className="add-icon green-circle">
                                        {/* <!--<i className="fa fa-plus"></i>--> */}
                                    </strong>
                                </p>
                            </span>


                        </a >
                        <a className="green-circle delivery-icon"><span
                            className="tooltip left"><strong>Delivery</strong>At your door</span></a>
                        <a className="green-circle add-icon">
                            <span className="tooltip listtip shopping">
                                <h3>Shopping list</h3>
                                <ul className="ul-tooltip">
                                    <h3>Bolognese Pasta</h3>
                                    <li>Pasta <strong>500g</strong></li>
                                    <li>Tomato Sauce <strong>250g</strong></li>
                                    <li>Ground beef <strong>1/4kg</strong></li>
                                    <li>garlic <strong>2pc</strong></li>
                                    <li>Sea Salt <strong>1c</strong></li>
                                    <li>Ground pepper <strong>0.5c</strong></li>
                                </ul>
                            </span></a> </span > </div >
                </section >
                {/* <!--Meal Planner Section end--> */}

                {/* <!--Fit Beat & Nutrition Diet Section start--> */}
                <section className="fit-beat-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-xl-6 col-md-12 col-lg-6">
                                <h2 className="title title-fold-1-landing"> <span>Fit Beat & Nutrition Diet</span>Eat healthier with your Fitbit </h2>
                                <p className='p-landing'>Connect your Fitbit with the Flavorable app and we’ll find the best dishes for you—based on your taste,
                                    activity level, and more. Browse through meals or save them for a craving later on. </p>
                            </div>
                        </div>
                    </div>
                    <div className="women-fitness"> <span className="shop-list"> <a
                        className="green-circle watch-icon"><span className="tooltip"><strong>Connect</strong>To your Fitbit</span></a> <a
                            className="green-circle apple-icon"><span className="tooltip"><strong>Diet</strong>465kcal /
                                Meal</span></a></span> </div>
                </section>
                {/* <!--Fit Beat & Nutrition Diet Section end--> */}

                {/* <!--Download Section start--> */}
                <section className="download-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-xl-6 col-md-12 col-lg-6">&nbsp;</div>
                            <div className="col-12 col-xl-6 col-md-12 col-lg-6">
                                <h2 className="title title-fold-1-landing"> <span>Download App</span>Discover Restaurant & Delicious Food</h2>
                                <p className='p-landing'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est, adipiscing cursus auctor eget sed phasellus
                                    senectus. </p>
                            </div>
                        </div>
                    </div>
                    <div className="veg-box"> <span className="shop-list"> <a className="green-circle speak-icon"><span
                        className="tooltip left"><strong>Live</strong> Session</span></a> <a
                            className="green-circle bell-icon"><span className="tooltip"><strong className="red-txt">Allergy Alert</strong>Green
                                peas</span></a> <a className="green-circle list-icon"> <span className="tooltip listtip">
                                    <h3>Meal Planner</h3>
                                    <ul className="ul-tooltip">
                                        <p>Mon 02/11/22</p>
                                        <li>Bolognese Pasta <strong>Lunch</strong></li>
                                        <li>Rib eye Steak <strong>Dinner</strong></li>
                                    </ul>
                                    <ul className="ul-tooltip">
                                        <p>Mon 02/11/22</p>
                                        <li>Bolognese Pasta <strong>Lunch</strong></li>
                                        <li>Rib eye Steak <strong>Dinner</strong></li>
                                    </ul>
                                    <p>
                                        <strong className="add-icon green-circle">
                                            {/* <!--<i className="fa fa-plus"></i>--> */}
                                        </strong>
                                    </p>
                                </span></a> </span> </div>
                </section>
                {/* <!--Download Section end--> */}

                {/* <!--Testimonials Section start--> */}
                <section className="testimonials-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-xl-5 col-md-12 col-lg-6">
                                <h2 className="title title-fold-1-landing">Testimonials </h2>
                                <p className=' p-landing '>The best recipes always come with a story </p>
                            </div>
                            <div className="col-12 col-xl-6 col-md-12 col-lg-6">
                                <Slider className="testimonials-slider" {...testimonials_slider_settings}>
                                    <div>
                                        <div className="test-slide-box">
                                            <div className="testi-content">"Thank you so much for your help. It's exactly what I've been looking for.
                                                You won't regret it. It really saves me time and effort. Flavorable is exactly what our business has
                                                been lacking."</div>
                                            <div className="test-title">
                                                <h3>Gloria Rose</h3>
                                                <span> <i><i className="fas fa-star"></i></i> <i><i className="fas fa-star"></i></i> <i><i
                                                    className="fas fa-star"></i></i> <i><i className="fas fa-star"></i></i> <i><i
                                                        className="fas fa-star"></i></i> <strong>12 reviews</strong> </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="test-slide-box">
                                            <div className="testi-content">"Thank you so much for your help. It's exactly what I've been looking for.
                                                You won't regret it. It really saves me time and effort. Flavorable is exactly what our business has
                                                been lacking."</div>
                                            <div className="test-title">
                                                <h3>Gloria Rose</h3>
                                                <span> <i><i className="fas fa-star"></i></i> <i><i className="fas fa-star"></i></i> <i><i
                                                    className="fas fa-star"></i></i> <i><i className="fas fa-star"></i></i> <i><i
                                                        className="fas fa-star"></i></i> <strong>12 reviews</strong> </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="test-slide-box">
                                            <div className="testi-content">"Thank you so much for your help. It's exactly what I've been looking for.
                                                You won't regret it. It really saves me time and effort. Flavorable is exactly what our business has
                                                been lacking."</div>
                                            <div className="test-title">
                                                <h3>Gloria Rose</h3>
                                                <span> <i><i className="fas fa-star"></i></i> <i><i className="fas fa-star"></i></i> <i><i
                                                    className="fas fa-star"></i></i> <i><i className="fas fa-star"></i></i> <i><i
                                                        className="fas fa-star"></i></i> <strong>12 reviews</strong> </span>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--Testimonials Section end--> */}
            </div >

        )
    }
}


export default Landing;
import axios from 'axios';

// let url = "http://localhost:8000/api";
const url = "https://dev.flavorable.io";

// if(window.location.hostname === "localhost") {
//   url = "http://localhost:8000/api";
// }
// else {
//   url = window.location.origin+"/api";
// }

//Set Authorization Token
export function setAuthorizationToken(token, csrftoken = '',request ) {
  if (token) {
  request.headers.common['Authorization'] = `Token ${token}`;
} else {
    delete axios.defaults.headers.common['Authorization'];
  }
  if (csrftoken) {
    axios.defaults.headers.common['X-CSRFToken'] = `${csrftoken}`;
  } else {
    delete axios.defaults.headers.common['X-CSRFToken'];
  }
}


export function subscribeArticles(userData) {
  return axios.post(`${url}/articles/api/v1/subscribe/`, userData); //Subscribe to Articles
}


export function submitContactForm(userData) {
  return axios.post(`${url}/contactpage/api/v1/feedback/`, userData); // Submit contact form
}

export function submitRegisterForm(userData) {
  return axios.post(`${url}/api/user/v1/createuser/`, userData);  //Sumbmit Register form
}

export function blogApiContent() {
  return axios.get(`${url}/articles/api/v1/list/`);  //Blog Api
}
export function listBlogsByPage(page_no) {
  return axios.get(`${url}/articles/api/v1/list/?page=${page_no}`);  //Blog Api
}

export function postBlogComments(userData) {
  return axios.post(`${url}/articles/api/v1/comment/`, userData);  //send comments to server 
}
export function postBlogReply(userData) {
  return axios.post(`${url}/articles/api/v1/comment_reply/`, userData);  //send comments to server 
}

export function getBlogComments() {
  return axios.get(`${url}/articles/api/v1/comment/`,);  //get comments from server
}

export function searchBlogs(search) {
  return axios.get(`${url}/articles/api/v1/list/?query=${search}`);  //Search blogs
}

export function searchBlogsByPage(search, page) {
  return axios.get(`${url}/articles/api/v1/list/?page=${page}&query=${search}`);  //Search blogs
}

export function tagsBlogsByPage(tag, page) {
  return axios.get(`${url}/articles/api/v1/list/?page=${page}&tags=${tag}`);  //Search blogs
}


export function tagBlogs(tag) {
  return axios.get(`${url}/articles/api/v1/list/?tags=${tag}`);  //Search tag
}

export function currentBlog(blog_id) {
  return axios.get(`${url}/articles/api/v1/retrieve/${blog_id}`);  //Current Blog
}

export function submitLoginForm(userData) {
  return axios.post(`${url}/api/user/v1/login/`, userData);  //Sumbmit Register form
}

export function googleSingUp(userData) {
  return axios.post(`${url}/api/user/google/`, userData);  //google signup
}

export function facebookSignUp(userData) {
  return axios.post(`${url}/api/user/facebook/`, userData);  //facebook signup
}

export function appleAuth(userData) {
  return axios.post(`${url}/api/user/v1/login/`, userData);  //Sumbmit Register form
}

export function magicLogin(email) {
  return axios.get(`${url}/api/user/magic/signin/?email=${email}`);  //forgot password
}

export function resetPassword(userData) {
  return axios.post(`${url}/api/rest-auth/password/reset/`, userData);  //forgot password
}

export function createBlog(blogData) {
  return axios.post(`${url}/articles/api/v1/create/`, blogData);  //post blog 
}
export function getUserProfile(config) {
  return axios.get(`${url}/api/user/`, config);  //user info 
}

export function getRecipesForUser() {
  return axios.get(`${url}/api/recipe/v1/recipes_by_user/`);  //to get the recipes for specific user
}

export function getIngredients(page_no,query) {
  return axios.get(`${url}/api/recipe/v1/ingredients/?page=${page_no}&search=${query}`);  //to get the ingredients
}

export function getDiet() {
  return axios.get(`${url}/api/recipe/v1/diet/`);  //to get the recipes
}

export function recipe_type() {
  return axios.get(`${url}/api/recipe/v1/recipe_type/`);  //to get the recipes by 
}

export function getCuisine() {
  return axios.get(`${url}/api/recipe/v1/recipe_culture/`);  //to get the cuisine
}

export function getGeography() {
  return axios.get(`${url}/api/recipe/v1/recipe_geography/`);  //to get the bake time
}

export function getTest() {
  return axios.get(`${url}/api/recipe/v1/recipe_taste/`);  //to get the resting time
}

export function updateBlog(userData, blog_id) {
  return axios.put(`${url}/articles/api/v1/update/${blog_id}/`, userData);
}

export function workspaceBlogs() {
  return axios.get(`${url}/articles/api/v1/workspace/`);
}

export function updateUser(userData) {
  return axios.put(`${url}/api/user/update-user/`, userData);
}

// retrieve the user 
export function retrieveUser() {
  return axios.get(`${url}/api/user/`)
}


export function getRecipes(page_no) {
  return axios.get(`${url}/api/recipes/?page=${page_no}`);  //to get all recipes 
}

export function retrieveRecipe(slug) {
  return axios.get(`${url}/api/recipe/v1/retrieve/${slug}/`);  //to get retrieve the specefic recipes 
}

export function searchRecipe(query) {
  return axios.get(`${url}/api/recipe/v1/search_recipe/?query=${query}`);  //to get retrieve the specefic recipes 
}

export function getRecipeByTag(tag) {
  return axios.get(`${url}/api/recipe/v1/search_recipe/?tags=${tag}`);  //to get retrieve the specefic recipes 
}

export function listCommentsForRecipe(slug) {
  return axios.get(`${url}/api/recipes/get_reviews/?slug=${slug}`);  //list comments for the recipes
}

export function postCommentsForRecipe(slug, formdata) {
  return axios.post(`${url}/api/recipes/${slug}/reviews/`,formdata);  //post comments for the recipes
}

export function updateRecipe(slug, formdata) {
  return axios.put(`${url}/api/recipe/v1/update/${slug}/reviews/`,formdata);  //to update the recipe
}

export function createRecipe(formdata) {
  return axios.post(`${url}/api/recipe/v1/create/`,formdata);  //to create the recipe
}

export function addRecipeToFavourite(recipe_id) {
  return axios.post(`${url}/api/recipe/v1/favourite_recipes/`,recipe_id);  //to add the recipe to the favourite
}

export function addLikeToRecipe(slug) {
  return axios.post(`${url}/api/recipes/${slug}/like/`);  //to add the like to recipe
}

export function removeLikeFromRecipe(slug) {
  return axios.delete(`${url}/api/recipes/${slug}/like/`);  //to remove the likes recipe
}

export function getRelatedRecipe(recipe_id) {
  return axios.get(`${url}/api/recipe/v1/related_recipes/?recipe_id=${recipe_id}`);  //to get the related recipes
}

// Api related to the subscription 
export function modifySubscription(formdata) {
  return axios.put(`${url}/subscription/subscription/`,formdata);  //modify the subscription
}

export function createSubscription(formdata) {
  return axios.post(`${url}/subscription/subscription/`,formdata);  //create the subscription
}

export function listSubscriptonsPlans() {
  return axios.get(`${url}/subscription/v1/plans/`) //to get the subscriptions
}

export function followUnfollowUser(auther_id) {
  return axios.post(`${url}/health_profile/api/v1/follow_unfollow_user/`,auther_id) //to follow and unfollow the user
}

export function subscriptionPreview(plan_id) {
  return axios.post(`${url}/subscription/subscription_preview/`,plan_id) //to follow and unfollow the user
}


export function popularTagsForRecipe() {
  return axios.get(`${url}/api/recipe/v1/popular_tags/`) //to get the polular tags for the recipe
}



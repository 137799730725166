
import React, { Component } from 'react'

export default class TagBlogs extends Component {

  render() {

    return (
      <>
        {this.props?.tags?.map((tag, index) => {
          return (
            <ul key={`${tag}-${index}`} >
              {index < this.props?.tags?.length - 1 && (<li style={{ listStyle: "none" }}><b>|</b></li>)}
              {tag &&
                <li style={{ listStyle: "none" }}>{tag}</li>
              }
            </ul>
          )
        })
        }
      </>
    )
  }
}
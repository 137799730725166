import React, { Component } from 'react'
import Modal from 'react-modal';
import cooking_video from "../../assets/images/cooking-video.mp4"


export class WatchTutorialModal extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false
        };
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }
    handleOpenModal() {
        this.setState({ showModal: true });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }
    render() {
        return (
            <>
                <li className="tutorial">
                    <a onClick={this.handleOpenModal}><b><i className="fas fa-play"></i></b><span>Watch Tutorial</span></a>
                </li>
                <Modal
                    isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    className="watchTutorialModal"
                    overlayClassName="watchTutorialOverlay"
                    closeTimeoutMS={500}
                    ariaHideApp={false}
                >
                    <div className='contener'>
                        <video className="video-fluid z-depth-1" autoPlay controls muted>
                            <source src={cooking_video} type="video/mp4" />
                        </video>
                    </div>
                </Modal>
            </>
        )
    }
}

export default WatchTutorialModal
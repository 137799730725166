import React from 'react';
import { Editor } from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import { createBlog } from '../../apis'
import { WithContext as ReactTags } from 'react-tag-input';
import { notification } from 'antd';
import { Navigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { S3Client } from "@aws-sdk/client-s3";
import { PutObjectCommand } from "@aws-sdk/client-s3";
import { currentBlog, updateBlog } from '../../apis';
import { useParams } from "react-router-dom";


class S3UploadAdapter {

    constructor(loader) {

        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                this._initRequest();
                this._initListeners(resolve, reject, file);
                this._sendRequest(file);
            }));
    }

    // Aborts the upload process.
    abort() {

        if (this.xhr) {
            this.xhr.abort();
        }
    }


    _initRequest() {

    }



    _initListeners(resolve, reject, file) {
        const params = {
            Bucket: process.env.React_App_BUCKET_NAME,
            Key: `content/${file.name}`,
            Body: file,
        };
        const CREDENTIAL = {
            accessKeyId: process.env.React_App_USER_KEY,
            secretAccessKey: process.env.React_App_USER_SECRET,
        };
        const REGION = process.env.React_App_REGION;
        const s3Client = new S3Client({ region: REGION, credentials: CREDENTIAL });
        s3Client.send(new PutObjectCommand(params));


        resolve({
            default: `https://s3.ap-southeast-2.amazonaws.com/flavorable.io/content/${file.name}`,
            // '300': `https://s3.ap-southeast-2.amazonaws.com/flavorable.io/content/${file.name}`,
            // '400': `https://s3.ap-southeast-2.amazonaws.com/flavorable.io/content/${file.name}`,
            // '1000': `https://s3.ap-southeast-2.amazonaws.com/flavorable.io/content/${file.name}`,
            // '1052': `https://s3.ap-southeast-2.amazonaws.com/flavorable.io/content/${file.name}`,
        });

    }

    // Prepares the data and sends the request.
    _sendRequest(file) {

    }
}


function S3UploadAdapterPlugin(editor) {
    window.editor = editor
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new S3UploadAdapter(loader);
    };
}


const editorConfiguration = {

    wordCount: {
        onUpdate: stats => {
            let wordCount = `Characters: ${stats.characters}\n Words: ${stats.words}`;
        }
    },

    autosave: {
        waitingTime: 5000, // in ms
        save(editor) { }
    },

    removePlugins: ["MediaEmbedToolbar"],

    extraPlugins: [S3UploadAdapterPlugin],


}



function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
class NewBlog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            htmlData: "",
            fields: { title: "" },
            errors: {},
            backgroundImage: [],
            tags: [],
            blog: {},
            isPublished: false,
            isLoading: false,
            image: "",
            blog_id: null
        }
        this.handleChangeFields = this.handleChangeFields.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0)


        let blog_id = this.props?.params?.blogId;
        if (blog_id) {
            currentBlog(blog_id)
                .then(resp => {

                    let fields = {
                        title: resp?.data?.title,
                        abstract: resp?.data?.abstract
                    }

                    let tags = []
                    for (let tag of resp.data.tags) {
                        let tagg = {
                            id: tag,
                            text: tag
                        }
                        tags.push(tagg)
                    }


                    let isHeroImage;
                    for (let image of resp.data.images) {
                        if (image.is_hero) {
                            isHeroImage = image.image
                        }
                    }

                    this.setState({
                        fields: fields,
                        tags: tags,
                        image: isHeroImage,
                        content: resp.data.content,
                        blog_id: blog_id
                    })

                    window.editor.setData({ main: resp.data.content })
                })

                .catch(err => {
                })


        }

        //Input label effects JS
        $("input").val("");

        $(".input-effect input").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });
        $(".input-effect textarea").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });
        $(".input-effect select").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        });

        this.imagejs();
    }

    imagejs() {
        let fileInput = document.getElementById("file-upload-input");
        let fileSelect = document.getElementsByClassName("file-upload-select")[0];
        fileSelect.onclick = function () {
            fileInput.click();
        }
        // fileSelect.on
        fileInput.onchange = function () {
            let filename = fileInput.files[0].name;
            let selectName = document.getElementsByClassName("file-select-name")[0];
            selectName.innerText = filename;
        }
    }

    handleChangeContent = (event, editor) => {

        this.setState({
            htmlData: editor.getData(),
            data: ReactHtmlParser(editor.getData())
        })
    }

    handleChangeFields(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields: fields
        });
    }

    handleChangeImage = (e) => {
        const image = e.target.files[0]
        this.setState({
            backgroundImage: image,
            name: e.target.name
        })
    }

    handleClickPublish = (e) => {
        e.preventDefault();
        if (this.validateform()) {
            this.setState({
                isLoading: true
            })
            document.getElementById("btn mt-5").disabled = true;
            let tags = [];
            for (let tag of this.state.tags) {
                tags.push(tag.text)
            }
            let formData = new FormData();
            formData.append('title', this.state.fields.title);
            formData.append('abstract', this.state.fields.abstract);
            formData.append('tags', tags);
            formData.append('content', this.state.htmlData);
            formData?.append('images', this.state.backgroundImage, this.state.backgroundImage.name);
            formData.append('hero_image', this.state.backgroundImage, this.state.backgroundImage.name);

            if (this.state.blog_id) {
                updateBlog(formData, this.state.blog_id)
                    .then(resp => {
                        notification.success({
                            message: 'Blog published successfully!',
                            placement: 'bottomRight'
                        })
                        this.setState({
                            blog: resp.data,
                        })

                        if (this.state.blog !== {}) {
                            this.setState({
                                isPublished: true,
                                isLoading: false
                            })
                        }
                        document.getElementById("btn mt-5").disabled = false;
                    })

                    .catch(err => {


                        // if (err.response?.data?.title && err.response?.data?.abstract) {
                        //     notification.error({
                        //         message: 'Ensure title and abstract fields have no more than 255 characters!',
                        //         placement: 'bottomRight'
                        //     })
                        // }

                        // else if (err.response?.data?.title) {
                        //     notification.error({
                        //         message: 'Ensure title field has no more than 255 characters!',
                        //         placement: 'bottomRight'
                        //     })
                        // }

                        // else if (err.response?.data?.abstract) {
                        //     notification.error({
                        //         message: 'Ensure abstract field has no more than 255 characters!',
                        //         placement: 'bottomRight'
                        //     })
                        // }
                        // this.setState({
                        //     isLoading: false
                        // })
                        document.getElementById("btn mt-5").disabled = false;
                    })
            } else {
                createBlog(formData)
                    .then(resp => {
                        notification.success({
                            message: 'Blog published successfully!',
                            placement: 'bottomRight'
                        })
                        this.setState({
                            blog: resp.data,
                        })

                        if (this.state.blog !== {}) {
                            this.setState({
                                isPublished: true,
                                isLoading: false
                            })
                        }
                        document.getElementById("btn mt-5").disabled = false;
                    })

                    .catch(err => {
                        if (err.response?.data?.title && err.response?.data?.abstract) {
                            notification.error({
                                message: 'Ensure title and abstract fields have no more than 255 characters!',
                                placement: 'bottomRight'
                            })
                        }

                        else if (err.response?.data?.title) {
                            notification.error({
                                message: 'Ensure title field has no more than 255 characters!',
                                placement: 'bottomRight'
                            })
                        }

                        else if (err.response?.data?.abstract) {
                            notification.error({
                                message: 'Ensure abstract field has no more than 255 characters!',
                                placement: 'bottomRight'
                            })
                        }
                        this.setState({
                            isLoading: false
                        })
                        document.getElementById("btn mt-5").disabled = false;
                    })
            }

        }
    }


    validateform = () => {
        let fields = this.state.fields;
        let errors = {};
        let isValid = true;

        if (!fields["title"]?.trim()) {
            isValid = false;
            errors["errorTitle"] = "*Please add title.";
        }

        if (!fields["abstract"]?.trim()) {
            isValid = false;
            errors["errorAbstract"] = "*Please add abstract.";
        }

        if (!this.state.htmlData?.trim()) {
            isValid = false;
            errors["errorContent"] = "*Please add Content.";
        }

        if (this.state.backgroundImage?.length === 0) {
            isValid = false;
            errors["errorBackgroundImage"] = "*Please add an image.";
        }

        if (this.state.tags?.length > 4) {
            isValid = false;
            errors["errorTags"] = "*Number of tags should be less than 4.";
        }

        if (this.state.tags?.length === 0) {
            isValid = false;
            errors["errorTags"] = "*Please add tags.";
        }

        this.setState({
            errors: errors,
        })

        return isValid;
    }

    handleDelete = i => {
        const tag = this.state.tags.filter((tag, index) => index !== i)

        this.setState({ tags: tag });

    };

    handleAddition = tag => {
        const tags = this.state.tags.slice();
        tags.push(tag);
        this.setState({ tags });
    };

    handleInputChange = (e) => {
        let tagLength = this.state.tags?.length

        if (tagLength > 3) {
            notification.error({
                message: "tags shouldn't be more than 4!",
                placement: 'bottomRight'
            })
        }

    }

    render() {
        let token = ReactSession.get('authtoken');
        return (
            <>
                {!token && (<Navigate to="/blogs" replace={true} />)}
                <section className="banner-section-create-blog">
                    {this.state.isPublished && (<Navigate to={`/blog/${this.state.blog.id}/${this.state.blog.title}`} replace={true} />)}
                    <section className="banner-content create-area">
                        <div className="App">
                            <div className="col-12 form-group  ">
                                <div className="input-effect">
                                    <input className="effect" type="text" name="title" value={this.state.fields.title} placeholder="" onChange={(event) => this.handleChangeFields(event)} autoComplete="off" maxLength="80" />
                                    <label>Title</label>
                                    <span className='errorValidation'>{this.state.errors.errorTitle}</span>
                                </div>
                            </div>
                            <div className="col-12 form-group  ">
                                <div className="input-effect">
                                    <textarea id='message' className="effect" type="text" name="abstract" value={this.state.fields.abstract} placeholder="" onChange={(event) => this.handleChangeFields(event)} autoComplete="off" maxLength="255" />
                                    <label>Abstract</label>
                                    <span className='errorValidation'>{this.state.errors.errorAbstract}</span>
                                </div>
                            </div>
                            <div className="col-12 form-group  ">
                                <div className="input-effect">
                                    <label style={{ color: "#85b492" }}>Tags (Add tags up to 4 so readers know what your article is about)</label>
                                    <ReactTags
                                        tags={this.state.tags}
                                        handleAddition={this.handleAddition}
                                        handleDelete={this.handleDelete}
                                        handleInputChange={this.handleInputChange}
                                        inputFieldPosition="bottom"
                                        placeholder="Add new tags here and press enter"
                                        allowUnique={true}
                                        maxLength={15}
                                    />
                                    <span className='errorValidation'>{this.state.errors.errorTags}</span>
                                </div>
                            </div>

                            <div className="file-upload">
                                <div className="file-upload-select">
                                    <div className="file-select-button btn mb-5 " >Choose image</div>

                                    <div className="file-select-name">{this.state.image}</div>
                                    <input type="file" name="file-upload-input" id="file-upload-input" accept="image/*" value={this.state.backgroundImag} onChange={(e) => this.handleChangeImage(e)} />
                                    <span className='errorValidation'>{this.state.errors.errorBackgroundImage}</span>
                                </div>
                            </div>
                            <div>
                            </div>
                            <CKEditor
                                editor={Editor}
                                config={editorConfiguration}
                                data=""
                                onChange={(event, editor) => this.handleChangeContent(event, editor)}
                            />
                            {this.state.data}
                            {this.state.htmlData}
                            <span className='errorValidation'>{this.state.errors.errorContent}</span>
                            <div className="col-12 col-xl-12 col-md-12 text-center">
                                <button id="btn mt-5" onClick={(e) => this.handleClickPublish(e)} className="btn mt-5">Pub
                                    {this.state.isLoading &&
                                        <div className="spinner-border text-info" role="status">
                                            <span className="visually-hidden"></span>
                                        </div>}
                                    lish</button>
                            </div>
                        </div>
                    </section>
                </section>
            </>
        )
    }
}
export default withParams(NewBlog);
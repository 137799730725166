import React, { useState, useEffect } from "react";
import blog_banner from "../../assets/images/blog-banner.png";
import blog_banner_mob from "../../assets/images/blog-banner-mob.png";
import $ from "jquery";
import LazyLoad from "react-lazyload";
import Loading from "../components/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useParams } from "react-router-dom";
import { ReactSession } from "react-client-session";
import { getRecipes, searchRecipe, getRecipeByTag, popularTagsForRecipe } from "../../apis";
import Recipe from "../components/Recipe";

let is_initial = true;
const Recipes = () => {
  // All the states are defined over here
  const [recipe_data, setReciepeData] = useState({});
  const [entered_search, setEnteredSearch] = useState("");
  const [is_loading, setIsLoading] = useState(true);
  const [selected_tag, setSelectedTag] =useState('Tags')
  const [pupular_tags, setPopularTags] = useState([])
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
   
    if (params?.tag) {
      getRecipeByTag(params.tag)
        .then((resp) => {
          let new_data = {
            count: resp?.data?.results?.recipes?.length,
            next: null,
            previous: null,
            results: resp?.data?.results?.recipes,
          };
          setReciepeData(new_data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("some error occured", err.response);
          setIsLoading(false);
        });
    } else {
      let page_no = 1;
      getRecipes(page_no)
        .then((resp) => {
          setReciepeData(resp.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("this is the error", err.response);
        });

        popularTagsForRecipe()
        .then((resp)=>{
          setPopularTags(resp.data?.popular_tags[0]?.recipe?.slice(0, 10))
          if (is_initial) {
            enableSelect();
          }
          is_initial = false;
        })
        .catch((err)=>{
          console.log('some error occured while fetching the popular tags', err.response);
        })

    }

    return () => {
      is_initial = true;
    };
  }, []);

  function enableSelect() {
    var customSelect = $("select.custom-select");
    var _ = this;
    var current_tag = selected_tag
    // FIRST, create the custom select menus from the existing select
    customSelect.each(function () {
      var that = $(this);
      var listID = that.attr("id"),
        groups = that.children("optgroup"),
        theOptions = "",
        startingOption = "",
        customSelect = "";
      //check if there are option groups
      if (groups.length) {
        groups.each(function () {
          var curGroup = $(this);
          var curName = curGroup.attr("label");
          //Open the option group
          theOptions += '<li class="optgroup">' + curName + "</li>";
          //get the options
          curGroup.children("option").each(function () {
            var curOpt = $(this);
            var curVal = curOpt.attr("value"),
              curHtml = curOpt.html(),
              isSelected = curOpt.attr("defaultValue");
            if (isSelected === "selected") {
              startingOption = curHtml;
              theOptions +=
                '<li class="selected" data-value="' +
                curVal +
                '">' +
                curHtml +
                "</li>";
            } else {
              theOptions +=
                '<li data-value="' + curVal + '">' + curHtml + "</li>";
            }
          });
          //Close the option group
          //theOptions += '<li class="optgroup-close"></li>';
        });
        //add options not in a group to the top of the list
        that.children("option").each(function () {
          var curOpt = $(this);
          var curVal = curOpt.attr("value"),
            curHtml = curOpt.html(),
            isSelected = curOpt.attr("selected");
          if (isSelected === "selected") {
            startingOption = curHtml;
            theOptions =
              '<li class="selected" data-value="' +
              curVal +
              '">' +
              curHtml +
              "</li>" +
              theOptions;
          } else {
            theOptions =
              '<li data-value="' +
              curVal +
              '">' +
              curHtml +
              "</li>" +
              theOptions;
          }
        });
      } else {
        that.children("option").each(function () {
          var curOpt = $(this);
          var curVal = curOpt.attr("value"),
            curHtml = curOpt.html(),
            isSelected = curOpt.attr("defaultValue");
          if (curVal !== "tags") {
            theOptions +=
              '<li data-value="' + curVal + '">' + curHtml + "</li>";
          }
        });
      }
      //build the custom select
      customSelect = `<div class="dropdown-container"><div class="dropdown-select entypo-down-open-big"><span> ${current_tag} </span></div><ul class="dropdown-select-ul" data-role="${listID}"> ${theOptions} </ul></div> <!-- .custom-select-wrapper -->`;
      //append it after the actual select
      $(customSelect).insertAfter(that);
    });

    var selectdd = $(".dropdown-select"),
      selectul = $(".dropdown-select-ul"),
      selectli = $(".dropdown-select-ul li");
    //THEN make them work
    selectdd.on("click", function () {
      $(this).parent(".dropdown-container").toggleClass("active");
    });
    //Hide it on mouseleave
    selectul.on("mouseleave", function () {
      $(this).parent(".dropdown-container").removeClass("active");
    });
    //select the option
    selectli.on("click", function (event) {
      var that = $(this);
      if (!that.hasClass("optgroup")) {
        var parentUl = that.parent("ul"),
          thisdd = parentUl.siblings(".dropdown-select"),
          lihtml = that.html(),
          livalue = that.attr("data-value"),
          originalSelect = "#" + parentUl.attr("data-role");
        //close the dropdown
        parentUl.parent(".dropdown-container").toggleClass("active");
        //remove selected class from all list items
        that.siblings("li").removeClass("selected");
        //add .selected to clicked li
        that.addClass("selected");
        //set the value of the hidden input
        $(originalSelect).val(livalue);
        //change the dropdown text
        thisdd.children("span").html(lihtml);

        handleClickSelectTag(livalue);
      }
    });
  }

  const fetchMoreRecipes = () => {
    if (recipe_data.next) {
      let page = recipe_data.next;
      let page_no = page.split("=").pop();
      const token = ReactSession?.get("authtoken");
      getRecipes(page_no)
        .then((resp) => {
          let new_result = recipe_data.results.concat(resp.data.results);
          let new_data = {
            count: resp?.data?.count,
            next: resp?.data?.next,
            previous: resp?.data?.previous,
            results: new_result,
          };
          setReciepeData(new_data);
          // enableSelect();
        })
        .catch((err) => {
          console.log("this is the error", err.response);
        });
    }
  };

  const handleChangeSearch = (e) => {
    setEnteredSearch(e.target.value);
  };

  const handleClickRecipesSearch = (e) => {
    e.preventDefault();
    setSelectedTag("Tags")
    if (entered_search.trim()) {
      setIsLoading(true);
      searchRecipe(entered_search.trim())
        .then((resp) => {
          let new_result = resp?.data.results.recipes;
          let new_data = {
            count: resp?.data?.count,
            next: resp?.data?.next,
            previous: resp?.data?.previous,
            results: new_result,
          };
          setReciepeData(new_data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("this is the error", err.response);
          setIsLoading(false);
        });
    }
  };

  const handleClickSelectTag = (tag) => {
    setEnteredSearch('')
    setIsLoading(true);
    getRecipeByTag(tag)
      .then((resp) => {
        let new_data = {
          count: resp?.data?.results?.recipes?.length,
          next: null,
          previous: null,
          results: resp?.data?.results?.recipes,
        };
        setReciepeData(new_data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("some error occured", err.response);
        setIsLoading(false);
      });
  };

  return (
    <>
      <section className="inner-page-banner ">
        <div className="banner-content">
          <div className="container">
            <div className="row">
              <div className="col-10 col-xl-7 col-md-7 col-lg-7">
                <h1 className="title-register ">
                  {" "}
                  <span>Recipe</span> Delicious, Healthy Meals Without
                  Compromise
                </h1>
                <div className="col-12 col-xl-9 col-md-10 col-lg-9 p-0">
                  <p className="contact-p">
                    Browse through our library of recipes to find the perfect
                    meal for your family. With categories tailored to your
                    lifestyle, you're sure to find something that meets your
                    needs.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="inner-img-box">
            {" "}
            <img
              src={blog_banner}
              className="img-fluid desktop-display"
              alt=""
            />{" "}
            <img src={blog_banner_mob} className="device-display" alt="" />
          </div>
        </div>
      </section>

      <section className="mid-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-12 col-lg-4">
              <div className="input-effect">
                <select
                  defaultValue="selected tags"
                  className="custom-select effect"
                  id="select-tags"
                  // ref={this.feedback_type}
                >
                  <option value="tags" defaultValue>
                    Tags
                  </option>

                  {pupular_tags?.map((tag,index)=>{
                  return( <option value={tag} key={`${index}-${tag}`}>{tag}</option>)})}
                </select>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4">
              <div className="input-group search-box">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search drinks, recipe, blogs..."
                  aria-label="Search drinks, recipe, blogs..."
                  aria-describedby="basic-addon2"
                  name="search"
                  value={entered_search}
                  onChange={handleChangeSearch}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleClickRecipesSearch(event);
                    }
                  }}
                  autoComplete="off"
                />
                <div className="input-group-append">
                  <button
                    className="btn"
                    type="button"
                    onClick={handleClickRecipesSearch}
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {is_loading ? (
            <>
              <Loading />
            </>
          ) : (
            <>
            {!recipe_data.results.length ? (
              <h1>
                No Recipes Found!
              </h1>
            ):(
              <div className="blog-area">
              <InfiniteScroll
                dataLength={recipe_data.results.length}
                next={fetchMoreRecipes}
                hasMore={true}
                loader={
                  <>
                    <Loading />
                  </>
                }
              >
                <div className="container">
                  {/* {this.state.isTag && (
                        <div>
                          <h1 style={{ textAlign: "center" }}>
                            Articles for the "{this.state.tag?.toUpperCase()}"
                            tag
                          </h1>
                        </div>
                      )}
                      {!this.state.noData && this.state.isSearch && (
                        <div>
                          <h1 style={{ textAlign: "center" }}>
                            Articles for the "
                            {this.state.fields.search?.toUpperCase()}" search
                          </h1>
                        </div>
                      )} */}
                  <div className="row">
                    {recipe_data?.results?.map((recipe) => {
                      return (
                        <div className="col-md-6 col-lg-4" key={recipe.id}>
                          <Recipe
                            title={recipe.name_of_recipe}
                            image={recipe?.image_1}
                            tags={recipe?.tags}
                            date={recipe?.created_at}
                            id={recipe?.id}
                            slug={recipe.slug}
                          />
                        </div>
                      );
                    })}
                    {/* {this.state.noData && (
                          <>
                            <div className="col-xl-12 col-11 col-md-12 col-lg-12 col-sm-12">
                              <h1 style={{ margin: "0 0 5px 0 " }}>
                                No blogs available for given search result!
                              </h1>
                              <span>
                                Make sure all words are spelled correctly.
                              </span>
                            </div>
                          </>
                        )} */}
                  </div>
                </div>
              </InfiniteScroll>
            </div>
            )}
           
            </>
           
          )}
        </div>
      </section>
    </>
  );
};

export default Recipes;

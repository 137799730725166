import { React, Component } from "react";
import { Link, NavLink } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Moment from "react-moment";
import TagBlogs from "./TagBlogs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical,faPenToSquare } from "@fortawesome/free-solid-svg-icons";

export class UpdateArticle extends Component {
  render() {
    let title = this.props?.title?.replaceAll(" ", "-");
    return (
      <>
        <Link
          to={`/createblog/${this.props?.id}/${title}`}
          className="blog-box"
        >
          {/* <LazyLoad> */}
          <span className="blog-thumbnail">
            {this.props?.images
              ?.filter((tru) => tru?.is_hero === true)
              ?.map((img) => (
                <img
                  src={img?.image}
                  alt=""
                  key={img?.image}
                  className="img-fluid"
                />
              ))}
          </span>
          <h2 className="title">
            <span>
              {<Moment format="MMM D, YYYY">{this.props?.date}</Moment>}
                <FontAwesomeIcon
                  className="trashIcon"
                  icon={faEllipsisVertical}
                  size="2x"
                  transform="shrink-6"
                  color="#85b492"
                  style={{ float: "right" }}
                  />
        <NavLink
          to={`/createblog/${this.props?.id}/${title}`}
          className="editWrapper"
        >
              <div className={"edit-drope-down"}>
            <span>
                <FontAwesomeIcon
                className="trashIcon"
                icon={faPenToSquare}
                size="2x"
                transform="shrink-6"
                color="#85b492"
                // style={{ float: "right" }}
                />     Edit
                </span> 
              </div>
              </NavLink>                
            </span>
            {this.props?.title}{" "}
          </h2>
          <p>{this.props?.abstract}</p>
          {/* </LazyLoad> */}
          <div className="discover">
            <TagBlogs tags={this.props?.tags} />
          </div>
        </Link>
      </>
    );
  }
}

export default UpdateArticle;

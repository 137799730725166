import React from "react";

import { Link } from "react-router-dom";

import blog_banner from "../../assets/images/blog-banner.png";
import blog_banner_mob from "../../assets/images/blog-banner-mob.png";

import { notification } from "antd";

import {
  blogApiContent,
  listBlogsByPage,
  searchBlogs,
  searchBlogsByPage,
  tagBlogs,
  tagsBlogsByPage,
  workspaceBlogs,
  getRecipesForUser,
  popularTagsForRecipe,
  getRecipeByTag
} from "../../apis";

import $, { data } from "jquery";

import LazyLoad from "react-lazyload";

import Loading from "../components/Loading";
import UpdateArticle from "../components/Update_Article";

import InfiniteScroll from "react-infinite-scroll-component";

import { useParams } from "react-router-dom";
import { ReactSession } from "react-client-session";
import RecipeComopant from "../components/Recipe";
import MyWorkspaceTags from "../components/my-worksapce-tags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class MyWorkspace extends React.Component {
  constructor(props) {
    super(props);
    this.feedback_type = React.createRef();
    this.state = {
      loading: true,
      data: {},
      recipe_data : {},
      fields: { search: "" },
      isTag: false,
      is_Loged_In: false,
      isBlogs: true,
      isSearch: false,
      tag: "",
      tagg: "",
      search: "",
      isData: false,
      noData: false,
      tagged: "",
      popular_tags:[],
      initial_popular_tags_state:[],
      show_edit_drop_down_blog:false,
      show_edit_drop_down_recipe:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChangeTags = this.handleDropdownChangeTags.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleClickPopularTags= this.handleClickPopularTags.bind(this)
    this.handleClickShowDropedownArticle= this.handleClickShowDropedownArticle.bind(this)
    this.handleClickShowDropedownRecipe= this.handleClickShowDropedownRecipe.bind(this)

  }

  enableSelect() {
    var state = this.state.tag;
    var customSelect = $("select.custom-select");
    var _ = this;
    // FIRST, create the custom select menus from the existing select
    customSelect.each(function () {
      var that = $(this);
      var listID = that.attr("id"),
        groups = that.children("optgroup"),
        theOptions = "",
        startingOption = "",
        customSelect = "";
      //check if there are option groups
      if (groups.length) {
        groups.each(function () {
          var curGroup = $(this);
          var curName = curGroup.attr("label");
          //Open the option group
          theOptions += '<li class="optgroup">' + curName + "</li>";
          //get the options
          curGroup.children("option").each(function () {
            var curOpt = $(this);
            var curVal = curOpt.attr("value"),
              curHtml = curOpt.html(),
              isSelected = curOpt.attr("defaultValue");
            if (isSelected === "selected") {
              startingOption = curHtml;
              theOptions +=
                '<li class="selected" data-value="' +
                curVal +
                '">' +
                curHtml +
                "</li>";
            } else {
              theOptions +=
                '<li data-value="' + curVal + '">' + curHtml + "</li>";
            }
          });
          //Close the option group
          //theOptions += '<li class="optgroup-close"></li>';
        });
        //add options not in a group to the top of the list
        that.children("option").each(function () {
          var curOpt = $(this);
          var curVal = curOpt.attr("value"),
            curHtml = curOpt.html(),
            isSelected = curOpt.attr("selected");
          if (isSelected === "selected") {
            startingOption = curHtml;
            theOptions =
              '<li class="selected" data-value="' +
              curVal +
              '">' +
              curHtml +
              "</li>" +
              theOptions;
          } else {
            theOptions =
              '<li data-value="' +
              curVal +
              '">' +
              curHtml +
              "</li>" +
              theOptions;
          }
        });
      } else {
        that.children("option").each(function () {
          var curOpt = $(this);
          var curVal = curOpt.attr("value"),
            curHtml = curOpt.html(),
            isSelected = curOpt.attr("defaultValue");
          if (curVal != "tags") {
            theOptions +=
              '<li data-value="' + curVal + '">' + curHtml + "</li>";
          }
        });
      }
      //build the custom select
      customSelect = `<div class="dropdown-container"><div class="dropdown-select entypo-down-open-big"><span> ${
        state ? state.toUpperCase() : "All"
      } </span></div><ul class="dropdown-select-ul" data-role="${listID}"> ${theOptions} </ul></div> <!-- .custom-select-wrapper -->`;
      //append it after the actual select
      $(customSelect).insertAfter(that);
    });

    var selectdd = $(".dropdown-select"),
      selectul = $(".dropdown-select-ul"),
      selectli = $(".dropdown-select-ul li");
    //THEN make them work
    selectdd.on("click", function () {
      $(this).parent(".dropdown-container").toggleClass("active");
    });
    //Hide it on mouseleave
    selectul.on("mouseleave", function () {
      $(this).parent(".dropdown-container").removeClass("active");
    });
    //select the option
    selectli.on("click", function (event) {
      var that = $(this);
      if (!that.hasClass("optgroup")) {
        var parentUl = that.parent("ul"),
          thisdd = parentUl.siblings(".dropdown-select"),
          lihtml = that.html(),
          livalue = that.attr("data-value"),
          originalSelect = "#" + parentUl.attr("data-role");

        //close the dropdown
        parentUl.parent(".dropdown-container").toggleClass("active");
        //remove selected class from all list items
        that.siblings("li").removeClass("selected");
        //add .selected to clicked li
        that.addClass("selected");
        //set the value of the hidden input
        $(originalSelect).val(livalue);
        //change the dropdown text
        thisdd.children("span").html(lihtml);

        _.handleDropdownChangeTags(lihtml);
      }
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

      popularTagsForRecipe()
      .then((resp)=>{
        let tags = []
        let new_tag
        let id = 1
        for(let data of resp?.data?.popular_tags[0]?.user?.slice(0, 10)){
          new_tag = {
            id:id++,
            is_selected:false,
            name:data
          }
          tags.push(new_tag)
        }

        this.setState({
          popular_tags:tags,
          initial_popular_tags_state:JSON.parse(JSON.stringify(tags))
        })
        
      })
      .catch((err)=>{
        console.log('some error occured while fetching the popular tags', err.response);
      })

      workspaceBlogs()
        .then((resp) => {
          this.setState({
            data: resp.data.recipes,
            recipe_data:resp.data.articles,
            loading: false,
          });
          this.enableSelect();
        })

        .catch((err) => {
          console.log(err.response);
        });
   
    const token = ReactSession?.get("authtoken");

    this.setState({
      is_Loged_In: token ? true : false,
    });
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e?.target?.name] = e?.target?.value;
    this.setState({
      fields,
    });
  }

  handleDropdownChangeTags(tag) {
    this.setState({
      loading:true
    })
    if(tag==="Recipes"){

      workspaceBlogs()
      .then((resp) => {
        this.setState({
          data: null,
          recipe_data:resp.data.articles,
          loading: false,
          popular_tags:JSON.parse(JSON.stringify(this.state.initial_popular_tags_state)),
          tag:tag
        });
        this.enableSelect();
      })

      .catch((err) => {
        console.log(err.response);
      });

    }
    else if (tag==="Blogs"){
      workspaceBlogs()
      .then((resp) => {
        this.setState({
          data: resp.data.recipes,
          recipe_data:null,
          loading: false,
          popular_tags:JSON.parse(JSON.stringify(this.state.initial_popular_tags_state)),
          tag:tag
        });
        this.enableSelect();
      })

      .catch((err) => {
        console.log(err.response);
      });
    }
    else{
      workspaceBlogs()
      .then((resp) => {
        this.setState({
          data: resp.data.recipes,
          recipe_data:resp.data.articles,
          loading: false,
          popular_tags:JSON.parse(JSON.stringify(this.state.initial_popular_tags_state)),
          tag:tag
        });
        this.enableSelect();
      })

      .catch((err) => {
        console.log(err.response);
      });

    }
  }

  handleClickSearch(e) {
    e?.preventDefault();
    this.setState({
      loading: true,
    });
    let search = this.state.fields["search"].trim();
    if (search) {
      if (search) {
        searchBlogs(search)
          .then((resp) => {
            this.setState({
              data: {},
            });

            this.setState({
              data: resp.data,
              loading: false,
              isBlogs: false,
              isTag: false,
              isSearch: true,
              noData: resp.data.count === 0 ? true : false,
              tag: "",
            });
            this.enableSelect();
          })

          .catch((err) => {});
      }
    } else {
      this.setState({
        loading: true,
      });
      let page_no = 1;
      listBlogsByPage(page_no)
        .then((resp) => {
          this.setState({
            data: resp.data,
            loading: false,
            isBlogs: true,
            isTag: false,
            tag: "",
          });
          this.enableSelect();
        })

        .catch((err) => {});
    }
  }

  fetchMoreBlogs = () => {
    if (this.state.isBlogs) {
      let page = this.state.data?.next;
      let page_no = page.split("=").pop();
      listBlogsByPage(page_no)
        .then((resp) => {
          let new_result = this.state.data.results.concat(resp.data.results);
          let new_data = {
            count: resp?.data?.count,
            next: resp?.data?.next,
            previous: resp?.data?.previous,
            results: new_result,
          };

          this.setState({
            data: new_data,
          });
        })

        .catch((err) => {});
    } else if (this.state.isSearch) {
      // reading page no
      let page = this.state.data?.next;
      page = page.split("page=");
      let page_no = page[1].charAt(0);
      // let page_no = page.charAt(49);   should i read page no by above aproch or the one i commented on the left
      searchBlogsByPage(this.state.search, page_no)
        .then((resp) => {
          let new_result = this.state.data.results.concat(resp.data.results);
          let new_data = {
            count: resp?.data?.count,
            next: resp?.data?.next,
            previous: resp?.data?.previous,
            results: new_result,
          };

          this.setState({
            data: new_data,
          });
        })

        .catch((err) => {});
    } else if (this.state.isTag) {
      let page = this.state.data?.next;
      page = page.split("page=");
      let page_no = page[1].charAt(0);
      tagsBlogsByPage(this.state.tagg, page_no)
        .then((resp) => {
          let new_result = this.state.data.results.concat(resp.data.results);
          let new_data = {
            count: resp?.data?.count,
            next: resp?.data?.next,
            previous: resp?.data?.previous,
            results: new_result,
          };

          this.setState({
            data: new_data,
          });
        })

        .catch((err) => {});
    }
  };

  handleClickPopularTags=(index)=>{
    this.setState({
      loading:true
    })
    const tags = [...this.state.popular_tags]
    if(tags[index].is_selected){
      workspaceBlogs()
        .then((resp) => {
          this.setState({
            data: resp.data.recipes,
            recipe_data:resp.data.articles,
            loading: false,
            popular_tags:JSON.parse(JSON.stringify(this.state.initial_popular_tags_state)),
            tag: "",
          });
          this.enableSelect();
        })

        .catch((err) => {
          console.log(err.response);
        });

    }
    else{
    const new_tags = JSON.parse(JSON.stringify(this.state.initial_popular_tags_state))
    new_tags[index].is_selected = !new_tags[index].is_selected

    getRecipeByTag(new_tags[index].name)
    .then((resp)=>{
      this.setState({
        popular_tags:new_tags,
        recipe_data:resp.data?.results?.recipes,
        data:resp.data?.results?.articles,
        loading: false,
        tag: "",
      })
      this.enableSelect();
    })
    .catch((err)=>{
      console.log("some error occured while fetching tags");
    })}
  }

  handleClickShowDropedownArticle=()=>{
    console.log('handleClickShowDropedownArticle',);
  }

  handleClickShowDropedownRecipe=()=>{
    console.log('handleClickShowDropedownRecipe');
  }


  render() {
    return (
      <div>
        {this.state?.loading  ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            {/* // <!--Banner container start--> */}
            {this.state.is_Loged_In && (
              <Link to="/createblog">
                <div className="creatblog"> + </div>
              </Link>
            )}
            {/* <section className="inner-page-banner "> */}
            <div className="banner-content">
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className="col-11 col-xl-12 col-md-12 col-lg-7">
                    <h1 className="title-register"> My Workspace </h1>
                    <div className="col-12 col-xl-6 col-md-8 col-lg-9 p-0">
                      <div style={{ fontSize: "11pt" }} className="contact-p">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Est, adipiscing cursus auctor eget sed phasellus
                        senectus.{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section className="mid-section">
              <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-12 col-md-12 col-lg-4">
                    <div className="tags-box-workspace">
                      {this.state.popular_tags?.map((tag,index)=>{

                      return <MyWorkspaceTags   
                        tag_name={tag.name}
                        key={tag.id}
                        is_selected={tag.is_selected}
                        index={index}
                        id={tag.id}
                        handleClickPopularTags={this.handleClickPopularTags}
                      />})}
                    </div>
                  </div>
                  <div className="col-11 col-md-12 col-lg-4">
                    <div className="input-effect">
                      <select
                        defaultValue="selected tags"
                        className="custom-select effect"
                        id="select-tags"
                        ref={this.feedback_type}
                      >
                        <option value="ALL" defaultValue>
                          All
                        </option>
                        <option value="BLOGS">Blogs</option>
                        <option value="RECIPES">Recipes</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-11 col-md-12 col-lg-4">
                    <div className="input-group search-box">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search drinks, recipe, blogs..."
                        aria-label="Search drinks, recipe, blogs..."
                        aria-describedby="basic-addon2"
                        name="search"
                        value={this.state.fields.search}
                        onChange={this.handleChange}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            this.handleClickSearch();
                          }
                        }}
                        autoComplete="off"
                      />
                      <div className="input-group-append">
                        <button
                          className="btn"
                          type="button"
                          onClick={this.handleClickSearch}
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="blog-area">
                    {this.state?.data &&
                  <InfiniteScroll
                    dataLength={this.state?.data?.length}
                    next={this.fetchMoreBlogs}
                    hasMore={this.state?.data?.next}
                    loader={
                    
                        <Loading />
                    
                    }
                  >
                    <div className="container">
                      {this.state.isTag && (
                        <div>
                          <h1 style={{ textAlign: "center" }}>
                            Articles for the "{this.state.tag?.toUpperCase()}"
                            tag
                          </h1>
                        </div>
                      )}
                      {!this.state.noData && this.state.isSearch && (
                        <div>
                          <h1 style={{ textAlign: "center" }}>
                            Articles for the "
                            {this.state.fields.search?.toUpperCase()}" search
                          </h1>
                        </div>
                      )}
                   { this.state.data?.length ?(<h1> This are the Blogs</h1>):<h1> No Blogs found</h1>}
                      <div className="row">
                        {this.state?.data?.length && this.state?.data?.map((blog) => {
                          return (
                            <div className="col-md-6 col-lg-4" key={blog.id}>
                              <UpdateArticle
                                title={blog?.title}
                                abstract={blog?.abstract}
                                images={blog?.images}
                                tags={blog?.tags}
                                date={blog?.created_at}
                                id={blog?.id}
                                show_edit_drop_down_blog={this.state.show_edit_drop_down_blog}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </InfiniteScroll>
                    }
                  <>
                   { this.state?.recipe_data?.length ?(<h1> This are the Recipes</h1>):<h1> No Recipes found</h1>}
                  <div className="row">
                  {this.state.recipe_data?.map((recipe) => {
                    return (
                      <div
                        className="col-md-6 col-lg-4"
                        key={recipe.id}
                      >
                        <RecipeComopant
                          title={recipe?.name_of_recipe}
                          image={recipe?.image_1}
                          tags={recipe?.tags}
                          date={recipe?.created_at}
                          id={recipe?.id}
                          slug={recipe.slug}
                          key={recipe.id}
                          show_edit_drop_down_recipe={this.state.show_edit_drop_down_recipe}
                          identifer={'my-workspace'}
                        />
                       </div>
                    );
                  })}
                  </div>
                  </>
                  {/* } */}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    );
  }
}

export default withParams(MyWorkspace);

import React, { useEffect, useState } from "react";
import "../../assets/css/tcreate-recipe.css";
import { ReactSession } from "react-client-session";
import { Link, Navigate } from "react-router-dom";
import { getIngredients } from "../../apis";
import { useSelector, useDispatch } from "react-redux";
import { createRecipeActions } from "../../store/createRecipe";
import ListIngredients from "../components/ListIngredients";
import ListDirection from "../components/ListDirection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faScaleBalanced } from "@fortawesome/free-solid-svg-icons";
import RecipeEditDirection from "../components/RecipeEditDirection";
import { recipeDataActions } from "../../store/recipeData";
import IngredientQuantityModal from "../components/Ingredient_quantity_modal";

const initial_unit_state =[
  {
    'value':'kilograms',
    'name':'Kilograms'
  },
  {
    'value':'pounds',
    'name':'Pounds'
  },
  {
    'value':'ounce',
    'name':'Ounce'
  },
  {
    'value':'grams',
    'name':'Grams'
  },
  {
    'value':'tablespoon',
    'name':'Tabelspoon'
  },
  {
    'value':'teaspoon',
    'name':"Teaspoon"
  },
  {
    'value':'liter',
    'name':'Liter'
  },
  {
    'value':'mililiter',
    'name':'Mililiter'
  },
  {
    'value':'cup',
    'name':'Cup'
  },
  {
    'value':'servings',
    'name':'Serinvgs'
  },
  {
    'value':'can',
    'name':'Can'
  }
]
const CreateRecipeStep3 = (props) => {
  const [is_validated, setIsValidate] = useState(false);
  
  const [units_list , setUnitsList] = useState(initial_unit_state)

  const [is_loading, setIsLoading] = useState(false);

  const [show_ingredient_quantity_modal, setShowIngredientQuantityModal] = useState(false);
  
  const [ingredients, setIngredients] = useState(null);
  
  const [enteredSearch , setEnteredSearch] = useState("");
  
  const [clicked_quantity_id , setClickedQuantityId] = useState(0);

  const [edit_Direction_id, setEditDirectionId] = useState("");

  const [selcted_ingredients, setSelectedIngredients] = useState([]);

  const [direction_input, setDirectionInput] = useState("");

  const [directions_list, setDirectionsList] = useState([]);

  const [showEditDirectionModal, setShowEditDirectionModal] = useState(false);

  const [errors, setErrors] = useState({});

  const [entered_quantity, setEnteredQuantity] = useState("")

  const dispatch = useDispatch();

  const create_recipe = useSelector(
    (state) => state.createrecipe.create_recipe
  );
  const recipe_data = useSelector((state) => state.recipedata);

  useEffect(() => {
    window.scrollTo(0, 0);
    const authtoken = ReactSession.get("authtoken");

    console.log(recipe_data.stored_ingredient);
    

    if (recipe_data?.stored_ingredient?.results) {
      setIngredients(JSON.parse(JSON.stringify(recipe_data.stored_ingredient)));
    } else {
      const page_no = 1;
      const search = '' 
      getIngredients(page_no,search)
        .then((resp) => {
          for (let data of resp.data.results) {
            data.is_selected = false;
            data.quantity = '';
            data.unit=null
          }
          setIngredients(resp.data);
        })
        .catch((err) => {
          console.log("this is the error", err.response);
        });
    }
    setSelectedIngredients(JSON.parse(JSON.stringify(create_recipe.recipe_ingredients)))
    setDirectionsList(JSON.parse(JSON.stringify(create_recipe.recipe_directions)))
    return()=>{

    }
  }, []);

  const handleChangeDirection = (e) => {
    let direction = e.target.value;
    setDirectionInput(direction);
  };

  const handleClickAddDirection = () => {
    let directions = [...directions_list];
    if (direction_input.trim()) {
      let id = directions?.length;
      if (id === 0) {
        id = 1;
      } else {
        id = directions[id - 1].id + 1;
      }
      let new_direction = {
        'id': id,
        'direction': direction_input,
      };
      directions.push(new_direction);
      setDirectionsList(directions);
      setDirectionInput("");
    }
    setErrors({
      ...errors,
      directions:''
    })
  };

  const handleClickIngredients = (id) => {
    const ingredient = { ...ingredients };
    let selcted_ingredient = [...selcted_ingredients];
    let is_already_selected =selcted_ingredient?.filter(curr_ingredient =>
      curr_ingredient[0].id === id
      )

      if(is_already_selected?.length){
    
        let douplicate_ingredient = element=> element[0].id === id  
        let index = selcted_ingredient.findIndex(douplicate_ingredient);
        selcted_ingredient.splice(index, 1)
        for (let result of ingredient.results) {
          if (result.id === id) {
            result.is_selected = false
          }}
          setSelectedIngredients(selcted_ingredient);
          setIngredients(ingredient);
      }
      else{
        const  new_ingredient = ingredient.results?.filter(ingredient=>
                                  ingredient.id === id)
        selcted_ingredient.push(new_ingredient)
        for (let result of ingredient.results) {
          if (result.id === id) {
            result.is_selected = true
          }}
        setSelectedIngredients(selcted_ingredient);
        setIngredients(ingredient);
        setShowIngredientQuantityModal(true)
        setClickedQuantityId(id)
      }
      setErrors({
        ...errors,
        ingredients:''
      })
  };

  const handleClickDeleteDirection = (id) => {
    const directions = [...directions_list];
    for (let direction of directions) {
      if (direction.id == id) {
        let index = directions.indexOf(direction);
        // only splice array when item is found
        if (index > -1) {
          // 2nd parameter means remove one item only
          directions.splice(index, 1);
        }
      }
    }
    setDirectionsList(directions);
  };

  const handleclickEditDirection = (id, current_direction) => {
    setDirectionInput(current_direction);
    setEditDirectionId(id);
    handleOpenEditDirectionModal();
  };

  const handleClickEditSaveDirection = (id) => {
    let directions = JSON.parse(JSON.stringify(directions_list));
    for (let direction of directions) {
      if (direction.id === id) {
        direction.direction = direction_input;
      }
    }
    setDirectionsList(directions);
    setEditDirectionId("");
    setDirectionInput("");
    handleCloseEditDirectionModal();
  };

  const handleOpenEditDirectionModal = () => {
    setShowEditDirectionModal(true);
  };

  const handleCloseEditDirectionModal = () => {
    setShowEditDirectionModal(false);
  };

  const handleClickRoutingNext=()=>{
    if (isValidated()) {
      handleClickRouting()
      setIsValidate(true)
      
    }
  }

  const handleClickRouting = () => {
    const str_ingedient = ingredients;
    dispatch(
      recipeDataActions.recipeData({
        ...recipe_data,
        stored_ingredient: str_ingedient,
      })
    );

    dispatch(
      createRecipeActions.createRecipe({
        ...create_recipe,
        recipe_ingredients: selcted_ingredients,
        recipe_directions:directions_list
      })
    );
  };

  const isValidated = () => {
    let is_validted = true;
    let error = {};
    if (!selcted_ingredients.length) {
      is_validted = false;
      error.ingredients = "Please select the ingredients for the recipe";
    }
    if (!directions_list.length) {
      is_validted = false;
      error.directions = "Please add the directions for the recipe";
    }
      setErrors(error);
      return is_validted;
    };

    const searchIngrediets=()=>{
      setIngredients({})
      setIsLoading(true)
      getIngredients(1,enteredSearch)
      .then((resp)=>{
        for (let data of resp.data.results) {
          data.is_selected = false;
          data.quantity = '';
          data.unit=null
        }
        setIngredients(resp.data)
        setIsLoading(false)
      })
      .catch((err)=>{
        console.log('someting went wrong with the search ingredients',err.response);
      })
    }

    const handleChangeIngredient=(e)=>{
      setEnteredSearch(e.target.value)
    }

    const fetchMoreIngredients = ()=>{
      if (ingredients.next) {
        let page = recipe_data.next;
        let page_no = page.split("=").pop();
        getIngredients(page_no)
          .then((resp) => {
            for (let data of resp.data.results) {
              data.is_selected = false;
              data.quantity = '';
              data.unit=null
            }
            let new_result = recipe_data.results.concat(resp.data.results);
            let new_data =
            {
              count: resp?.data?.count,
              next: resp?.data?.next,
              previous: resp?.data?.previous,
              results: new_result
            }
            setIngredients(new_data)

          })
          .catch((err) => {
            console.log("this is the error", err.response);
          });
      }
    }

    const handleChangeQuantity=(e,id)=>{
    let selcted_ingredient = [...selcted_ingredients];
      for(let data of selcted_ingredient){
        if (data[0].id === id) {
          data[0].quantity = e.target.value
        }
      }
      setSelectedIngredients(selcted_ingredient)
    }

    const handleClickDeleteIngredient=(id)=>{
      const ingredient = { ...ingredients };
      let selcted_ingredient = [...selcted_ingredients];
      let tobe_deleted_ingredient = element=> element[0].id === id  
      let index = selcted_ingredient.findIndex(tobe_deleted_ingredient);
      selcted_ingredient.splice(index, 1)
      for (let result of ingredient.results) {
        if (result.id === id) {
          result.is_selected = false
        }}
        setSelectedIngredients(selcted_ingredient);
        setIngredients(ingredient);
    }

    const handleCloseIngredientQuantityModal=()=>{
        setShowIngredientQuantityModal(false)
        setClickedQuantityId(0)
    }

    const handleOpenIngredientQuantityModal=(id)=>{
        setShowIngredientQuantityModal(true)
        setClickedQuantityId(id)
    }
    
    const handleChangeIngredientsUnit =(event,id)=>{
      let selcted_ingredient = [...selcted_ingredients];
      let listed_units = [...initial_unit_state]
      selcted_ingredient[id][0].unit = event.target.value
      for(let unit of listed_units){
        unit.is_selected = true
      }
      setSelectedIngredients(selcted_ingredient)
      setUnitsList(listed_units)
    }
  return (
    <>
     {is_validated && (<Navigate to="/create-recipe-step-4" replace={true} />)}
      <section className="create-recipe-page-1">
        <div className="container">
          <div className="row d-flex justify-content-center mt-2">
            <div className="col-11 col-xl-12 col-md-12 col-lg-6">
              <h5 className="create-recipe-title">
                {create_recipe?.recipe_name}
              </h5>
              <h1 className="create-recipe-heading">
                {create_recipe?.recipe_name}
              </h1>
              <p className="col-xl-6 col-11 p-0 create-recipe-paragraph-section-1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est,
                adipiscing cursus auctor eget sed phasellus senectus.{" "}
              </p>
            </div>
            <div className="col-xl-12 col-11">
              <ul className="progressbar pl-0">
                <li className="active">Step 1</li>
                <li>Step 2</li>
                <li>Step 3</li>
                <li>Step 4</li>
              </ul>
            </div>
            <div className="col-xl-12 col-11">
              <div className="progress mt-2 p-0" style={{ height: "5px" }}>
                <div
                  className="progress-bar steps-bg-color"
                  role="progressbar"
                  aria-label="Example 1px high"
                  style={{ width: "75%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-6 col-md-12 col-11 mt-3">
            <span className="recipe-name">Add ingedients</span>
            <span className="red-star">*</span>
            <div className="col-xl-10 mt-2 add-ingredient-box">
              {selcted_ingredients?.length === 0 ? (
                <>
                  <div className="add-ingredient-heading">
                    <span>Click below to add ingreadients</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="add-ingredient-heading">
                    <span> Added ingreadients</span>
                  </div>
                  <ol>
                    {selcted_ingredients?.map((ingredient,index) => {
                      return (
                        <React.Fragment key={ingredient[0].id}>
                            <li>
                              <p className="ingredient-name-in-list">{ingredient[0]?.name}</p>
                            
                                <IngredientQuantityModal
                                show_ingredient_quantity_modal={()=>show_ingredient_quantity_modal()}
                                handleCloseIngredientQuantityModal={handleCloseIngredientQuantityModal}
                                quantity={ingredient[0].quantity}
                                id={ingredient[0].id}
                                handleChangeQuantity={handleChangeQuantity}
                                clicked_quantity_id = {clicked_quantity_id}
                                handleChangeIngredientsUnit = {handleChangeIngredientsUnit} 
                                index={index}
                                unit={ingredient[0].unit}
                                units = {units_list}
                                />

                                <div onClick={()=>handleOpenIngredientQuantityModal(ingredient[0].id)}>
                                <FontAwesomeIcon
                                  icon={faScaleBalanced}
                                  size={"lg"}
                                  color={ingredient[0]?.quantity && ingredient[0]?.unit ? "#85b492" :"#ffcccb"}
                                  style={{cursor: 'pointer'}}
                                  title="select the quantity"
                                  className="mr-3"
                                />
                                </div>
                                
                                <a onClick = {() => handleClickDeleteIngredient(ingredient[0].id)} >
                                <FontAwesomeIcon
                                className="trashIcon"
                                icon={faTrash}
                                size="lg"
                                color="#85b492"
                                title={'Delete'}
                                style={{cursor: 'pointer'}}
                              />
                                </a>
                                 


                              {/* {ingredient[0]?.image ?
                                       ( <img className='modal-ing-img' src={ingredient[0]?.image} alt="ingredients" />)
                                       :
                                       ( <>
                                        <FontAwesomeIcon
                                            icon={faCartPlus}
                                            size="2x"
                                            color="#85B492"
                                        />{ingredient?.is_selected}
                                    </>)} */}

                            </li>
                        </React.Fragment>
                      );
                    })}
                  </ol>
                </>
              )}
              <>
                <ListIngredients
                  ingredients={ingredients?.results}
                  count={ingredients?.count}
                  is_loading={is_loading}
                  next={ingredients?.next}
                  handleClickIngredients={handleClickIngredients}
                  handleChangeIngredient={handleChangeIngredient}
                  searchIngrediets={searchIngrediets}
                  fetchMoreIngredients={fetchMoreIngredients}
                  enteredSearch = {enteredSearch}
                  entered_quantity={entered_quantity}
                  ingredient_error = {errors.ingredients}
                />
              </>
            </div>
          </div>
          <div className="col-xl-6 col-md-12 col-11 mt-3">
            <span className="recipe-name">Add directions</span>
            <span className="red-star">*</span>
            <div className="col-xl-10 mt-2 add-ingredient-box">
              {directions_list?.length !== 0 ? (
                <>
                  <div className="add-directions-heading">
                    <span> Added directions </span>
                  </div>
                  <ol>
                    {directions_list?.map((direction, index) => {
                      return (
                        <li
                          key={direction.id}
                          data-list-index={`${index + 1}.`}
                        >
                          <div style={{width:"80%"}} className="add-directions-step-3">{direction?.direction}</div>

                          <RecipeEditDirection
                            handleclickEditDirection={handleclickEditDirection}
                            direction={direction?.direction}
                            id={direction?.id}
                            showEditDirectionModal={showEditDirectionModal}
                            handleCloseEditDirectionModal={
                              handleCloseEditDirectionModal
                            }
                            handleClickEditSaveDirection={
                              handleClickEditSaveDirection
                            }
                            handleChangeDirection={handleChangeDirection}
                            direction_input={direction_input}
                            edit_Direction_id={edit_Direction_id}

                          />

                          <div
                            onClick={() =>
                              handleClickDeleteDirection(direction?.id)
                            }
                          >
                            <FontAwesomeIcon
                              className="trashIcon"
                              icon={faTrash}
                              size="2x"
                              transform="shrink-6"
                              color="#85b492"
                              title={'Delete'}
                            />
                          </div>
                        </li>
                      );
                    })}
                  </ol>
                </>
              ) : (
                <>
                  <div className="add-ingredient-heading">
                    <span>Click below to add direction</span>
                  </div>
                </>
              )}
              <>
                <ListDirection
                  handleChangeDirection={handleChangeDirection}
                  handleClickAddDirection={handleClickAddDirection}
                  direction_input={direction_input}
                  directions_list={directions_list}
                  direction_error={errors.directions}
                />
              </>
            </div>
          </div>
        </div>
        <hr className="mb-5 hr-color" />
        <div className="row  mb-5">
          <div className="col-xl-12 col-12 d-flex justify-content-between">
            <Link to="/create-recipe-step-2">
              <button
                type="button"
                className="btn btn-success btn-rounded previous-btn "
                onClick={handleClickRouting}
              >
                Previous
              </button>
            </Link>
              <button
                type="button"
                className="btn btn-success btn-rounded previous-btn "
                onClick={handleClickRoutingNext}
              >
                Next
              </button>

          </div>
        </div>
      </div>
    </>
  );
};
export default CreateRecipeStep3;

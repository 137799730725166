import { createSlice } from "@reduxjs/toolkit";

const initialRecipe = {
  create_recipe: {
    recipe_name: '',
    recipe_difficulty: "",
    recipe_images: [],
    recipe_video: [],
    recipe_prep_time: { min: 0, hours: 0 },
    recipe_bake_time: { min: 0, hours: 0 },
    recipe_rest_time: { min: 0, hours: 0 },
    recipe_portion_type: "",
    recipe_ingredients:[],
    recipe_directions: [],
    recipe_selected_cuisines:[],
    recipe_selected_bakes:[],
    recipe_selected_tastes:[],
    recipe_selected_occasions:[],
    recipe_selected_ingrediets:{},
    recipe_cuisine: "",
    dish_type: "",
    chefs_note: "",
    recipe_test: "",
    recipe_bake: "",  },
};

const createRecipeSlice = createSlice({
  name: "create_recipe",
  initialState: initialRecipe,
  reducers: {
    createRecipe(state, action) {
      state.create_recipe.recipe_name = action.payload.recipe_name;
      state.create_recipe.recipe_difficulty = action.payload.recipe_difficulty;
      state.create_recipe.recipe_images = action.payload.recipe_images;
      state.create_recipe.recipe_video = action.payload.recipe_video;
      state.create_recipe.recipe_bake_time = action.payload.recipe_bake_time;
      state.create_recipe.recipe_prep_time = action.payload.recipe_prep_time;
      state.create_recipe.recipe_rest_time = action.payload.recipe_rest_time;
      state.create_recipe.recipe_portion_type =
        action.payload.recipe_portion_type;
      state.create_recipe.recipe_ingredients =
        action.payload.recipe_ingredients;
      state.create_recipe.recipe_directions = action.payload.recipe_directions;
      state.create_recipe.dish_type = action.payload.dish_type;
      state.create_recipe.recipe_cuisine = action.payload.recipe_cuisine;
      state.create_recipe.chefs_note = action.payload.chefs_note;
      state.create_recipe.recipe_test = action.payload.recipe_test;
      state.create_recipe.recipe_bake = action.payload.recipe_bake;
      state.create_recipe.recipe_selected_cuisines=action.payload.recipe_selected_cuisines
      state.create_recipe.recipe_selected_bakes=action.payload.recipe_selected_bakes
      state.create_recipe.recipe_selected_tastes=action.payload.recipe_selected_tastes
      state.create_recipe.recipe_selected_occasions=action.payload.recipe_selected_occasions

    },
  },
});

export const createRecipeActions = createRecipeSlice.actions;

export default createRecipeSlice.reducer;

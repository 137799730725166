import React from "react";
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

const RecentRecipes = (props) => {
  return (
    <>
        <Link to ={`/recipe/${props?.slug}`} >
      <div className="post-list">
            <span>
              <img src={props?.image} alt="" />
            </span>
          <p style={{ color: "black" }}>
            {<Moment format="MMM D, YYYY">{props?.date}</Moment>}
          </p>
          <h4>{props?.title}</h4>
        </div>
        </Link>
    </>
  );
};

export default RecentRecipes;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

const EditIcon = () => {
  return (
    <FontAwesomeIcon
      className="penToSquareIcon"
      icon={faPenToSquare}
      size="2x"
      color="#85b492"
      transform="shrink-6"
      title="Edit"
      style={{float:"right"}}
    />
  );
};

export default EditIcon;

import React from "react";
import { subscribeArticles } from '../apis';
import { notification } from 'antd';
import $ from 'jquery';
//import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import playstore from '../assets/images/play-store-qr-code.png';
import Detect from "detect.js"


class Footer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: {
                email: ""
            },
            errors: {
                errorEmail: ""
            },
            isDesktop: false,
            isLoading: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        //Show password JS
        $(document).on('click', '.show-password', function () {
            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $("#pass_log_id");
            input.attr('type') === 'password' ? input.attr('type', 'text') : input.attr('type', 'password')
        });

        //Show confirm password JS
        $(document).on('click', '.show-password1', function () {
            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $(" #pass_log_id_confirm");
            input.attr('type') === 'password' ? input.attr('type', 'text') : input.attr('type', 'password')
        });
        // const ua = navigator.userAgent

        var ua = Detect.parse(navigator.userAgent);
        this.setState({
            isDesktop: ua.device.type === "Desktop" ? true : false
        })
    }
    handleChange(e) {
        let fields = this.state.fields;
        let errors = this.state.errors;
        fields[e.target.name] = e.target.value;
        errors[e.target.name] = ""
        this.setState({
            errors: errors,
            fields: fields
        });
    }



    handleClick() {
        if (this.validateForm()) {
            this.setState({
                isLoading: true
            })
            document.getElementById("btn news-btn").disabled = true;
            subscribeArticles({ 'email': this.state.fields['email'] })
                .then(resp => {
                    notification.success({
                        message: 'Subscription Success',
                        description: resp.data.detail,
                        placement: 'bottomRight',
                    })
                    this.setState(
                        {
                            fields: { email: "" }
                        })
                    document.getElementById("btn news-btn").disabled = false;
                    this.setState({
                        isLoading: false
                    })
                })

                .catch(err => {
                    if (err?.response) {
                        notification.error({
                            message: ` ${this.state.fields['email']} has already subscribed`,
                            description: "",
                            placement: 'bottomRight',
                        })
                    }
                    document.getElementById("btn news-btn").disabled = false;
                    this.setState({
                        isLoading: false
                    })
                })

        }
    }
    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let isValid = true;

        if (!fields["email"]?.trim()) {
            isValid = false;
            errors["email"] = "*Please enter your email.";
        }

        else {
            //regex for email validation
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                isValid = false;
                errors["email"] = "*Please enter valid email.";
            }
        }
        this.setState({
            errors: errors,
        })
        return isValid;
    }

    render() {
        return (

            <div>
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-xl-12">
                                <div className="footer-content">
                                    <h2 >
                                        Have questions? <br /> Fill in the form or reach out to us at <br />
                                        <a href="mailto:contact@flavorable.io">contact@flavorable.io</a>
                                    </h2>
                                    <p>Subscribe and make your life Flavorable with the new exciting recopies! </p>
                                    <div className="foot-inp-box col-lg-7 col-md-7">
                                        <input type="text" id="newsletter" name="email" value={this.state.fields["email"]} className="form-control" placeholder="E-mail" onChange={this.handleChange} autoComplete="off" />
                                        <button id="btn news-btn" className="btn news-btn" type="submit" onClick={this.handleClick}>
                                            {this.state.isLoading &&
                                                <div className="spinner-border text-info" role="status">
                                                    <span className="visually-hidden"></span>
                                                </div>}
                                        </button>
                                    </div>
                                    <span className='footerErrorValidation'>{this.state.errors.email}</span>

                                    <ul className="ul-btn mt-4">
                                        {this.state.isDesktop ?
                                            (<li className="btn">
                                                <a >
                                                    <i className="fab fa-google-play"></i> <span>GET IT ON <strong>Google Play</strong> </span>
                                                    <span className="tooltip google-btn">
                                                        <img src={playstore} alt="" />
                                                    </span>
                                                </a>
                                            </li>) :
                                            (
                                                <a href="https://play.google.com/store/apps/details?id=com.neevista.foodapp" target="_blank">
                                                    <li className="btn">
                                                        <i className="fab fa-google-play"></i>
                                                        <span>GET IT ON <strong>Google Play</strong></span>
                                                    </li>
                                                </a>
                                            )
                                        }

                                        {this.state.isDesktop ?
                                            (<li className="btn">
                                                <a >
                                                    <i className="fab fa-apple"></i> <span>Download on the <strong>App Store</strong> </span>
                                                    <span className="tooltip google-btn">
                                                        <img src={playstore} alt="" />
                                                    </span>
                                                </a>
                                            </li>) :
                                            (
                                                <a href="https://play.google.com/store/apps/details?id=com.neevista.foodapp" target="_blank">
                                                    <li className="btn">
                                                        <i className="fab fa-apple"></i> <span>Download on the <strong>App Store</strong> </span>
                                                    </li>
                                                </a>
                                            )
                                        }
                                    </ul>
                                    <span className="nav-footer">

                                        <Link to="/blogs">Blog</Link>

                                        <Link to="/contact">Contact</Link>
                                    </span>
                                    <span className="social-footer">
                                        <a href="https://www.facebook.com/NeeVista/" target="_blank" className="fb-icon">
                                        </a>
                                        <a href="https://twitter.com/NeevistaLtd" target="_blank" className="twitter-icon">
                                        </a>
                                        <a href="https://www.instagram.com/neevistaltd/" target="_blank" className="inst-icon">&nbsp;
                                        </a>
                                        <b><i className="far fa-copyright"></i>{(new Date().getFullYear())} Flavorable  </b>

                                    </span>
                                </div>
                            </div>
                        </div>
                    </div >
                </footer >
            </div >




        )
    }
}

export default Footer;
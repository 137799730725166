import { createSlice } from '@reduxjs/toolkit';

const recipe_data = {
    stored_cuisine: {},
    stored_bake: {},
    stored_taste: {},
    stored_ingredient: {},
    stored_occasion: {}
}

const recipeDataSlice = createSlice({
    name: 'recipe_data',
    initialState: recipe_data,
    reducers: {
        recipeData(state, action) {
            state.stored_cuisine = action.payload.stored_cuisine;
            state.stored_bake = action.payload.stored_bake;
            state.stored_taste = action.payload.stored_taste;
            state.stored_ingredient = action.payload.stored_ingredient;
            state.stored_occasion = action.payload.stored_occasion;
        }


    },
});

export const recipeDataActions = recipeDataSlice.actions;

export default recipeDataSlice.reducer

import React, { Component } from "react";
import { ReactSession } from "react-client-session";
import default_person from "../../assets/images/default_person.png";
import varified from "../../assets/images/varified.png";
import card from "../../assets/images/card.png";
import uploadImg from "../../assets/images/upload-cam.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import $ from "jquery"
import {
  updateUser,
  listSubscriptonsPlans,
  retrieveUser,
  subscriptionPreview,
  modifySubscription,
} from "../../apis";
import Subscription_plan from "../components/subscription_plan";
import Subscription_switch_modal from "../components/plan_switch_modal";
import { notification } from "antd";
import SubCancelReactivateModal from "../components/plan_cancel_reactive_modal"


export default class My_account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_info: null,
      country_code: '',
      fields: {
        first_name: "",
        last_name: "",
        bio: "",
        email: "",
        old_password:"",
        password: "",
        confirm_password: "",
        phone: "",
      },
      preview_invoice: {},
      errors: {},
      subscriptions_plans: null,
      monthly_sub_plans: [],
      yearly_sub_plans: [],
      isMonthly: true,
      isYearly: false,
      profile_image: {},
      show_modal: false,
      show_cancel_reactivate_modal:false,
      currunt_plan_id: "",
      current_subscription_plan: null
            };

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleClickApply = this.handleClickApply.bind(this);
    this.handleClickMonthly = this.handleClickMonthly.bind(this);
    this.handleClickYearly = this.handleClickYearly.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.handleClickSwitch = this.handleClickSwitch.bind(this);
    this.handleClosePlanSwitchModal =
      this.handleClosePlanSwitchModal.bind(this);
    this.handleOpenPlanSwitchModal = this.handleOpenPlanSwitchModal.bind(this);
    this.userInputValidation = this.userInputValidation.bind(this);
    this.handleClickSwitchPreview= this.handleClickSwitchPreview.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    // custom jquery for eye toggle
    $(document).on('click', '.show-password2', function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $("#pass_old_log_id");
      input.attr('type') === 'password' ? input.attr('type', 'text') : input.attr('type', 'password')
    });
    
    // retrieving the current user
    retrieveUser()
      .then((resp) => {
        let country_code = "";
        let fields = {};
        fields.first_name = resp.data?.first_name ? resp.data?.first_name : "";
        fields.last_name = resp.data?.last_name ? resp.data?.last_name : "";
        fields.email = resp.data.email ? resp.data?.email : "";
        fields.phone = resp.data?.phone ? resp.data?.phone : "";
        let profile_image = resp.data?.profile_image
          ? {url : resp.data?.profile_image, file:null }  : {};
        fields.bio = resp.data?.bio ? resp.data?.bio : "";
        // country_code = resp.data?.country_code ? resp.data?.country_code : "";
        this.setState({
          fields: fields,
          country_code: country_code,
          user_info: resp.data,
          profile_image:profile_image
        });
      })
      .catch((err) => {
        console.log("retrieve user api failed", err.response);
      });

    // getting the subscription plans
    listSubscriptonsPlans()
      .then((resp) => {
        let monthly_sub_plans = [];
        let yearly_sub_plans = [];
        let current_sub
        for (let plan of resp?.data?.results) {
          // adding yearly plans in montly_sub_plans array
          if (plan.plan_type === "Monthly") {
            monthly_sub_plans.push(plan);
          }
          // adding yearly plans in yearly_sub_plans array
          else if (plan.plan_type === "Yearly") {
            yearly_sub_plans.push(plan);
          }
          if(plan.is_subscribed){
            current_sub=plan
          }
        }

        this.setState({
          subscriptions_plans: resp.data,
          monthly_sub_plans: monthly_sub_plans,
          yearly_sub_plans: yearly_sub_plans,
          current_subscription_plan:current_sub
        });
      })

      .catch((err) => {
        console.log("list subscription api failed", err.response);
      });
  }

  handleChnageCountryCode(country_code) {
    this.setState({ country_code: country_code });
  }

  handleChangeInput(e) {
    let fields = this.state.fields;
    let errors = this.state.errors;
    errors[e.target.name] = "";
    fields[e.target.name] = e.target.value;

    this.setState({
      errors: errors,
      fields,
    });
  }

  userInputValidation() {
    let fields = this.state.fields;
    let errors = {};
    let isValid = true;

    let old_password = fields.old_password?.trim();
    let password = fields.password?.trim();
    let confirm_password = fields.confirm_password?.trim();

    if (!old_password) {
      isValid = false;
      errors.old_password = "*Please enter your old password.";
    }

    if (!password) {
      isValid = false;
      errors.password = "*Please enter your password.";
    }
    // checking whather password is satisfiying our conditions
    else {
      if (
        !fields["password"].match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*()-_]).*$/
        )
      ) {
        isValid = false;
        errors.password =
          "*Please enter secure and strong password. <br> Password must satistfy following criteria: <br> Length should be more than 8.<br>Must contail atleast one number. <br> Must contain alteast one upercase alphabet.<br> Must contain any of this specian char(@, #, $, %, &).";
      }
    }

    // checking whather confirm password value given or not
    if (!confirm_password) {
      isValid = false;
      errors.confirm_password= "*Please enter confirm password.";
    }

    // checking whather confirm password & password are matching
    else {
      if (confirm_password !== password) {
        isValid = false;
        errors.confirm_password =
          "*Confirm Password must match the password .";
      }
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }

  handleClickApply(e) {
    e.preventDefault();
    if(this.userInputValidation()){
      let user_data = new FormData();
      user_data.append("first_name", this.state.fields.first_name);
      user_data.append("last_name", this.state.fields.last_name);
      user_data.append("email", this.state.fields.email);
      user_data.append("phone", this.state.fields.phone);
      user_data.append("country_code", this.state.country_code);
      user_data.append("old_password", this.state.fields.old_password);
      user_data.append("new_password", this.state.fields.confirm_password);

       { this.state.profile_image?.file &&
        ( user_data.append(
          "profile_image",
          this.state.profile_image.file,
          this.state.profile_image.file.name
        ))
      }
      user_data.append("bio", this.state.fields.bio);
  
      updateUser(user_data)
        .then((resp) => {
          notification.success({
            message: "Your profile is successfuly updated",
            placement: "bottomRight"
        })
        retrieveUser()
        .then((resp) => {
          let country_code = "";
          let fields = {};
          fields.first_name = resp.data?.first_name ? resp.data?.first_name : "";
          fields.last_name = resp.data?.last_name ? resp.data?.last_name : "";
          fields.email = resp.data.email ? resp.data?.email : "";
          fields.phone = resp.data?.phone ? resp.data?.phone : "";
          let profile_image = resp.data?.profile_image
            ? {url : resp.data?.profile_image, file:null }  : {};
          fields.bio = resp.data?.bio ? resp.data?.bio : "";
          country_code = resp.data?.country_code ? resp.data?.country_code : 61;
          fields.old_password="";
          fields.password ="";
          fields.confirm_password= "";
  
          this.setState({
            fields: fields,
            country_code: country_code,
            user_info: resp.data,
            profile_image:profile_image
          });
        })
        .catch((err) => {
          console.log("retrieve user api failed", err.response);
        });

        })
  
        .catch((err) => {
          notification.error({
            description: err.response.data.detail,
            placement: 'bottomRight'
        })

        });
    }
   
  }

  handleClickMonthly(e) {
    e.preventDefault();

    if (this.state.isMonthly === true) {
      return;
    }

    this.setState({
      isMonthly: true,
      isYearly: false,
    });
  }

  handleClickYearly(e) {
    e.preventDefault();

    if (this.state.isYearly === true) {
      return;
    }

    this.setState({
      isMonthly: false,
      isYearly: true,
    });
  }

  handleChangeImage(e) {
    const image = e.target.files[0];
    let new_image = {
      url: URL.createObjectURL(image),
      file: image,
    };
    this.setState({
      profile_image: new_image,
    });
  }

  handleClickSwitch(new_plan_id) {
    const new_plan = this.state.subscriptions_plans?.results?.filter(
      (currunt_plan) => currunt_plan?.id === new_plan_id
    );

      const subscripton_data={
        "plan_id": new_plan_id,
      }
  
      modifySubscription(subscripton_data)
        .then((resp) => {
          notification.success({
            message: resp?.data?.detail,
            placement: "bottomRight"
        })

          listSubscriptonsPlans()
          .then((resp) => {
            let monthly_sub_plans = [];
        let yearly_sub_plans = [];
        let current_sub
        for (let plan of resp?.data?.results) {
          // adding yearly plans in montly_sub_plans array
          if (plan.plan_type === "Monthly") {
            monthly_sub_plans.push(plan);
          }
          // adding yearly plans in yearly_sub_plans array
          else if (plan.plan_type === "Yearly") {
            yearly_sub_plans.push(plan);
          }
          if(plan.is_subscribed){
            current_sub=plan
          }
        }

        this.setState({
          subscriptions_plans: resp.data,
          monthly_sub_plans: monthly_sub_plans,
          yearly_sub_plans: yearly_sub_plans,
          current_subscription_plan:current_sub
        });
          })
        })
  
        .catch((err) => {
          notification.error({
            description: err.response.data.detail,
            placement: 'bottomRight'
        })
        });

    this.handleClosePlanSwitchModal();


  }

  handleClickSwitchPreview(plan_id){
    subscriptionPreview ({'plan_id':plan_id})
    .then(async(resp)=>{
      this.setState({
        preview_invoice: await resp.data
      })
    this.handleOpenPlanSwitchModal(plan_id)

    })
    .catch((err)=>{
      console.log('some error occured while fetcging the plans',err.response);
    })


  }

  handleOpenPlanSwitchModal(plan_id) {
    this.setState({ show_modal: true, currunt_plan_id: plan_id });
  }

  handleClosePlanSwitchModal() {
    this.setState({ show_modal: false, currunt_plan_id: "" });
  }

  handleChnageSubscriptionToggle=(current_plan_id, is_recurring)=>{
    this.handleOpenCancelReactivateModal()
  }

  handleCloseCancelReactivateModal=()=>{
    this.setState({
      show_cancel_reactivate_modal:false
    })
  }
  
  handleOpenCancelReactivateModal=()=>{
    this.setState({
      show_cancel_reactivate_modal:true
    })
  }

  handleClickCancelRactive=()=>{
    
    const  new_plan={
      "plan_id" : this.state.current_subscription_plan?.id,
      "type"    : this.state.current_subscription_plan?.is_recurring ? "cancel" : "reactivate"
    }
    modifySubscription(new_plan)
    .then((resp)=>{
      notification.success({
        message: resp?.data?.detail,
        placement: "bottomRight"
    })
      listSubscriptonsPlans()
      .then((resp) => {
        let monthly_sub_plans = [];
        let yearly_sub_plans = [];
        let current_sub
        for (let plan of resp?.data?.results) {
          // adding yearly plans in montly_sub_plans array
          if (plan.plan_type === "Monthly") {
            monthly_sub_plans.push(plan);
          }
          // adding yearly plans in yearly_sub_plans array
          else if (plan.plan_type === "Yearly") {
            yearly_sub_plans.push(plan);
          }
          if(plan.is_subscribed){
            current_sub=plan
          }
        }

        this.setState({
          subscriptions_plans: resp.data,
          monthly_sub_plans: monthly_sub_plans,
          yearly_sub_plans: yearly_sub_plans,
          current_subscription_plan:current_sub
        });
    this.handleCloseCancelReactivateModal()
      })

      .catch((err) => {
        console.log("error occured while canceling/ reactivating the subscription", err.response);
      });

    })
    .catch((err)=>{
      console.log(err.response);
    })

    
  }

  render() {
    return (
      <>
      <SubCancelReactivateModal
      handleClickCancelRactive={this.handleClickCancelRactive}
      show_cancel_reactivate_modal={this.state.show_cancel_reactivate_modal}
      handleCloseCancelReactivateModal={this.handleCloseCancelReactivateModal}
      is_recurring={this.state?.current_subscription_plan?.is_recurring}
      current_subscription_plan={this.state.current_subscription_plan}
      />
        <section className="banner-section contact-section">
          <div className="banner-content">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-11 col-xl-12 col-md-12 col-sm-9  align center ">
                  <h1 className="title-register">
                    {" "}
                    <span>HI, {this.state?.user_info?.first_name}!</span> My
                    Account{" "}
                  </h1>
                  <div className="col-12 col-xl-6 col-md-8 col-lg-12 p-0">
                    <div style={{ fontSize: "11pt" }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Est, adipiscing cursus auctor eget sed phasellus senectus.{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-xl-8 col-md-12 col-12">
                  <div className="row d-flex justify-content-xl-start justify-content-center">
                    <div className=" col-md-12 col-11">
                      <h4 style={{ color: "#666666", fontSize: "13pt" }}>
                        Your Profile
                      </h4>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="profile-left text-center">
                        <div className="profile-pic mb-3">
                          {this.state.profile_image?.url ? (
                            <img
                              className="profile-img rounded-circle"
                              src={this.state.profile_image?.url}
                              alt="profile pic"
                            />
                          ) : (
                            <img
                              className="profile-img rounded-circle"
                              src={default_person}
                              alt="profile pic"
                            />
                          )}
                          <label htmlFor="picture" className="upload_label">
                            <img src={uploadImg} className="upload_pic_img" />
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            id="picture"
                            className="my-account-image-upload"
                            name="profile_image "
                            onChange={this.handleChangeImage}
                          />
                        </div>
                        <p className="small-p">
                          Tip: Choose an image where your face is recognisable.
                        </p>
                        <p className="small-p">.JPG, .GIF or .PNG. Max 20MB</p>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="row d-flex justify-content-center">
                        <div className="col-xl-6 col-md-6 col-11 mb-3">
                          <label className="my-account-label">First Name</label>
                          <input
                            type="text"
                            className="my-account-form my-account-form-label"
                            name="first_name"
                            placeholder="First name"
                            value={this.state?.fields?.first_name}
                            onChange={this.handleChangeInput}
                          />
                        </div>
                        <div className="col-xl-6 col-md-6 col-11 mb-3">
                          <label className="my-account-label">Last Name</label>
                          <input
                            type="text"
                            className="my-account-form"
                            name="last_name"
                            placeholder="Last name"
                            value={this.state?.fields?.last_name}
                            onChange={this.handleChangeInput}
                          />
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-xl-12 col-md-12 col-11  mb-3">
                          <label className="my-account-label">Bio</label>
                          <textarea
                            className="my-account-form"
                            name="bio"
                            rows="5"
                            placeholder="Tell us your goals and what you'd like to work on"
                            value={this.state?.fields?.bio}
                            onChange={this.handleChangeInput}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contact-detail text-grey">
                    <h4
                      className="mt-5"
                      style={{ color: "#666666", fontSize: "13pt" }}
                    >
                      Contact details
                    </h4>
                    <div className="row d-flex justify-content-center">
                      <div className="col-xl-6 col-md-6 col-11 mb-3">
                        <label className="my-account-label">
                          Email Address
                        </label>
                        <input
                          type="text"
                          className="my-account-form"
                          name="email"
                          value={this.state?.fields?.email}
                          onChange={this.handleChangeInput}
                        />
                        <div className="form-text mt-2 d-flex align-items-center">
                          <img
                            style={{ width: "4%" }}
                            className="varified"
                            src={varified}
                            alt=""
                          />{" "}
                          Your email address is verified.
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-11 mb-3">
                        <div className="password-input-container">
                          <label className="my-account-label">
                            old Password
                          </label>
                          <br />
                          <input
                            type="text"
                            className="my-account-form"
                            name="old_password"
                            placeholder="Enter a old password"
                            id="pass_old_log_id"
                            value={this.state.fields?.old_password}
                            onChange={this.handleChangeInput}
                          />
                          <span
                            toggle="#password-field"
                            className="fa fa-fw fa-eye field_icon show-password2"
                          ></span>
                        </div>
                        <span className='errorValidation'>{this.state.errors.old_password}</span>
                        <br />
                        <div className="password-input-container">
                          <label className="my-account-label">
                            New Password
                          </label>
                          <br />
                          <input
                            type="text"
                            className="my-account-form"
                            name="password"
                            placeholder="Enter a new password"
                            value={this.state?.fields?.password}
                            onChange={this.handleChangeInput}
                            id="pass_log_id"
                          />
                          <span
                            toggle="#password-field"
                            className="fa fa-fw fa-eye field_icon show-password"
                          ></span>
                        </div>
                        <span className='errorValidation'>{this.state.errors.password}</span>
                        <div className="mt-3 password-input-container password-confirm">
                          <input
                            type="text"
                            className="my-account-form"
                            name="confirm_password"
                            placeholder="Confirm password"
                            value={this.state?.fields?.confirm_password}
                            onChange={this.handleChangeInput}
                            id="pass_log_id_confirm"
                          />
                          <span
                            toggle="#password-field"
                            className="fa fa-fw fa-eye field_icon show-password1"
                          ></span>
                        </div>
                        <span className='errorValidation'>{this.state.errors.confirm_password}</span>
                      </div>
                      <div className=" col-xl-6 col-md-6 col-11 mb-3">
                        <label className="my-account-label">Phone Number</label>
                        <div className="d-flex">
                          <PhoneInput
                            country={"au"}
                            // placeholder=""
                            enableSearch={true}
                            value={this.state?.country_code}
                            onChange={(country_code) =>
                              this.handleChnageCountryCode(country_code)
                            }
                            inputStyle={{ width: "100px", height: "38px" }}
                            containerStyle={{ width: "50%" }}
                            autoFormat={false}
                          />
                          <input
                            type="number"
                            className="my-account-form ml-2"
                            name="phone"
                            placeholder="Phone number"
                            value={this.state?.fields?.phone}
                            onChange={this.handleChangeInput}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 mt-5">
                        <a
                          className="btn my-account-apply"
                          onClick={(e)=>this.handleClickApply(e)}
                        >
                          Apply
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <h4 style={{ color: "#666666", fontSize: "13pt" }}>
                      {" "}
                      Payment Details
                    </h4>
                  </div>
                  <div className="zindex-high">
                    <a className="btn apple-pay-btn">Apple Pay</a>
                  </div>
                  <div className="login-other">
                    <span>Or pay with card</span>
                  </div>
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Card information
                  </label>
                  <div className="visitig-crd" id="card_error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="card_number"
                      className="my-account-form"
                      id="card_number"
                      placeholder="**** **** **** 1234"
                      maxLength="16"
                    />
                    <img className="card" src={card} alt="visiting card" />
                  </div>
                  <div className="mt-2" id="card_error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="card_number"
                      className="my-account-form"
                    />
                  </div>

                  <div className="mt-3" id="card_error">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Name on card
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="card_number"
                      className="my-account-form"
                    />
                  </div>

                  <div className="mt-3" id="card_error">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Country or region
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="card_number"
                      className="my-account-form"
                    />
                  </div>
                  <div className="mt-3">
                    <a className="btn apple-pay-btn">Save</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row mt-5">
                <ul
                  className="nav nav-pills mb-3 align-items-center justify-content-center mt-3 mb-5"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item me-5" role="presentation">
                    <button
                      className={`nav-link price_tab  ${
                        this.state.isMonthly && "active"
                      } `}
                      id="monthly-price-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#monthly-price-view"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                      onClick={this.handleClickMonthly}
                    >
                      Monthly
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                       className={`nav-link price_tab  ${
                        this.state.isYearly && "active"
                      } `}
                      id="yearly-price-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#yearly-price-view"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                      onClick={this.handleClickYearly}
                    >
                      Yearly
                    </button>
                  </li>
                </ul>
              </div>
              {/* plans starts */}

              {this.state.isMonthly && (
                <div className="container">
                  <div className="row">
                    {this.state.monthly_sub_plans?.map((sub_plan) => {
                      return (
                        <div className="col-md-4" key={sub_plan.id}>
                          <Subscription_plan
                            plan_id={sub_plan.id}
                            plan_name={sub_plan?.name}
                            plan_type={sub_plan?.plan_type}
                            plan_price={sub_plan?.price}
                            plan_stripe_price_id={sub_plan?.stripe_price_id}
                            plan_feature={sub_plan?.feature}
                            plan_is_subscribed={sub_plan.is_subscribed}
                            plan_is_recurring={sub_plan.is_recurring}
                            current_subscription={
                              this.state?.current_subscription_plan
                            }
                            handleChnageSubscriptionToggle={this.handleChnageSubscriptionToggle}
                          />
                          <div className="manage-subscription-fold-3">
                            <Subscription_switch_modal
                              show_modal={this.state.show_modal}
                              handleClickSwitch={this.handleClickSwitch}
                              plan_id={sub_plan?.id}
                              handleClosePlanSwitchModal={
                                this.handleClosePlanSwitchModal
                              }
                              handleOpenPlanSwitchModal={
                                this.handleOpenPlanSwitchModal
                              }
                              currunt_plan_id={this.state?.currunt_plan_id}
                              currunt_sub_id={this.state?.current_subscription_plan?.id}
                              current_subscription={
                                this.state?.current_subscription_plan
                              }
                              handleClickSwitchPreview={this.handleClickSwitchPreview}
                              preview_invoice={this.state?.preview_invoice}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {this.state.isYearly && (
                <div className="container">
                  <div className="row">
                    {this.state.yearly_sub_plans?.map((sub_plan) => {
                      return (
                        <div className="col-md-4" key={sub_plan.id}>
                          <Subscription_plan
                            plan_name={sub_plan.name}
                            plan_id={sub_plan.id}
                            plan_type={sub_plan.plan_type}
                            plan_price={sub_plan.price}
                            plan_stripe_price_id={sub_plan.stripe_price_id}
                            plan_is_subscribed={sub_plan.is_subscribed}
                            plan_is_recurring={sub_plan.is_recurring}
                            plan_feature={sub_plan.feature}
                            current_subscription={
                              this.state?.current_subscription_plan
                            }
                            handleChnageSubscriptionToggle={this.handleChnageSubscriptionToggle}
                          />
                          <div className="manage-subscription-fold-3">
                            <Subscription_switch_modal
                              handleClickSwitch={this.handleClickSwitch}
                              plan_id={sub_plan?.id}
                              show_modal={this.state.show_modal}
                              handleClosePlanSwitchModal={
                                this.handleClosePlanSwitchModal
                              }
                              handleOpenPlanSwitchModal={
                                this.handleOpenPlanSwitchModal
                              }
                              currunt_plan_id={this.state?.currunt_plan_id}
                              currunt_sub_id={this.state?.current_subscription_plan?.id}
                              current_subscription={
                                this.state?.current_subscription_plan
                              }
                              handleClickSwitchPreview={this.handleClickSwitchPreview}
                              preview_invoice={this.state?.preview_invoice}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}
